<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/detail.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">A PROPOS DE OUKALEY</h3>
                            <!-- <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">pages</li>
                                    <li class="breadcrumb-item active" aria-current="page">A PROPOS DE OUKALEY</li>
                                </ol>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== FAQ DESIGN PART START ======-->
        <section class="faq-design-area pb-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tab-content mt-55" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-1-tab">
                                <span v-html="footers[4].description"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== FAQ DESIGN PART ENDS ======-->

        <Patner :partner="partners" />
    </div>
</template>

<script>
import Patner from './layouts/Partner';
import axios from 'axios';
export default {
    components: {
        Patner
    },
    data() {
        return {
            api_url: String,
            footers: [],
            partners: [],
        }
    },
    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getParam();
    },
    methods: {
        getParam() {
            axios.get(this.api_url + 'footer')
            .then(async res => {
                const collect = await res.data;
                if (collect.success) this.footers = collect.data;
            })
            .catch(err => console.log(err));

            axios.get(this.api_url + 'partner')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.partners = collect.data;
                })
                .catch(err => console.log(err));
        },
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    
</style>