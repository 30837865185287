import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const store = new Vuex.Store({
    state: {
        userData: null,
        idleVue: null,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],

    getters: {
        getUserData: (state) => state.userData,
    },

    mutations: {
        USER_INFO: (state, data) => data ? (state.userData = data) : (state.userData = null),
        BANK_ADD: (state, data) => state.userData.bank += data,
        BANK_REDUCE: (state, data) => state.userData.bank -= data,
    },

    actions: {
        userInfo: (context, data) => context.commit('USER_INFO', data),
        bankAdd: (context, data) => context.commit('BANK_ADD', data),
        bankReduce: (context, data) => context.commit('BANK_REDUCE', data),
    }
});

export default store;