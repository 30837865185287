import axios from 'axios';
import store from '../store';

const payment = {
    zip_code: null,

    cinetpay(project = null, amount, dossier = null, taxe = null, anonyme = false, contrepartie = null) {
        const user = store.getters.getUserData;
        const transId = Math.floor(Math.random() * 100000000);
        const order = {
            fname: user.name.substr(0, user.name.indexOf(' ')),
            lname: user.name.substring(user.name.indexOf(' ') + 1),
            email: user.email,
            phone: user.phone,
            country: user.country,
            zip: this.zip_code,
            amount: amount,
            anonyme: anonyme,
            contrepartie: contrepartie,
            id: project != null ? project.id : null,
            name: project != null ? project.name : null,
            token: user.token,
        };
        const metadata = JSON.stringify({
            'user_id': user.id,
            'project_id': order.id,
            'transaction_id': transId,
            'commission': parseFloat(taxe)
        });

        // Frais de dossier
        if (dossier != null) {
            this.getZipCode(user.country);
            const url = dossier.state == 'frais_dossier' ? 'create-project' : 'update-project';
            let formData = new FormData();
            formData.append("id", dossier.state == 'frais_dossier_update' ? dossier.id : null);
            formData.append("name", dossier.name);
            formData.append("categorie", dossier.categorie);
            formData.append("country", dossier.country);
            formData.append("campagn_duration", dossier.campagn_duration);
            formData.append("amount", Math.ceil(dossier.amount));
            formData.append("project_video", dossier.project_video);
            formData.append("context", dossier.context);
            formData.append("execution_plan", dossier.execution_plan);
            formData.append("detailed_budget", dossier.detailed_budget);
            formData.append("campagne_condition", dossier.campagne_condition);
            formData.append("campagne_type", dossier.campagne_type);
            formData.append("presentation_equipe", dossier.presentation_equipe);
            formData.append("project_summary", dossier.project_summary);
            formData.append("state", dossier.state);
            formData.append("frais_dossier", dossier.campagne_type != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000);
            formData.append("transaction_id", transId);
            formData.append("currency", '');
            formData.append("payment_method", '');
            formData.append("mean_payment", dossier.type);
            formData.append("status", 'REFUSED');
            formData.append("description", 'Paiement des frais de dossier pour le projet ' + dossier.name);
            var titre_contrepartie = dossier.titre_contrepartie;
            var montant_contrepartie = dossier.montant_contrepartie;
            var mode_acquisition = dossier.mode_acquisition;
            var nombre_contrepartie = dossier.nombre_contrepartie;
            var description_contrepartie = dossier.description_contrepartie;
            var image_contrepartie = dossier.image_contrepartie;

            for (var i = 0; i < titre_contrepartie.length; i++)
                formData.append("titre_contrepartie[]", titre_contrepartie[i]);
            for (var i = 0; i < montant_contrepartie.length; i++)
                formData.append("montant_contrepartie[]", montant_contrepartie[i]);
            for (var i = 0; i < mode_acquisition.length; i++)
                formData.append("mode_acquisition[]", mode_acquisition[i]);
            for (var i = 0; i < nombre_contrepartie.length; i++)
                formData.append("nombre_contrepartie[]", nombre_contrepartie[i]);
            for (var i = 0; i < description_contrepartie.length; i++)
                formData.append("description_contrepartie[]", description_contrepartie[i]);
            for (var i = 0; i < image_contrepartie.length; i++)
                formData.append("image_contrepartie[]", image_contrepartie[i]);

            var banniere_projet = dossier.banniere_projet;
            var totalfiles1 = dossier.totalfiles1;

            var fichier_uploader = dossier.fichier_uploader;
            var totalfiles2 = dossier.totalfiles2;

            var logo_profil = dossier.logo_profil;
            var totalfiles3 = dossier.totalfiles3;

            var image_presentation = dossier.image_presentation;
            var totalfiles8 = dossier.totalfiles3;

            for (var i = 0; i < totalfiles1; i++)
                formData.append("banniere_projet", banniere_projet[i]);
            for (var i = 0; i < totalfiles2; i++)
                formData.append("fichier_uploader", fichier_uploader[i]);
            for (var i = 0; i < totalfiles3; i++)
                formData.append("logo_profil", logo_profil[i]);
            for (var i = 0; i < totalfiles8; i++)
                formData.append("image_presentation", image_presentation[i]);

            axios.post(process.env.VUE_APP_URL + url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + order.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        CinetPay.setConfig({
                            apikey: process.env.VUE_APP_CINETPAY_API_KEY,
                            site_id: process.env.VUE_APP_CINETPAY_SITE_ID,
                            notify_url: 'https://oukaley.com/admin/notify',
                            mode: 'PRODUCTION'
                        });
                        CinetPay.getCheckout({
                            transaction_id: transId.toString(),
                            amount: dossier.campagne_type != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000,
                            currency: 'XOF',
                            channels: 'ALL',
                            description: 'Paiement des frais de dossier pour le projet ' + dossier.name,
                            metadata: metadata,
                            customer_name: order.fname,
                            customer_surname: order.lname,
                            customer_email: order.email,
                            customer_phone_number: order.phone,
                            customer_address: order.country,
                            customer_city: order.country,
                            customer_country: order.zip,
                            customer_state: order.country,
                            customer_zip_code: order.zip,

                        });
                        CinetPay.waitResponse(function (data) {
                            const url = dossier.state == 'frais_dossier' ? 'create-project' : 'update-project';
                            if (data.status == "REFUSED") { }
                            else if (data.status == "ACCEPTED") { }
                        });
                        CinetPay.onError(function (data) {
                            console.log(data);
                        });
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors) alert(collect.errors[Object.keys(collect.errors)[0]]);
                    else if (collect.success == false) alert(collect.message);
                    else alert('Erreur de connexion');
                });
        }
        else {
            // Contribution
            if (project != null) {
                this.getZipCode(user.country);
                axios.post(process.env.VUE_APP_URL + 'payment', {
                    amount: Math.ceil(amount),
                    project_id: order.id,
                    transaction_id: transId,
                    currency: '',
                    payment_method: '',
                    status: 'REFUSED',
                    commission: taxe,
                    is_anonyme: order.anonyme,
                    contrepartie_id: order.contrepartie != null ? order.contrepartie.id : null,
                    description: 'Contribution pour le projet ' + order.name,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + order.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        if (collect.success) {
                            if (collect.message == 'Veuillez fournir les pièces justificatives de votre identité') this.$router('/dashboard');
                            else {
                                CinetPay.setConfig({
                                    apikey: process.env.VUE_APP_CINETPAY_API_KEY,
                                    site_id: process.env.VUE_APP_CINETPAY_SITE_ID,
                                    notify_url: 'https://oukaley.com/admin/notify',
                                    mode: 'PRODUCTION'
                                });
                                CinetPay.getCheckout({
                                    transaction_id: transId.toString(),
                                    amount: Math.ceil(amount),
                                    currency: 'XOF',
                                    channels: 'ALL',
                                    description: 'Contribution pour le projet ' + order.name,
                                    metadata: metadata,
                                    customer_name: order.fname,
                                    customer_surname: order.lname,
                                    customer_email: order.email,
                                    customer_phone_number: order.phone,
                                    customer_address: order.country,
                                    customer_city: order.country,
                                    customer_country: order.zip,
                                    customer_state: order.country,
                                    customer_zip_code: order.zip,

                                });
                                CinetPay.waitResponse(function (data) {
                                    if (data.status == "REFUSED") { }
                                    else if (data.status == "ACCEPTED") { }
                                });
                                CinetPay.onError(function (data) {
                                    console.log(data);
                                });
                            }
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        alert(collect)
                        if (collect.errors) alert(collect.errors[Object.keys(collect.errors)[0]]);
                        else if (collect.success == false) alert(collect.message);
                        else alert('Erreur de connexion');
                    });
            }
            // Rechargement de compte
            else {
                this.getZipCode(user.country);
                axios.post(process.env.VUE_APP_URL + 'bank-payment', {
                    amount: Math.ceil(amount),
                    transaction_id: transId,
                    currency: '',
                    payment_method: '',
                    status: 'REFUSED',
                    commission: taxe,
                    description: 'Rechargement du compte oukaley',
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + order.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        if (collect.success) {
                            CinetPay.setConfig({
                                apikey: process.env.VUE_APP_CINETPAY_API_KEY,
                                site_id: process.env.VUE_APP_CINETPAY_SITE_ID,
                                notify_url: 'https://oukaley.com/admin/notify',
                                mode: 'PRODUCTION'
                            });
                            CinetPay.getCheckout({
                                transaction_id: transId.toString(),
                                amount: Math.ceil(amount),
                                currency: 'XOF',
                                channels: 'ALL',
                                description: 'Rechargement du compte oukaley',
                                metadata: metadata,
                                customer_name: order.fname,
                                customer_surname: order.lname,
                                customer_email: order.email,
                                customer_phone_number: order.phone,
                                customer_address: order.country,
                                customer_city: order.country,
                                customer_country: order.zip,
                                customer_state: order.country,
                                customer_zip_code: order.zip,

                            });
                            CinetPay.waitResponse(function (data) {
                                if (data.status == "REFUSED") { }
                                else if (data.status == "ACCEPTED") {
                                    store.dispatch('bankAdd', Math.ceil(amount));
                                }
                            });
                            CinetPay.onError(function (data) {
                                console.log(data);
                            });
                        }
                    })
                    .catch(err => console.log(err));
            }
        }
    },

    getZipCode(country) {
        axios.get('https://restcountries.com/v3.1/all')
            .then(async res => {
                const collect = await res.data;
                collect.forEach(element => {
                    if (element.name.common == country)
                        this.zip_code = element.cca2;
                });
                this.countries = this.countries.sort();
            })
            .catch(err => console.log(err));
    },
};

export default payment;