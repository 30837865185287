<template>
    <div>
        <section class="explore-projects-3-area">
            <div class="site__body">
                <div class="block-space block-space--layout--after-header"></div>
                <div class="block">
                    <div class="container container--max--xl">
                        <div class="row">
                            <div class="col-12 col-lg-3 d-flex">
                                <div class="account-nav flex-grow-1">
                                    <h4 class="account-nav__title">Tableau de bord</h4>
                                    <ul class="account-nav__list">
                                        <li class="account-nav__item"
                                            :class="[dashboard ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(1)">
                                                <i class="fa fa-home"></i> Tableau de bord</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[project ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(2)">
                                                <i class="fa fa-dropbox"></i> Mes projets</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[donation ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(7)">
                                                <i class="fa fa-handshake-o"></i> Mes donations</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[rechargement ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(3)">
                                                <i class="fa fa-credit-card"></i> Rechargement de compte</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[open_account ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(6)">
                                                <i class="fa fa-group"></i> Ouvrir un compte oukaley</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[create_project ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(5)">
                                                <i class="fa fa-pencil-square-o"></i> Créer un projet</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[decaissement_screen ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(10)">
                                                <i class="fa fa-money"></i> Décaissement</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[profil ? 'account-nav__item--active highlight_' : '']">
                                            <a @click="handleClick(4)">
                                                <i class="fa fa-user"></i> Modifier le profil</a>
                                        </li>
                                        <li class="account-nav__item"
                                            :class="[dashboard ? 'account-nav__item--active' : '']">
                                            <a href="#" data-toggle="modal" data-target="#updatepassword">
                                                <i class="fa fa-gears"></i> Sécurité</a>
                                        </li>
                                        <li class="account-nav__divider" role="presentation"></li>
                                        <li class="account-nav__item"
                                            :class="[dashboard ? 'account-nav__item--active' : '']">
                                            <a @click="logout">
                                                <i class="fa fa-openid"></i> Déconnexion</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-9 mt-4 mt-lg-0">
                                <div v-if="dashboard" class="dashboard">
                                    <div v-if="!userLoad" class="dashboard__profile card profile-card">
                                        <div class="card-body profile-card__body">
                                            <div class="profile-card__avatar">
                                                <img src="/images/avatars/avatar-4.jpg" alt="">
                                            </div>
                                            <div class="profile-card__name">{{ userData.name }}</div>
                                            <div class="profile-card__email">{{ userData.email }}</div>
                                            <div class="profile-card__email">Compte oukaley: <span
                                                    style="font-weignt: bold;">{{ userMedia.bank }} FCFA </span></div>
                                            <div class="profile-card__edit">
                                                <a @click="handleClick(4)"
                                                    class="btn btn-secondary btn-sm text-light">Editer le profile</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dashboard__orders card">
                                        <div class="card-header">
                                            <h5>Projets récents</h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-table">
                                            <div class="table-responsive-sm">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Nom du projet</th>
                                                            <th>Etat</th>
                                                            <th>Frais de dossier</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in projects_limit" :key="item.project.id">
                                                            <td>
                                                                <img :src="item.image != null ? item.image.src : 'https://www.freeiconspng.com/uploads/no-image-icon-6.png'"
                                                                    alt="img" />
                                                            </td>
                                                            <td>{{ item.project.name }}</td>
                                                            <td>
                                                                <button class="btn btn-success btn-sm"
                                                                    :class="[
                                                    item.etat == 'En campagne' || item.etat == 'Brouillon' ? 'btn-info' :
                                                        item.etat == 'Réfusé' ? 'btn-danger' :
                                                            item.etat == 'Sans reserve' || item.etat == 'Avec reserve' ? 'btn-warning' :
                                                                item.etat == 'Enregistré' ? 'btn-primary' :
                                                                    item.etat == 'Clôturé et réussi' || item.etat == 'Clôturé et échoué' ? 'btn-success' : '']">{{
                        item.etat
                            == 'Sans reserve' ? 'SANS RESERVE' : item.etat ==
                                'Clôturé et réussi' ? 'PROJET REUSSI' : item.etat ==
                                    'Clôturé et échoué' ? 'PROJET ECHOUE' :
                            item.etat
                    }}</button>
                                                            </td>
                                                            <td>
                                                                <a v-if="item.frais != null && item.frais.status == 'ACCEPTED'"
                                                                    class="btn btn-success btn-sm text-decoration-none text-light"
                                                                    title="Déjà payer">Payer</a>
                                                                <a v-else href="javascript:;"
                                                                    class="btn btn-info btn-sm text-decoration-none"
                                                                    title="Procéder au paiement">Non payer</a>
                                                            </td>
                                                            <td>
                                                                <router-link
                                                                    :to="{ name: 'viewer', params: { id: item.project.slug } }"
                                                                    class="btn btn-primary btn-sm"
                                                                    :class="[item.etat == 'En campagne' || item.etat == 'Enregistré' ? 'mr-1' : '']"
                                                                    title="Voir"><i class="fa fa-eye"></i>
                                                                </router-link>
                                                                <button
                                                                    v-if="item.etat != 'En campagne' && item.etat != 'Enregistré'"
                                                                    @click="[item.etat == 'Avec reserve' ? updateProjet(item, 'brouillon') : updateProjet(item)]"
                                                                    class="btn btn-secondary btn-sm mx-1"
                                                                    :title="[item.etat == 'Avec reserve' ? 'Retour du projet au brouillon' : 'Modifier']">
                                                                    <i class="fa fa-pencil"></i>
                                                                </button>
                                                                <button
                                                                    v-if="item.etat == 'Brouillon' || item.etat == 'Enregistré'"
                                                                    v-clipboard:copy="url + 'project-view/' + item.project.slug"
                                                                    v-clipboard:success="onCopy"
                                                                    class="btn btn-warning btn-sm mr-1"
                                                                    title="Copier le lien du projet">
                                                                    <i class="fa fa-copy text-light"></i>
                                                                </button>
                                                                <button @click="disabledProject(item.project.id)"
                                                                    class="btn btn-sm"
                                                                    :class="[item.project.status == 1 ? 'btn-success' : 'btn-danger']"
                                                                    :title="[item.project.status == 1 ? 'Désactiver' : 'Activer']">
                                                                    <i class="fa fa-ban"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <pagination v-model="projet" :records="project_total" :per-page="10"
                                        @paginate="getProjects" /> -->
                                </div>
                                <div v-if="project">
                                    <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                                role="tab" aria-controls="home" aria-selected="true">Projets au
                                                brouillons</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile"
                                                role="tab" aria-controls="profile" aria-selected="false">Projets en
                                                cours</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact"
                                                role="tab" aria-controls="contact" aria-selected="false">Projets
                                                financés</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel"
                                            aria-labelledby="home-tab">
                                            <div class="dashboard__orders card">
                                                <div class="card-table">
                                                    <div class="table-responsive-sm text-center">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Image</th>
                                                                    <th>Nom du projet</th>
                                                                    <th>Etat</th>
                                                                    <th>Frais de dossier</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="projects_brouillon.length > 0"
                                                                    v-for="item in projects_brouillon.reverse()"
                                                                    :key="item.project.id">
                                                                    <td>
                                                                        <img :src="item.image != null ? item.image.src : 'https://www.freeiconspng.com/uploads/no-image-icon-6.png'"
                                                                            alt="img" />
                                                                    </td>
                                                                    <td>{{ item.project.name }}</td>
                                                                    <td>
                                                                        <button class="btn btn-info btn-sm">{{
                                                                            item.etat
                                                                        }}</button>
                                                                    </td>
                                                                    <td>
                                                                        <a v-if="item.frais != null && item.frais.status == 'ACCEPTED'"
                                                                            class="btn btn-success btn-sm text-decoration-none text-light"
                                                                            title="Déjà payer">Payer</a>
                                                                        <a v-else href="javascript:;"
                                                                            class="btn btn-info btn-sm text-decoration-none"
                                                                            title="Procéder au paiement">Non payer</a>
                                                                    </td>
                                                                    <td>
                                                                        <router-link
                                                                            :to="{ name: 'viewer', params: { id: item.project.slug } }"
                                                                            class="btn btn-primary btn-sm"
                                                                            :class="[item.etat == 'En campagne' ? 'mr-1' : '']"
                                                                            title="Voir"><i class="fa fa-eye"></i>
                                                                        </router-link>
                                                                        <button v-if="item.etat != 'En campagne'"
                                                                            @click="updateProjet(item, 'brouillon')"
                                                                            class="btn btn-secondary btn-sm mx-1"
                                                                            :title="['Modifier']">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button
                                                                            v-if="item.etat == 'Brouillon' || item.etat == 'Enregistré'"
                                                                            v-clipboard:copy="url + 'project-view/' + item.project.slug"
                                                                            v-clipboard:success="onCopy"
                                                                            class="btn btn-warning btn-sm mr-1"
                                                                            title="Copier le lien du projet">
                                                                            <i class="fa fa-copy text-light"></i>
                                                                        </button>
                                                                        <button
                                                                            @click="disabledProject(item.project.id)"
                                                                            class="btn btn-sm"
                                                                            :class="[item.project.status == 1 ? 'btn-success' : 'btn-danger']"
                                                                            :title="[item.project.status == 1 ? 'Désactiver' : 'Activer']">
                                                                            <i class="fa fa-ban"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr v-else>
                                                                    <td colspan="5">Aucun projet disponible</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <pagination v-model="projet_brouillon" :records="projet_brouillon_total"
                                                :per-page="10" @paginate="getBrouillonProjects" />
                                        </div>
                                        <div class="tab-pane fade" id="profile" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            <div class="dashboard__orders card">
                                                <div class="card-table">
                                                    <div class="table-responsive-sm text-center">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Image</th>
                                                                    <th>Nom du projet</th>
                                                                    <th>Etat</th>
                                                                    <th>Contributeurs</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="projects_cours.length > 0"
                                                                    v-for="item in projects_cours.reverse()"
                                                                    :key="item.project.id">
                                                                    <td>
                                                                        <img :src="item.image.src" alt="img" />
                                                                    </td>
                                                                    <td>{{ item.project.name }}</td>
                                                                    <td>
                                                                        <button class="btn btn-info btn-sm">{{
                                                                            item.etat
                                                                        }}</button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            @click="seeContributeur(item.project.id)"
                                                                            class="btn btn-info btn-sm">Voir les
                                                                            contributeurs</button>
                                                                    </td>
                                                                    <td>
                                                                        <router-link
                                                                            :to="{ name: 'viewer', params: { id: item.project.slug } }"
                                                                            class="btn btn-primary btn-sm"
                                                                            :class="[item.etat == 'En campagne' ? 'mr-1' : '']"
                                                                            title="Voir"><i class="fa fa-eye"></i>
                                                                        </router-link>
                                                                        <button v-if="item.etat != 'En campagne'"
                                                                            @click="updateProjet(item, 'brouillon')"
                                                                            class="btn btn-secondary btn-sm mx-1"
                                                                            :title="['Modifier']">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button v-if="item.etat == 'Avec reserve'"
                                                                            @click="disabled"
                                                                            class="btn btn-danger btn-sm mr-1"
                                                                            title="Copié">
                                                                            <i class="fa fa-paste"></i>
                                                                        </button>
                                                                        <button
                                                                            @click="disabledProject(item.project.id)"
                                                                            class="btn btn-sm mr-1"
                                                                            :class="[item.project.status == 1 ? 'btn-success' : 'btn-danger']"
                                                                            :title="[item.project.status == 1 ? 'Désactiver' : 'Activer']">
                                                                            <i class="fa fa-ban"></i>
                                                                        </button>
                                                                        <a @click="project_id = item.project.id"
                                                                            href="#"
                                                                            class="btn btn-info btn-sm text-decoration-none"
                                                                            data-toggle="modal" data-target="#suivi"
                                                                            title="Laisser une note">
                                                                            <i class="fa fa-edit"></i>
                                                                        </a>
                                                                        <a @click="project_id = item.project.id"
                                                                            href="#"
                                                                            class="btn btn-warning btn-sm text-decoration-none mx-1"
                                                                            data-toggle="modal" data-target="#decaissement"
                                                                            title="Faire une demande de décaissement">
                                                                            <i class="fa fa-edit"></i>
                                                                        </a>
                                                                        <!-- <figure class="qrcode mt-5">
                                                                            <vue-qrcode
                                                                            :value="url10 + item.project.id"
                                                                            tag="svg"
                                                                            :options="{
                                                                                errorCorrectionLevel: 'Q',
                                                                                width: 200,
                                                                            }"
                                                                            ></vue-qrcode>
                                                                            <img
                                                                                class="qrcode__image"
                                                                                src="assets/images/icon.png"
                                                                                alt="Logo"
                                                                            />
                                                                        </figure> -->
                                                                    </td>
                                                                </tr>
                                                                <tr v-else>
                                                                    <td colspan="5">Aucun projet disponible</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <pagination v-model="projet_cours" :records="projet_cours_total"
                                                :per-page="10" @paginate="getCoursProjects" />
                                        </div>
                                        <div class="tab-pane fade" id="contact" role="tabpanel"
                                            aria-labelledby="contact-tab">
                                            <div class="dashboard__orders card">
                                                <div class="card-table">
                                                    <div class="table-responsive-sm text-center">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Image</th>
                                                                    <th>Nom du projet</th>
                                                                    <th>Etat</th>
                                                                    <th>Contributeurs</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="projects_finance.length > 0"
                                                                    v-for="item in projects_finance.reverse()"
                                                                    :key="item.project.id">
                                                                    <td>
                                                                        <img :src="item.image.src" alt="img" />
                                                                    </td>
                                                                    <td>{{ item.project.name }}</td>
                                                                    <td>
                                                                        <button class="btn btn-success btn-sm">{{
                                                                            item.etat
                                                                        }}</button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            @click="seeContributeur(item.project.id)"
                                                                            class="btn btn-info btn-sm">Voir les
                                                                            contributeurs</button>
                                                                    </td>
                                                                    <td>
                                                                        <router-link
                                                                            :to="{ name: 'viewer', params: { id: item.project.slug } }"
                                                                            class="btn btn-primary btn-sm mr-1"
                                                                            title="Voir"><i class="fa fa-eye"></i>
                                                                        </router-link>
                                                                        <button
                                                                            @click="disabledProject(item.project.id)"
                                                                            class="btn btn-sm"
                                                                            :class="[item.project.status == 1 ? 'btn-success' : 'btn-danger']"
                                                                            :title="[item.project.status == 1 ? 'Désactiver' : 'Activer']">
                                                                            <i class="fa fa-ban"></i>
                                                                        </button>
                                                                        <a @click="project_id = item.project.id"
                                                                            href="#"
                                                                            class="btn btn-info btn-sm text-decoration-none"
                                                                            data-toggle="modal" data-target="#suivi"
                                                                            title="Laisser une note">
                                                                            <i class="fa fa-edit"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr v-else>
                                                                    <td colspan="5">Aucun projet disponible</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <pagination v-model="projet_finance" :records="projet_finance_total"
                                                :per-page="10" @paginate="getFinanceProjects" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="donation">
                                    <div class="dashboard__orders card my-3">
                                        <div class="card-header">
                                            <h5>Mes donnations</h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-table">
                                            <div class="table-responsive-sm text-center">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Nom du projet</th>
                                                            <th>Montant</th>
                                                            <th>Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in donations" :key="item.contributeur.id">
                                                            <td>
                                                                <img :src="item.user.image" alt="img" />
                                                            </td>
                                                            <td>{{ item.project.name }}</td>
                                                            <td>{{ parseInt(item.contributeur.amount).toLocaleString("en-US") }} FCFA</td>
                                                            <td>{{
                                                                new
                                                                                                                            Date(item.contributeur.date).toLocaleDateString("fr")
                                                            }}
                                                            </td>
                                                            <td>
                                                                <router-link
                                                                    :to="{ name: 'viewer', params: { id: item.project.slug } }"
                                                                    class="btn btn-primary btn-sm" title="Voir"><i
                                                                        class="fa fa-eye"></i>
                                                                </router-link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <pagination v-model="page_donation" :records="donation_total" :per-page="10"
                                        @paginate="getDonations" />
                                </div>
                                <div v-if="contributeur">
                                    <div class="dashboard__orders card my-3">
                                        <div class="card-header">
                                            <h5>Liste des contributeurs du projet
                                                <strong>{{ contributeurs.contributeur[0].project.name }}</strong> <span style="margin-right: 10px;"></span>
                                                <button @click="downloadFile()" class="btn btn-primary btn-xs">Export to excel</button>
                                            </h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-table">
                                            <div class="table-responsive-sm text-center">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Nom</th>
                                                            <th>Email</th>
                                                            <th>Contact</th>
                                                            <th>Montant</th>
                                                            <th>Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in contributeurs.contributeur"
                                                            :key="item.contributeur.id">
                                                            <td>
                                                                <img :src="item.user.image != null ? item.user.image : '/assets/images/user.png'"
                                                                    alt="img" />
                                                            </td>
                                                            <td>{{ item.user.name }}</td>
                                                            <td>{{ item.user.email }}</td>
                                                            <td>{{ item.user.phone }}</td>
                                                            <td>{{ item.contributeur.amount }} FCFA</td>
                                                            <td>{{
                                                                new
                                                                                                                            Date(item.contributeur.date).toLocaleDateString("fr")
                                                            }}
                                                            </td>
                                                            <td>
                                                                <a :href="'mailto:' + item.user.email"
                                                                    class="btn btn-primary btn-sm" title="Voir">
                                                                    <i class="fa fa-envelope"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <pagination v-model="page_donation" :records="contributeurs.contributeur.length"
                                        :per-page="5" @paginate="callback" /> -->
                                </div>
                                <div v-if="rechargement">
                                    <div class="dashboard__orders card my-3">
                                        <div class="card-header">
                                            <h5>Recharger mon compte oukaley</h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-table">
                                            <div class="table-responsive-sm text-center">
                                                <div class="faq-title-area">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="section-title text-center">
                                                                    <div class="row justify-content-center">
                                                                        <div class="col-lg-8">
                                                                            <div class="input-box">
                                                                                <input v-model="form.amount"
                                                                                    type="number" min="1000" step="1000"
                                                                                    placeholder="Saisissez le montant à recharger">
                                                                                <h5 class="mt-4 text-danger">Taux:
                                                                                    {{ taxe.user }} %</h5>
                                                                                <h5 class="mt-4 text-danger">Total à
                                                                                    payer: {{
                                                                                        parseInt(form.amount +
                                                                                            ((form.amount
                                                                                                * taxe.user) / 100)).toLocaleString("en-US")
                                                                                    }} FCFA</h5>
                                                                                <img src="/assets/images/cinetpay.png"
                                                                                    alt="img" class="my-5"
                                                                                    draggable="false">
                                                                                <div
                                                                                    class="row justify-content-center my-2">
                                                                                    <div class="col-lg-7">
                                                                                        <div v-if="!load"
                                                                                            class="easy-steps-content text-center">
                                                                                            <a @click="pay('rechargement')"
                                                                                                type="button"
                                                                                                class="main-btn text-decoration-none"
                                                                                                disabled>Procéder au
                                                                                                réchargement</a>
                                                                                        </div>
                                                                                        <div v-else class="load">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="open_account" class="card">
                                    <div class="card-header d-flex">
                                        <h5 v-if="this.userAccountType == null">Ouvrir un compte oukaley</h5>
                                        <h5 v-else>Information du compte oukaley</h5>
                                    </div>
                                    <div class="card-divider"></div>
                                    <div class="card-body card-body--padding--2">
                                        <div class="row no-gutters">
                                            <div v-if="last_step" class="col-12">
                                                <div class="form-row justify-content-center">
                                                    <div v-if="this.userAccountType == null"
                                                        class="form-group col-md-6">
                                                        <label for="signup-confirm">Sélectionnez la catégorie <span
                                                                class="text-danger">(*)</span></label>
                                                        <select v-model="form.account.type" class="form-control"
                                                            :class="[form.account.type != '' ? 'is-valid' : '']">
                                                            <option value="" disabled selected>Sélectionnez une
                                                                catégorie</option>
                                                            <option>Particulier</option>
                                                            <option>Professionnel</option>
                                                            <option>Entreprise</option>
                                                            <option>Association et ONG</option>
                                                        </select>
                                                        <div class="invalid-feedback">{{ form.account.errors.type }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="form.account.type == 'Particulier' || userAccountType == 'personal'">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Nom <span class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.firstname" type="text"
                                                                class="form-control"
                                                                :class="[form.account.firstname != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.firstname }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Prenoms <span class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.lastname" type="text"
                                                                class="form-control"
                                                                :class="[form.account.lastname != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.lastname }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Pseudo <span class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.pseudo" type="text"
                                                                class="form-control"
                                                                :class="[form.account.pseudo != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.pseudo
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label>Civilité <span class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.civility" class="form-control"
                                                                :class="[form.account.civility != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez une
                                                                    civilité</option>
                                                                <option>M.</option>
                                                                <option>Mme</option>
                                                                <option>Mlle</option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.civility }}</div>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label>Sexe <span class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.sex" class="form-control"
                                                                :class="[form.account.sex != null ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez un sexe
                                                                </option>
                                                                <option v-for="item in genre" :key="item">{{ item }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">{{ form.account.errors.sex }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Date de naissance <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.birthday" type="date"
                                                                class="form-control"
                                                                :class="[form.account.birthday != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.birthday }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Lieu de naissance <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model.trim="form.account.borthday" type="text"
                                                                class="form-control"
                                                                :class="[form.account.borthday != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.borthday }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Nationnalité <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.nationality" type="text"
                                                                class="form-control"
                                                                :class="[form.account.nationality != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.nationality }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Pays de résidence <span
                                                                    class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.country_residence"
                                                                class="form-control"
                                                                :class="[form.account.country_residence != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez un pays
                                                                    de résidence</option>
                                                                <option v-for="item in country" :key="item">{{ item }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.country_residence }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label for="signup-confirm">Ville <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.city" id="signup-confirm"
                                                                type="text" class="form-control"
                                                                :class="[form.account.city != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{ form.account.errors.city }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="signup-password">Contact <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.phone" id="signup-password"
                                                                type="number" class="form-control"
                                                                :class="[form.account.phone != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.phone
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <button @click="step('next')" type="button"
                                                            class="btn btn-warning text-light mt-3"
                                                            :disabled="form.account.disabled_account">Suivant</button>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="form.account.type == 'Professionnel' || userAccountType == 'profesional'">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Registre des métiers <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.registre" type="text"
                                                                class="form-control"
                                                                :class="[form.account.registre != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.registre }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Activité <span class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.activity" type="text"
                                                                class="form-control"
                                                                :class="[form.account.activity != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.activity }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Raison sociale <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.raison" type="text"
                                                                class="form-control"
                                                                :class="[form.account.raison != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.raison
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Répertoire des entreprises <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.repertoire" type="text"
                                                                class="form-control"
                                                                :class="[form.account.repertoire != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.repertoire }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Date de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.date_creation" type="date"
                                                                class="form-control"
                                                                :class="[form.account.date_creation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.date_creation }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Objet de la société <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.object" type="text"
                                                                class="form-control"
                                                                :class="[form.account.object != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.object
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <button @click="step('next')" type="button"
                                                            class="btn btn-warning text-light mt-3 mr-1"
                                                            :disabled="form.account.disabled_next1">Suivant</button>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="form.account.type == 'Entreprise' || userAccountType == 'entreprise'">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Raison sociale <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.raison" type="text"
                                                                class="form-control"
                                                                :class="[form.account.raison != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.raison
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Objet de la société <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.object" type="text"
                                                                class="form-control"
                                                                :class="[form.account.object != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.object
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Forme juridique <span
                                                                    class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.forme_juridique"
                                                                class="form-control"
                                                                :class="[form.account.forme_juridique ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez une
                                                                    forme juridique</option>
                                                                <option>SARL</option>
                                                                <option>SAS</option>
                                                                <option>SA</option>
                                                                <option>EI</option>
                                                                <option>EIRL</option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.forme_juridique }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Capital social <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.capital_social" type="number"
                                                                class="form-control"
                                                                :class="[form.account.capital_social != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.capital_social }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-4">
                                                            <label>Date de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.date_creation" type="date"
                                                                class="form-control"
                                                                :class="[form.account.date_creation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.date_creation }}</div>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Pays de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.pays_creation"
                                                                class="form-control"
                                                                :class="[form.account.pays_creation != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez un pays
                                                                    de résidence</option>
                                                                <option v-for="item in country" :key="item">{{ item }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.pays_creation }}</div>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Localisation <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.localisation" type="text"
                                                                class="form-control"
                                                                :class="[form.account.localisation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.localisation }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <button @click="step('next')" type="button"
                                                            class="btn btn-warning text-light mt-3 mr-1"
                                                            :disabled="form.account.disabled_next1">Suivant</button>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="form.account.type == 'Association et ONG' || userAccountType == 'association'">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Raison sociale <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.raison" type="text"
                                                                class="form-control"
                                                                :class="[form.account.raison != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.raison
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Numéro d'immatriculation <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.immatriculation" type="text"
                                                                class="form-control"
                                                                :class="[form.account.immatriculation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.immatriculation }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Date de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.date_creation" type="date"
                                                                class="form-control"
                                                                :class="[form.account.date_creation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.date_creation }}</div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Pays de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <select v-model="form.account.pays_creation"
                                                                class="form-control"
                                                                :class="[form.account.pays_creation != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez un pays
                                                                    de résidence</option>
                                                                <option v-for="item in country" :key="item">{{ item }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.pays_creation }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Objet de la société <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.object" type="text"
                                                                class="form-control"
                                                                :class="[form.account.object != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">{{
                                                                form.account.errors.object
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Ville/commune/lieu de création <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input v-model="form.account.localisation" type="text"
                                                                class="form-control"
                                                                :class="[form.account.localisation != '' ? 'is-valid' : '']">
                                                            <div class="invalid-feedback">
                                                                {{ form.account.errors.localisation }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <button @click="step('next')" type="button"
                                                            class="btn btn-warning text-light mt-3 mr-1"
                                                            :disabled="form.account.disabled_next1">Suivant</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="next_step" class="col-12">
                                                <div
                                                    v-if="form.account.type == 'Professionnel' || userAccountType == 'profesional'">
                                                    <div v-if="this.userAccountType == null" class="row">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="piece">Pièce d'identité <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="piece_identite"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="domicile">Justification de domicile <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="justification_domicile"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="bank">Relevé d'identité bancaire <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="bank" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="bank">RCCM ou Répertoire des sociétés ou RM
                                                                    <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="rccm" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="form-row justify-content-center">
                                                            <div class="form-group">
                                                                <label>Logo <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="logo" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button @click="openOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3"
                                                                :disabled="disabled_account">Créer</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                    <div v-else class="row">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="piece">Pièce d'identité <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.professionel_piece_identite.id)"
                                                                    :src="userMedia.professionel_piece_identite.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="piece_identite" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="domicile">Justification de domicile <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.professionel_justification_domicile.id)"
                                                                    :src="userMedia.professionel_justification_domicile.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="justification_domicile" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="bank">Relevé d'identité bancaire <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.professionel_bank.id)"
                                                                    :src="userMedia.professionel_bank.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="bank" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="bank">RCCM ou Répertoire des sociétés ou RM
                                                                    <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.professionel_rccm.id)"
                                                                    :src="userMedia.professionel_rccm.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="rccm" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row justify-content-center">
                                                            <div class="form-group">
                                                                <label>Logo <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.professionel_logo.id)"
                                                                    :src="userMedia.professionel_logo.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="logo" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button v-if="!form.account.hide_button"
                                                                @click="updateOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Modifier</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="form.account.type == 'Entreprise' || userAccountType == 'entreprise'">
                                                    <div v-if="this.userAccountType == null">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <label for="piece">Pièce d'identité du dirigeant <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="piece_identite"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label for="domicile">Justification de domicile <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="justification_domicile"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label for="bank">Relevé d'identité bancaire <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="releve_bancaire"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="piece">Statuts certifiés conformes à
                                                                    l’original de la société
                                                                    <!--(incluant la répartition des pouvoirs et les noms des personnes décisionnaires dans l’entreprise)-->
                                                                    <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="status_certifie"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="domicile">Pièce d’identité actionnaire
                                                                    détenant plus de 25% <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="piece_identite_actionnaire"
                                                                    name="input-b5" type="file" class="form-control">
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <label>RCCM <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="rccm" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label>DFE <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="dfe" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label>Logo <span class="text-danger">(*)</span></label>
                                                                <input id="input-b5" ref="logo" name="input-b5"
                                                                    type="file" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button @click="openOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3"
                                                                :disabled="disabled_account">Créer</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                    <div v-else class="row">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <label for="piece">Pièce d'identité du dirigeant <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_piece_identite_dirigeant.id)"
                                                                    :src="userMedia.entreprise_piece_identite_dirigeant.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="piece_identite" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label for="domicile">Justification de domicile <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_justification_domicile.id)"
                                                                    :src="userMedia.entreprise_justification_domicile.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="justification_domicile" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label for="bank">Relevé d'identité bancaire <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_releve_identite_bancaire.id)"
                                                                    :src="userMedia.entreprise_releve_identite_bancaire.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="releve_bancaire" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label for="piece">Statuts certifiés conformes à
                                                                    l’original de la société
                                                                    <!--(incluant la répartition des pouvoirs et les noms des personnes décisionnaires dans l’entreprise)-->
                                                                    <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_statut_entreprise.id)"
                                                                    :src="userMedia.entreprise_statut_entreprise.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="status_certifie" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label for="domicile">Pièce d’identité actionnaire
                                                                    détenant plus de 25% <span
                                                                        class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_piece_identite_actionnaire.id)"
                                                                    :src="userMedia.entreprise_piece_identite_actionnaire.src"
                                                                    width="300" height="200" alt="photo"
                                                                    draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="piece_identite_actionnaire" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <label>RCCM <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_rccm.id)"
                                                                    :src="userMedia.entreprise_rccm.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="rccm" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label>DFE <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_dfe.id)"
                                                                    :src="userMedia.entreprise_dfe.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="dfe" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label>Logo <span class="text-danger">(*)</span></label>
                                                                <img @click="addToExist(userMedia.entreprise_logo.id)"
                                                                    :src="userMedia.entreprise_logo.src" width="300"
                                                                    height="200" alt="photo" draggable="false" />
                                                                <div class="d-flex text-center">
                                                                    <input ref="logo" type="file"
                                                                        class="form-control my-2 file"
                                                                        id="input-b5 item.id">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button v-if="!form.account.hide_button"
                                                                @click="updateOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Modifier</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="form.account.type == 'Association et ONG' || userAccountType == 'association'">
                                                    <div v-if="this.userAccountType == null" class="row">
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Pièce d'identité du dirigeant <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="piece_identite" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Justification de domicile <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="justification_domicile"
                                                                name="input-b5" type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Relevé d'identité bancaire <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="releve_bancaire" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Statut de l'association <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="status_association"
                                                                name="input-b5" type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Une copie du numéro d'enregistrement
                                                                officiel de l’association <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="copie_numero" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Liste des membres du bureau légalisé <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="liste_membre" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Logo <span class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="logo" name="input-b5" type="file"
                                                                class="form-control">
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button @click="openOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3"
                                                                :disabled="disabled_account">Créer</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                    <div v-else class="row">
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Pièce d'identité du dirigeant <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_piece_identite_dirigeant.id)"
                                                                :src="userMedia.association_piece_identite_dirigeant.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="piece_identite" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Justification de domicile <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_justification_domicile.id)"
                                                                :src="userMedia.association_justification_domicile.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="justification_domicile" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Relevé d'identité bancaire <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_releve_identite_bancaire.id)"
                                                                :src="userMedia.association_releve_identite_bancaire.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="releve_bancaire" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Statut de l'association <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_statut_association.id)"
                                                                :src="userMedia.association_statut_association.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="status_association" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Une copie du numéro d'enregistrement
                                                                officiel de l’association <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_numero_enregistrement.id)"
                                                                :src="userMedia.association_numero_enregistrement.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="copie_numero" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Liste des membres du bureau légalisé <span
                                                                    class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_liste_membre_bureau.id)"
                                                                :src="userMedia.association_liste_membre_bureau.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="liste_membre" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Logo <span class="text-danger">(*)</span></label>
                                                            <img @click="addToExist(userMedia.association_logo.id)"
                                                                :src="userMedia.association_logo.src" width="300"
                                                                height="200" alt="photo" draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="logo" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button v-if="!form.account.hide_button"
                                                                @click="updateOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Modifier</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div v-if="this.userAccountType == null" class="row">
                                                        <div class="form-group col-md-6">
                                                            <label for="signup-password">Photo <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="photo" name="input-b5" type="file"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Pièce d'identité <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="piece_identite" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Justification de domicile <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="justification_domicile"
                                                                name="input-b5" type="file" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Relevé d'identité bancaire <span
                                                                    class="text-danger">(*)</span></label>
                                                            <input id="input-b5" ref="releve_bancaire" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button @click="openOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Créer</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                    <div v-else class="row">
                                                        <div class="form-group col-md-6">
                                                            <label for="signup-password">Photo <span
                                                                    class="text-danger">(*)</span></label> <br />
                                                            <img @click="addToExist(userMedia.personal_photo.id)"
                                                                :src="userMedia.personal_photo.src" width="300"
                                                                height="200" alt="photo" draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="photo" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="piece">Pièce d'identité <span
                                                                    class="text-danger">(*)</span></label> <br />
                                                            <img @click="addToExist(userMedia.personal_piece_identite.id)"
                                                                :src="userMedia.personal_piece_identite.src" width="300"
                                                                height="200" alt="photo" draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="piece_identite" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="domicile">Justification de domicile <span
                                                                    class="text-danger">(*)</span></label> <br />
                                                            <img @click="addToExist(userMedia.personal_justification_domicile.id)"
                                                                :src="userMedia.personal_justification_domicile.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="justification_domicile" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="bank">Relevé d'identité bancaire <span
                                                                    class="text-danger">(*)</span></label> <br />
                                                            <img @click="addToExist(userMedia.personal_releve_bancaire.id)"
                                                                :src="userMedia.personal_releve_bancaire.src"
                                                                width="300" height="200" alt="photo"
                                                                draggable="false" />
                                                            <div class="d-flex text-center">
                                                                <input ref="releve_bancaire" type="file"
                                                                    class="form-control my-2 file"
                                                                    id="input-b5 item.id">
                                                            </div>
                                                        </div>
                                                        <div v-if="!load" class="form-group mb-0">
                                                            <button @click="step('last')" type="button"
                                                                class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                            <button v-if="!form.account.hide_button"
                                                                @click="updateOukaleyAccount" type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Modifier</button>
                                                            <button v-else type="button"
                                                                class="btn btn-warning text-light mt-3 mr-1">Votre
                                                                compte est confirmé</button>
                                                        </div>
                                                        <div v-else class="load"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="create_project" class="card">
                                    <div class="card-header d-flex">
                                        <h5 class="mr-auto">Créez un projet</h5>
                                        <h5> {{project_next_step ? 2 : project_next1_step ? 3 : 1}} / 3</h5>
                                    </div>
                                    <div class="card-divider"></div>
                                    <div class="card-body card-body--padding--2">
                                        <div class="row no-gutters">
                                            <div class="col-md-6 mb-5">
                                                <p>
                                                    <a href="/CHECK-LIST_DE_PITCH_DE_PROJET_Oukaley.pdf" target="_blank"
                                                        class="text-danger" style="color: red;">Télécharger la check
                                                        list de pitch
                                                        de projet</a>
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <p>
                                                    <a href="https://oukaley.com/#/guide" target="_blank"
                                                        class="text-danger" style="color: red;">Consulter le guide
                                                        de création de
                                                        projet</a>
                                                </p>
                                            </div>
                                            <div v-if="project_last_step" class="col-12 mt-2">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>
                                                            Donnez un nom à votre projet <span class="text-danger">(*) </span>
                                                        </label>
                                                        <div class="input-group mb-3">
                                                            <input v-model="form.project.nom_projet" type="text"
                                                                class="form-control"
                                                                :class="[form.project.nom_projet != '' ? 'is-valid' : '']">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.name"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">{{form.project.errors.nom_projet}}</div>
                                                        
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Sélectionnez la catégorie <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <select v-model="form.project.categorie" class="form-control"
                                                            :class="[form.project.categorie != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez une
                                                                    catégorie</option>
                                                                <option v-for="item in categories" :key="item">{{
                                                                    item.name
                                                                }}
                                                                </option>
                                                            </select>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.categorie"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">{{
                                                            form.project.errors.categorie
                                                        }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Dans quel pays sera réalisé le projet ? <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <select v-model="form.project.country" class="form-control"
                                                            :class="[form.project.country != '' ? 'is-valid' : '']">
                                                                <option value="" disabled selected>Sélectionnez un pays
                                                                </option>
                                                                <option v-for="item in country" :key="item">{{ item }}
                                                                </option>
                                                            </select>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.country"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">{{ form.project.errors.country }}
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Durée de la campagne (Jours) <span
                                                                class="text-danger">(*)</span></label> <br>
                                                        <div class="input-group mb-3">
                                                            <input v-model="form.project.duree_campagne" type="number"
                                                                class="form-control"
                                                                :class="[form.project.duree_campagne != '' ? 'is-valid' : '']"
                                                                placeholder="Ex: 10">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.time"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.duree_campagne }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Montant de la collecte attendu (CFA) <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <input v-model="form.project.montant_collecte" type="text"
                                                                class="form-control"
                                                                :class="[form.project.montant_collecte != '' ? 'is-valid' : '']"
                                                                placeholder="Ex: 50000000">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.amount"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.montant_collecte }}</div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Lien du pitch vidéo du projet</label>
                                                        <div class="input-group mb-3">
                                                            <input v-model="form.project.link" type="url"
                                                                class="form-control"
                                                                :class="[form.project.link != '' ? 'is-valid' : '']">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.link"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">{{ form.project.errors.link }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-0">
                                                    <button @click="step('project_next')" type="button"
                                                        class="btn btn-warning text-light mt-3"
                                                        :disabled="form.project.disabled_project">Suivant</button>
                                                </div>
                                            </div>
                                            <div v-else-if="project_next_step" class="col-12 mt-2">
                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Contexte et justificatif <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <textarea v-model="form.project.contexte" cols="30" rows="5"
                                                            class="form-control"
                                                            :class="[form.project.contexte != '' ? 'is-valid' : '']"></textarea>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.context"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b8" ref="context_justificatif" name="input-b8"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Plan d'exécution <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <textarea v-model="form.project.plan_execution" cols="30"
                                                            rows="5" class="form-control"
                                                            :class="[form.project.plan_execution != '' ? 'is-valid' : '']"></textarea>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.plan"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.plan_execution }}</div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b9" ref="plan_execution" name="input-b9"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Budget sommaire du projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <textarea v-model="form.project.budget_detaille" cols="30"
                                                            rows="5" class="form-control"
                                                            :class="[form.project.budget_detaille != '' ? 'is-valid' : '']"></textarea>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.budget"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.budget_detaille }}</div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b10" ref="budget_detaille" name="input-b10"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Présentation de l'équipe <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <textarea v-model="form.project.presentation_equipe" cols="30"
                                                            rows="5" class="form-control"
                                                            :class="[form.project.presentation_equipe != '' ? 'is-valid' : '']"></textarea>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.presentation"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.presentation_equipe }}</div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b11" ref="presentation_equipe" name="input-b11"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Résumé du projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <textarea v-model="form.project.resume_projet" cols="30"
                                                            rows="5" maxlength="255" class="form-control"
                                                            :class="[form.project.resume_projet != '' ? 'is-valid' : '']"></textarea>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.resume"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            {{ form.project.errors.resume_projet }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-0">
                                                    <button @click="step('project_last')" type="button"
                                                        class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                    <button @click="step('project_next1')" type="button"
                                                        class="btn btn-warning text-light mt-3 mr-1"
                                                        :disabled="form.project.disabled_project1">Suivant</button>
                                                </div>
                                            </div>
                                            <div v-else-if="project_next1_step" class="col-12 mt-2">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Sélectionnez le type de campagne pour ce projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <select v-model="form.project.type_campagne"
                                                            class="form-control">
                                                                <option value="" disabled selected>Sélectionnez un type
                                                                </option>
                                                                <option>Don sans contrepartie</option>
                                                                <option>Don avec contrepartie</option>
                                                                <option>Prêt avec intérêt</option>
                                                                <option>Prêt sans intérêt</option>
                                                                <option>Vote</option>
                                                                <option>Mécénat de compétence (ou crowdsourcing)</option>
                                                            </select>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.type"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="form.project.type_campagne != 'Vote'" class="form-group col-md-6">
                                                        <label>Sélectionnez les conditions de la campagne <span
                                                                class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <select v-model="form.project.condition_campagne"
                                                                class="form-control">
                                                                <option value="" disabled selected>Sélectionnez une
                                                                    condition</option>
                                                                <option>TOUT OU RIEN</option>
                                                                <option>TOUT MONTANT COLLECTE</option>
                                                            </select>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.condit"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Logo de profil du projet <span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <div class="input-group">
                                                            <input id="input-b6" ref="logo_profil" name="input-b6"
                                                            type="file" class="form-control">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.logo"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger mt-1 mb-3">( 40px * 40px requis )</span>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Bannière pour le projet <span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <div class="input-group">
                                                            <input id="input-b7" ref="banniere_projet" name="input-b7"
                                                            type="file" class="form-control">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.banniere"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger mt-1 mb-3">( 1000px * 600px requis )</span>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Image de présentation<span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <div class="input-group">
                                                            <input id="input-b7" ref="image_presentation" name="input-b7"
                                                            type="file" class="form-control">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.presentation"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger mt-1 mb-3">( 1000px * 600px requis )</span>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Fichiers à uploader (Planning d’exécution, budget détaillé) <span class="text-danger">(*)</span></label>
                                                        <div class="input-group mb-3">
                                                            <input id="input-b5" ref="fichier_uploader" name="input-b5"
                                                            type="file" class="form-control">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" data-toggle="tooltip" data-placement="top" :title="tooltips.file"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                                            </div>
                                                        </div>
                                                        <span class="text-danger mt-1 mb-3">( pdf, docx, jpg, jpeg, png )</span>
                                                    </div>
                                                </div>
                                                <div v-if="form.project.type_campagne == 'Don avec contrepartie'">
                                                    <div v-for="item in line" :key="item" class="row justify-content-center jumbotron">
                                                        <div v-if="item == 1">
                                                            <button @click="line++"
                                                                class="btn btn-primary btn-sm my-3">Ajouter une
                                                                ligne</button>
                                                        </div>
                                                        <div v-else>
                                                            <button @click="line++"
                                                                class="btn btn-primary btn-sm m-3">Ajouter une
                                                                ligne</button>
                                                            <button @click="removeLast"
                                                                class="btn btn-danger btn-sm my-3">Supprimer la
                                                                ligne</button>
                                                        </div>

                                                        <div class="form-row col-12">
                                                            <div class="form-group col-md-6">
                                                                <label>Titre de la contrepartie <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project.type.titre_contrepartie[item]"
                                                                    type="text" max="190" class="form-control"
                                                                    :class="[form.project.type.titre_contrepartie[item] ? 'is-valid' : '']"
                                                                    placeholder="Titre de la contrepartie">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project.type.errors.titre_contrepartie[item]
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Montant de la contrepartie (CFA) <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project.type.montant_contrepartie[item]"
                                                                    type="number" class="form-control"
                                                                    :class="[form.project.type.montant_contrepartie[item] ? 'is-valid' : '']"
                                                                    placeholder="Ex: 1000">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project.type.errors.montant_contrepartie[item]
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-row col-12">
                                                            <div class="form-group col-md-6">
                                                                <label>Mode d'acquisition <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project.type.mode_acquisition[item]"
                                                                    type="text" class="form-control"
                                                                    :class="[form.project.type.mode_acquisition[item] ? 'is-valid' : '']"
                                                                    placeholder="Mode d'acquisition de la contrepartie">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project.type.errors.mode_acquisition[item]
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Nombre de contrepartie <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project.type.nombre_contrepartie[item]"
                                                                    type="number" class="form-control"
                                                                    :class="[form.project.type.nombre_contrepartie[item] ? 'is-valid' : '']">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project.type.errors.nombre_contrepartie[item]
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-12">
                                                            <label>Description de la contrepartie <span
                                                                    class="text-danger">(*)</span></label>
                                                            <textarea
                                                                v-model="form.project.type.description_contrepartie[item]"
                                                                cols="30" rows="5" class="form-control"
                                                                :class="[form.project.type.description_contrepartie[item] ? 'is-valid' : '']"></textarea>
                                                            <div class="invalid-feedback">
                                                                {{
                                                                    form.project.type.errors.description_contrepartie[item]
                                                                }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group">
                                                            <label>Image de la contrepartie</label>
                                                            <input id="input-b8" name="input-b8"
                                                            type="file" class="form-control" @change="onFileChange($event, item)">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group mt-2">
                                                        <input v-model="cgu1" type="checkbox" id="cgu">J’ai lu, j'ai compris et j’accepte
                                                            les Conditions Générales d’Utilisation <a href="/#/cgu" target="_blank" class="cgu text-danger pr-2">(Lire)</a>
                                                    </div>
                                                    <div class="form-group d-flex">
                                                        <input v-model="cgu2" type="checkbox" id="cgu1" class="mt-1"> Vos données personnelles seront
                                                            collectées et traitées par KAB SAS dans le cadre de
                                                            l'exécution de la mission d'intermédiation en financement
                                                            participatif et la gestion des clients et des prospects du
                                                            service OUKALEY. Vous pouvez consulter notre politique de
                                                            confidentialité en cliquant ici <a :href="url + 'politik'" target="_blank"
                                                            class="cgu text-danger pr-2">(Lire)</a>
                                                    </div>
                                                </div>

                                                <div v-if="!load" class="form-group mb-0">
                                                    <button @click="step('project_last1')" type="button"
                                                        class="btn btn-secondary text-light mt-3">Précédent</button>
                                                    <button @click="createProject('brouillon')" type="button"
                                                        class="btn btn-info text-light mt-3 mx-1"
                                                        :disabled="disabled_account">Enregistrer dans le
                                                        brouillon</button>
                                                    <button @click="pay('oukaley', 'frais_dossier')" type="button"
                                                        class="btn btn-success text-light mt-3"
                                                        :disabled="disabled_account">Soumettre via le compte
                                                        oukaley</button>
                                                    <button @click="pay('cinetpay', 'frais_dossier')" type="button"
                                                        class="btn btn-success text-light mt-3"
                                                        :disabled="disabled_account">Soumettre via cinetpay</button>
                                                </div>
                                                <div v-else class="load"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="update_project" class="card">
                                    <div class="card-header d-flex">
                                        <h5>Modifier le projet</h5>
                                        <h5> {{project_next_step_update ? 2 : project_next1_step_update ? 3 : 1}} / 3</h5>
                                    </div>
                                    <div class="card-divider"></div>
                                    <div class="card-body card-body--padding--2">
                                        <div class="row no-gutters">
                                            <div class="col-md-6 mb-5">
                                                <p>
                                                    <a href="/CHECK-LIST_DE_PITCH_DE_PROJET_Oukaley.pdf" target="_blank"
                                                        class="text-danger" style="color: red;">Télécharger la check
                                                        list de pitch
                                                        de projet</a>
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <p>
                                                    <a href="https://oukaley.com/#/guide" target="_blank"
                                                        class="text-danger" style="color: red;">Consulter le guide
                                                        de création de
                                                        projet</a>
                                                </p>
                                            </div>
                                            <div v-if="project_last_step_update" class="col-12">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Donnez un nom à votre projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <input v-model="form.project_update.nom_projet" type="text"
                                                            class="form-control"
                                                            :class="[form.project_update.nom_projet != null ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.nom_projet }}</div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Sélectionnez la catégorie <span
                                                                class="text-danger">(*)</span></label>
                                                        <select v-model="form.project_update.categorie"
                                                            class="form-control"
                                                            :class="[form.project_update.categorie != null ? 'is-valid' : '']">
                                                            <option v-if="form.project_update.categorie != null"
                                                                selected>{{ form.project_update.categorie }}</option>
                                                            <option v-else value="" disabled selected>Sélectionnez une
                                                                catégorie</option>
                                                            <option v-for="item in categories" :key="item">{{
                                                                item.name
                                                            }}
                                                            </option>
                                                        </select>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.categorie }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Dans quel pays sera réalisé le projet ? <span
                                                                class="text-danger">(*)</span></label>
                                                        <select v-model="form.project_update.country"
                                                            class="form-control"
                                                            :class="[form.project_update.country != null ? 'is-valid' : '']">
                                                            <option v-if="form.project_update.country != null" selected>
                                                                {{ form.project_update.country }}</option>
                                                            <option v-else value="" disabled selected>Sélectionnez un
                                                                pays</option>
                                                            <option v-for="item in country" :key="item">{{ item }}
                                                            </option>
                                                        </select>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.country }}</div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Durée de la campagne (Jours) <span
                                                                class="text-danger">(*)</span></label> <br>
                                                        <input v-model="form.project_update.duree_campagne"
                                                            type="number" class="form-control"
                                                            :class="[form.project_update.duree_campagne != null ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.duree_campagne }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Montant de la collecte attendu (CFA) <span
                                                                class="text-danger">(*)</span></label>
                                                        <input v-model="form.project_update.montant_collecte"
                                                            type="text" class="form-control"
                                                            :class="[form.project_update.montant_collecte != null ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.montant_collecte }}</div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Lien du pitch vidéo du projet</label>
                                                        <input v-model="form.project_update.link" type="url"
                                                            class="form-control"
                                                            :class="[form.project_update.link != null ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.link }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-0">
                                                    <button @click="step('project_next_update')" type="button"
                                                        class="btn btn-warning text-light mt-3"
                                                        :disabled="form.project_update.disabled_project">Suivant</button>
                                                </div>
                                            </div>
                                            <div v-else-if="project_next_step_update" class="col-12">
                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Contexte et justificatif <span
                                                                class="text-danger">(*)</span></label>
                                                        <textarea v-model="form.project_update.contexte" cols="30"
                                                            rows="5" class="form-control"
                                                            :class="[form.project_update.contexte != null ? 'is-valid' : '']"></textarea>
                                                    </div>
                                                    <div v-if="form.project_update.project_context_justificatif != null"
                                                        class="form-group">
                                                        <img @click="addToExist(form.project_update.project_context_justificatif.id)"
                                                            :src="form.project_update.project_context_justificatif.src"
                                                            width="200" height="200" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input ref="context_justificatif" type="file"
                                                                class="form-control my-2 file" id="input-b5 item.id">
                                                        </div>
                                                    </div>
                                                    <div v-else class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b5" ref="context_justificatif" name="input-b5"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Plan d'exécution <span
                                                                class="text-danger">(*)</span></label>
                                                        <textarea v-model="form.project_update.plan_execution" cols="30"
                                                            rows="5" class="form-control"
                                                            :class="[form.project_update.plan_execution != null ? 'is-valid' : '']"></textarea>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.plan_execution }}</div>
                                                    </div>
                                                    <div v-if="form.project_update.project_plan_execution != null"
                                                        class="form-group">
                                                        <img @click="addToExist(form.project_update.project_plan_execution.id)"
                                                            :src="form.project_update.project_plan_execution.src"
                                                            width="200" height="200" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input ref="plan_execution" type="file"
                                                                class="form-control my-2 file" id="input-b5 item.id">
                                                        </div>
                                                    </div>
                                                    <div v-else class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b5" ref="plan_execution" name="input-b5"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Budget sommaire du projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <textarea v-model="form.project_update.budget_detaille"
                                                            cols="30" rows="5" class="form-control"
                                                            :class="[form.project_update.budget_detaille != null ? 'is-valid' : '']"></textarea>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.budget_detaille }}</div>
                                                    </div>
                                                    <div v-if="form.project_update.project_budget_detaille != null"
                                                        class="form-group">
                                                        <img @click="addToExist(form.project_update.project_budget_detaille.id)"
                                                            :src="form.project_update.project_budget_detaille.src"
                                                            width="200" height="200" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input ref="budget_detaille" type="file"
                                                                class="form-control my-2 file" id="input-b5 item.id">
                                                        </div>
                                                    </div>
                                                    <div v-else class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b5" ref="budget_detaille" name="input-b5"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Présentation de l'équipe <span
                                                                class="text-danger">(*)</span></label>
                                                        <textarea v-model="form.project_update.presentation_equipe"
                                                            cols="30" rows="5" class="form-control"
                                                            :class="[form.project_update.presentation_equipe != null ? 'is-valid' : '']"></textarea>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.presentation_equipe }}</div>
                                                    </div>
                                                    <div v-if="form.project_update.project_presentation_equipe != null"
                                                        class="form-group">
                                                        <img @click="addToExist(form.project_update.project_presentation_equipe.id)"
                                                            :src="form.project_update.project_presentation_equipe.src"
                                                            width="200" height="200" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input ref="presentation_equipe" type="file"
                                                                class="form-control my-2 file" id="input-b5 item.id">
                                                        </div>
                                                    </div>
                                                    <div v-else class="form-group">
                                                        <label>Ajouter une image</label>
                                                        <input id="input-b5" ref="presentation_equipe" name="input-b5"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="jumbotron">
                                                    <div class="form-group">
                                                        <label>Résumé du projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <textarea v-model="form.project_update.resume_projet" cols="30"
                                                            rows="5" maxlength="255" class="form-control"
                                                            :class="[form.project_update.resume_projet != null ? 'is-valid' : '']"></textarea>
                                                        <div class="invalid-feedback">
                                                            {{ form.project_update.errors.resume_projet }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-0">
                                                    <button @click="step('project_last_update')" type="button"
                                                        class="btn btn-secondary text-light mt-3 mr-1">Précédent</button>
                                                    <button @click="step('project_next1_update')" type="button"
                                                        class="btn btn-warning text-light mt-3 mr-1"
                                                        :disabled="form.project_update.disabled_project1">Suivant</button>
                                                </div>
                                            </div>
                                            <div v-else-if="project_next1_step_update" class="col-12">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Sélectionnez le type de campagne pour ce projet <span
                                                                class="text-danger">(*)</span></label>
                                                        <select v-model="form.project_update.type_campagne"
                                                            class="form-control">
                                                            <option value="" disabled selected>Sélectionnez le type de
                                                                campagne</option>
                                                            <option v-for="item in type_campagne" :key="item">{{ item }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div v-if="form.project_update.type_campagne != 'Vote'" class="form-group col-md-6">
                                                        <label>Sélectionnez les conditions de la campagne <span
                                                                class="text-danger">(*)</span></label>
                                                        <select v-model="form.project_update.condition_campagne"
                                                            class="form-control">
                                                            <option value="" disabled selected>Sélectionnez une
                                                                condition</option>
                                                            <option v-for="item in condition_campagne" :key="item">
                                                                {{ item }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div v-if="form.project_update.project_logo != null"
                                                        class="form-group col-md-6">
                                                        <label>Logo de profil du projet <span class="text-danger">( * / jpg, jpeg, png )</span></label> <br />
                                                        <img @click="addToExist(form.project_update.project_logo.id)"
                                                            :src="form.project_update.project_logo.src" width="150"
                                                            height="150" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input id="input-b6" ref="logo_profil" name="input-b6"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <span class="text-danger mt-1">( 40px * 40px requis )</span>
                                                    </div>
                                                    <div v-else class="form-group col-md-6">
                                                        <label>Logo de profil du projet <span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <input id="input-b6" ref="logo_profil" name="input-b6"
                                                            type="file" class="form-control">
                                                        <span class="text-danger mt-1">( 40px * 40px requis )</span>
                                                    </div>
                                                    <div v-if="form.project_update.project_image != null"
                                                        class="form-group col-md-6">
                                                        <label>Bannière pour le projet <span class="text-danger">( * / jpg, jpeg, png )</span></label> <br />
                                                        <img @click="addToExist(form.project_update.project_image.id)"
                                                            :src="form.project_update.project_image.src" width="150"
                                                            height="150" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input id="input-b7" ref="banniere_projet" name="input-b7"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <span class="text-danger mt-1">( 1000px * 600px requis )</span>
                                                    </div>
                                                    <div v-else class="form-group col-md-6">
                                                        <label>Bannière pour le projet <span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <input id="input-b7" ref="banniere_projet" name="input-b7"
                                                            type="file" class="form-control">
                                                        <span class="text-danger mt-1">( 1000px * 600px requis )</span>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div v-if="form.project_update.image_presentation != null"
                                                        class="form-group col-md-6">
                                                        <label>Image de présentation <span class="text-danger">( * / jpg, jpeg, png )</span></label> <br />
                                                        <img @click="addToExist(form.project_update.image_presentation.id)"
                                                            :src="form.project_update.image_presentation.src" width="150"
                                                            height="150" alt="photo" draggable="false" />
                                                        <div class="d-flex text-center">
                                                            <input id="input-b7" ref="image_presentation" name="input-b7"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <span class="text-danger mt-1">( 1000px * 600px requis )</span>
                                                    </div>
                                                    <div v-else class="form-group col-md-6">
                                                        <label>Image de présentation <span class="text-danger">( * / jpg, jpeg, png )</span></label>
                                                        <input id="input-b7" ref="image_presentation" name="input-b7"
                                                            type="file" class="form-control">
                                                        <span class="text-danger mt-1">( 1000px * 600px requis )</span>
                                                    </div>
                                                    <div v-if="form.project_update.project_file != null"
                                                        class="form-group col-md-6">
                                                        <label>Fichiers à uploader (Planning d’exécution, budget détaillé) <span class="text-danger">(*)</span></label>
                                                        <br />
                                                        <a :href="form.project_update.project_file.src"
                                                            @click="addToExist(form.project_update.project_file.id)"
                                                            class="btn btn-primary btn-sm" target="_blank">Télécharger
                                                            le fichier</a>
                                                        <div class="d-flex text-center">
                                                            <input id="input-b5" ref="fichier_uploader" name="input-b5"
                                                                type="file" class="form-control">
                                                        </div>
                                                        <span class="text-danger mt-1">( pdf, docx, jpg, jpeg, png )</span>
                                                    </div>
                                                    <div v-else class="form-group col-md-6">
                                                        <label>Fichiers à uploader (Planning d’exécution, budget détaillé) <span class="text-danger">(*)</span></label>
                                                        <input id="input-b5" ref="fichier_uploader" name="input-b5"
                                                            type="file" class="form-control">
                                                        <span class="text-danger mt-1">( pdf, docx, jpg, jpeg, png )</span>
                                                    </div>
                                                </div>

                                                <div
                                                    v-if="form.project_update.type_campagne == 'Don avec contrepartie'">
                                                    <div v-for="item in line" :key="item"
                                                        class="row justify-content-center jumbotron">
                                                        <div v-if="item == 1">
                                                            <button @click="line++"
                                                                class="btn btn-primary btn-sm my-3">Ajouter une
                                                                ligne</button>
                                                        </div>
                                                        <div v-else>
                                                            <button @click="line++"
                                                                class="btn btn-primary btn-sm m-3">Ajouter une
                                                                ligne</button>
                                                            <button @click="removeLast"
                                                                class="btn btn-danger btn-sm my-3">Supprimer la
                                                                ligne</button>
                                                        </div>

                                                        <div class="form-row col-12">
                                                            <div class="form-group col-md-6">
                                                                <label>Titre de la contrepartie <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project_update.type.titre_contrepartie[item - 1]"
                                                                    type="text" class="form-control"
                                                                    :class="[form.project_update.type.titre_contrepartie[item - 1] ? 'is-valid' : '']"
                                                                    placeholder="Titre de la contrepartie">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project_update.type.errors.titre_contrepartie[item
                                                                            - 1]
                                                                    }}</div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Montant de la contrepartie (CFA) <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project_update.type.montant_contrepartie[item - 1]"
                                                                    type="number" class="form-control"
                                                                    :class="[form.project_update.type.montant_contrepartie[item - 1] ? 'is-valid' : '']"
                                                                    placeholder="Ex: 1000">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project_update.type.errors.montant_contrepartie[item
                                                                            - 1]
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="form-row col-12">
                                                            <div class="form-group col-md-6">
                                                                <label>Mode d'acquisition <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project_update.type.mode_acquisition[item - 1]"
                                                                    type="text" class="form-control"
                                                                    :class="[form.project_update.type.mode_acquisition[item - 1] ? 'is-valid' : '']"
                                                                    placeholder="Mode d'acquisition de la contrepartie">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project_update.type.errors.mode_acquisition[item
                                                                            - 1]
                                                                    }}</div>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Nombre de contrepartie <span
                                                                        class="text-danger">(*)</span></label>
                                                                <input
                                                                    v-model="form.project_update.type.nombre_contrepartie[item - 1]"
                                                                    type="number" class="form-control"
                                                                    :class="[form.project_update.type.nombre_contrepartie[item - 1] ? 'is-valid' : '']">
                                                                <div class="invalid-feedback">
                                                                    {{
                                                                        form.project_update.type.errors.nombre_contrepartie[item
                                                                            - 1]
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-12">
                                                            <label>Description de la contrepartie <span
                                                                    class="text-danger">(*)</span></label>
                                                            <textarea
                                                                v-model="form.project_update.type.description_contrepartie[item - 1]"
                                                                cols="30" rows="5" class="form-control"
                                                                :class="[form.project_update.type.description_contrepartie[item - 1] ? 'is-valid' : '']"></textarea>
                                                            <div class="invalid-feedback">
                                                                {{
                                                                    form.project_update.type.errors.description_contrepartie[item
                                                                        - 1]
                                                                }}</div>
                                                        </div>

                                                        <div class="form-group">
                                                            <label>Image de la contrepartie <span class="text-danger">(*)</span></label>
                                                            <input id="input-b8" name="input-b8"
                                                            type="file" class="form-control" @change="updateOnFileChange($event, item)">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group mt-2">
                                                        <input v-model="cgu3" type="checkbox" id="cgu">
                                                        <label for="cgu pr-2">J’ai lu, j'ai compris et j’accepte
                                                            les Conditions Générales d’Utilisation</label>
                                                        <a href="/#/cgu" target="_blank" class="cgu pr-2">(Lire)</a>
                                                    </div>
                                                    <div class="form-group d-flex">
                                                        <input v-model="cgu4" type="checkbox" class="mt-1">
                                                        <label for="cgu pr-2">Vos données personnelles seront
                                                            collectées et traitées par KAB SAS dans le cadre de
                                                            l'exécution de la mission d'intermédiation en financement
                                                            participatif et la gestion des clients et des prospects du
                                                            service OUKALEY. Vous pouvez consulter notre politique de
                                                            confidentialité en cliquant ici</label>
                                                        <a :href="url + 'politik'" target="_blank"
                                                            class="cgu pr-2">(Lire)</a>
                                                    </div>
                                                </div>

                                                <div v-if="!load" class="form-group mb-0">
                                                    <button @click="step('project_last1_update')" type="button"
                                                        class="btn btn-secondary text-light mt-3">Précédent</button>
                                                    <button @click="updateProject('brouillon')" type="button"
                                                        class="btn btn-info text-light mt-3 mx-1"
                                                        :disabled="disabled_account">Enregistrer</button>

                                                    <button v-if="form.project_update.frais != 'ACCEPTED'" @click="pay('oukaley', 'frais_dossier_update')"
                                                        type="button" class="btn btn-success text-light mt-3"
                                                        :disabled="disabled_account">Soumettre via le compte
                                                        oukaley</button>
                                                    <button v-if="form.project_update.frais != 'ACCEPTED'" @click="pay('cinetpay', 'frais_dossier_update')"
                                                        type="button" class="btn btn-success text-light mt-3"
                                                        :disabled="disabled_account">Soumettre via cinetpay</button>
                                                        
                                                </div>
                                                <div v-else class="load"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="decaissement_screen" class="dashboard">
                                    <div class="dashboard__orders card">
                                        <div class="card-header">
                                            <h5>Historique des décaissements</h5>
                                        </div>
                                        <div class="card-divider"></div>
                                        <div class="card-table">
                                            <div class="table-responsive-sm">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Nom du projet</th>
                                                            <th>Etape</th>
                                                            <th>Montant</th>
                                                            <th>Moyen de paiement</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in decaissements" :key="item.id">
                                                            <td>{{ item.project.name }}</td>
                                                            <td>{{ item.decaissement.step }}</td>
                                                            <td>{{ parseInt(item.decaissement.amount).toLocaleString("en-US") }}</td>
                                                            <td>{{ item.decaissement.payment_method }}</td>
                                                            <td>
                                                                <button class="btn btn-primary btn-sm">{{ item.decaissement.status == 'PENDING' ? 'En Cours' : 'Succès' }}</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <pagination v-model="projet" :records="project_total" :per-page="10"
                                        @paginate="getProjects" /> -->
                                </div>
                                <div v-if="profil" class="card">
                                    <div class="card-header">
                                        <h5>Modifier le profile</h5>
                                    </div>
                                    <div class="card-divider"></div>
                                    <div class="card-body card-body--padding--2">
                                        <div class="row no-gutters">
                                            <div class="col-12">
                                                <div class="form-row justify-content-center">
                                                    <div class="form-grou">
                                                        <label for="piece">Photo<span
                                                                class="text-danger">(*)</span></label>
                                                        <input id="input-b5" ref="image_user" name="input-b5"
                                                            type="file" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Nom <span class="text-danger">(*)</span></label>
                                                        <input v-model="form.profil.firstname" type="text"
                                                            class="form-control"
                                                            :class="[form.profil.firstname != '' ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">{{ form.profil.errors.firstname }}
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Prenoms <span class="text-danger">(*)</span></label>
                                                        <input v-model="form.profil.lastname" type="text"
                                                            class="form-control"
                                                            :class="[form.profil.lastname != '' ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">{{ form.profil.errors.lastname }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Email <span class="text-danger">(*)</span></label>
                                                        <input v-model="form.profil.email" type="email"
                                                            class="form-control is-valid" disabled="disabled">
                                                        <div class="invalid-feedback">{{ form.profil.errors.email }}
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Contact <span class="text-danger">(*)</span></label>
                                                        <input v-model.trim="form.profil.phone" type="text"
                                                            class="form-control"
                                                            :class="[form.profil.phone != '' ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">{{ form.profil.errors.phone }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row justify-content-center">
                                                    <div class="form-group col-md-6">
                                                        <label for="signup-confirm">Ancien mot de passe <span
                                                                class="text-danger">(*)</span></label>
                                                        <input v-model="form.profil.old_password" id="signup-confirm"
                                                            type="password" class="form-control"
                                                            :class="[form.profil.old_password != '' ? 'is-valid' : '']">
                                                        <div class="invalid-feedback">
                                                            {{ form.profil.errors.old_password }}</div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-0">
                                                    <button v-if="!load" @click="updateAccount" type="button"
                                                        class="btn btn-warning text-light mt-3"
                                                        :disabled="form.profil.disabled_profil">Modifier</button>
                                                    <div v-else class="load"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-space block-space--layout--before-footer"></div>
            </div>
        </section>

        <!-- Modal -->
        <Password />

        <div class="modal fade" id="suivi" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Laisser une note sur le projet</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Image / Vidéo</label>
                            <input 
                                id="input-b5" 
                                ref="note_file" 
                                name="input-b5"
                                type="file" 
                                class="form-control"
                            >
                        </div>
                        <div class="form-group">
                            <textarea v-model="note" cols="30" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="sendNote()" data-dismiss="modal" type="button"
                            class="btn btn-warning text-light" :disabled="note_disabled">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="decaissement" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Faire une demande de décaissement</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <input v-model="decaissement.step" text="text" class="form-control" placeholder="Etape de décaissement" />
                        </div>
                        <div class="form-group">
                            <input v-model="decaissement.amount" text="number" class="form-control" placeholder="Montant de décaissement" />
                        </div>
                        <div class="form-group">
                            <select v-model="decaissement.payment_method" class="form-control" :class="[decaissement.payment_method != '' ? 'is-valid' : '']">
                                <option value="" disabled selected>Sélectionnez un moyen de paiement</option>
                                <option>Cartes Bancaires</option>
                                <option>Chèques</option>
                                <option>Virements</option>
                                <option>Monnaies électroniques</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="sendDecaissement()" data-dismiss="modal" type="button"
                            class="btn btn-warning text-light" :disabled="decaissement_disabled">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store';
import axios from 'axios';
import Pagination from 'v-pagination-3';
import Password from './modal/Password';
import payment from '../../helpers/cinetpay';
import exportFromJSON from "export-from-json";
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    components: {
        Pagination,
        Password,
        VueQrcode
    },
    data() {
        return {
            // Other
            url: String,
            url1: String,
            api_url: String,
            userLoad: true,
            token: null,
            user_id: Number,
            dashboard: true,
            rechargement: false,
            profil: false,
            create_project: false,
            update_project: false,
            decaissement_screen: false,
            // Contributeurs
            contributeur: false,
            contributeurs: null,
            project_id: Number,
            note: '',
            img: '',
            note_disabled: true,
            decaissement_disabled: true,
            country: [],
            exist: [],
            cgu1: false,
            cgu2: false,
            cgu3: false,
            cgu4: false,
            condition_campagne: [
                'TOUT OU RIEN',
                'TOUT MONTANT COLLECTE'
            ],
            type_campagne: [
                'Don sans contrepartie',
                'Don avec contrepartie',
                'Prêt avec intérêt',
                'Prêt sans intérêt',
                'Mécénat de compétence (ou crowdsourcing)',
            ],
            genre: ['', 'Masculin', 'Féminin'],
            categories: [],
            line: 1,
            // Donation
            donation: false,
            page_donation: 1,
            donation_total: 0,
            donations: [],
            // Account
            open_account: false,
            last_step: true,
            next_step: false,
            // Security
            securite: false,
            // Projet
            project: false,
            project_last_step: true,
            project_next_step: false,
            project_last1_step: false,
            project_next1_step: false,
            project_update: false,
            project_last_step_update: true,
            project_next_step_update: false,
            project_last1_step_update: false,
            project_next1_step_update: false,
            projet: 1,
            project_total: 0,
            projet_brouillon: 1,
            projet_brouillon_total: 0,
            projet_cours: 1,
            projet_cours_total: 0,
            projet_finance: 1,
            projet_finance_total: 0,
            projects: [],
            projects_limit: [],
            projects_brouillon: [],
            projects_finance: [],
            projects_cours: [],
            tooltips: [],
            decaissements: [],
            // Form
            form: {
                account: {
                    civility: '',
                    firstname: '',
                    lastname: '',
                    sex: '',
                    pseudo: '',
                    birthday: '',
                    borthday: '',
                    nationality: '',
                    country_residence: '',
                    localisation: '',
                    city: '',
                    phone: '',
                    type: '',
                    registre: '',
                    activity: '',
                    raison: '',
                    repertoire: '',
                    date_creation: '',
                    object: '',
                    forme_juridique: '',
                    capital_social: '',
                    pays_creation: '',
                    immatriculation: '',
                    disabled_account: true,
                    disabled_next1: true,
                    hide_button: false,
                    errors: {
                        civility: '',
                        firstname: '',
                        lastname: '',
                        sex: '',
                        pseudo: '',
                        birthday: '',
                        borthday: '',
                        nationality: '',
                        country_residence: '',
                        localisation: '',
                        city: '',
                        phone: '',
                        registre: '',
                        activity: '',
                        raison: '',
                        repertoire: '',
                        date_creation: '',
                        object: '',
                        forme_juridique: '',
                        capital_social: '',
                        pays_creation: '',
                        immatriculation: '',
                    }
                },
                project: {
                    nom_projet: '',
                    categorie: '',
                    country: '',
                    duree_campagne: '',
                    montant_collecte: '',
                    link: '',
                    contexte: '',
                    plan_execution: '',
                    budget_detaille: '',
                    presentation_equipe: '',
                    resume_projet: '',
                    condition_campagne: '',
                    type_campagne: '',
                    disabled_project: true,
                    disabled_project1: true,
                    type: {
                        titre_contrepartie: [],
                        montant_contrepartie: [],
                        mode_acquisition: [],
                        nombre_contrepartie: [],
                        description_contrepartie: [],
                        image: [],
                        errors: {
                            titre_contrepartie: [],
                            montant_contrepartie: [],
                            description_contrepartie: [],
                            mode_acquisition: [],
                            nombre_contrepartie: [],
                        }
                    },
                    errors: {
                        nom_projet: '',
                        categorie: '',
                        country: '',
                        duree_campagne: '',
                        montant_collecte: '',
                        link: '',
                        contexte: '',
                        plan_execution: '',
                        budget_detaille: '',
                        presentation_equipe: '',
                        resume_projet: '',
                        condition_campagne: '',
                        type_campagne: ''
                    }
                },
                project_update: {
                    id: 0,
                    nom_projet: '',
                    categorie: '',
                    country: '',
                    duree_campagne: '',
                    montant_collecte: '',
                    link: '',
                    contexte: '',
                    plan_execution: '',
                    budget_detaille: '',
                    presentation_equipe: '',
                    resume_projet: '',
                    condition_campagne: '',
                    type_campagne: '',
                    frais: null,
                    disabled_project: true,
                    disabled_project1: true,
                    project_context_justificatif: null,
                    project_plan_execution: null,
                    project_budget_detaille: null,
                    project_presentation_equipe: null,
                    project_image: null,
                    project_logo: null,
                    project_file: null,
                    type: {
                        titre_contrepartie: [],
                        montant_contrepartie: [],
                        description_contrepartie: [],
                        mode_acquisition: [],
                        nombre_contrepartie: [],
                        image: [],
                        errors: {
                            titre_contrepartie: [],
                            montant_contrepartie: [],
                            description_contrepartie: [],
                            mode_acquisition: [],
                            nombre_contrepartie: [],
                        }
                    },
                    errors: {
                        nom_projet: '',
                        categorie: '',
                        country: '',
                        duree_campagne: '',
                        montant_collecte: '',
                        link: '',
                        contexte: '',
                        plan_execution: '',
                        budget_detaille: '',
                        presentation_equipe: '',
                        resume_projet: '',
                        condition_campagne: '',
                        type_campagne: ''
                    }
                },
                profil: {
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    old_password: '',
                    disabled_profil: true,
                    errors: {
                        firstname: '',
                        lastname: '',
                        phone: '',
                        email: '',
                        old_password: '',
                        new_password: ''
                    }
                },
                amount: 0
            },
            context_justificatif: null,
            totalfiles4: [],
            plan_execution: null,
            totalfiles5: [],
            budget_detaille: null,
            totalfiles6: [],
            presentation_equipe: null,
            totalfiles7: [],
            userAccountType: null,
            userMedia: null,
            taxe: null,
            load: false,
            decaissement: {
                amount: '',
                payment_method: '',
                step: '',
            }
        };
    },

    computed: {
        userData() {
            return store.getters.getUserData;
            userLoad = false;
        },
    },

    updated() {
        // Note
        if (this.note != '') this.note_disabled = false;
        else this.note_disabled = true;

        // Decaissement
        if (this.decaissement.amount != '' && this.decaissement.payment_method != '' && this.decaissement.step != '') this.decaissement_disabled = false;
        else this.decaissement_disabled = true;

        // Create account

        if (this.form.account.type == 'Particulier' || store.getters.getUserData.account == 'personal') {
            if (this.form.account.firstname != '' && this.form.account.lastname != '' && this.form.account.civility != '' && this.form.account.sex != '' && this.form.account.birthday != '' && this.form.account.borthday != '' && this.form.account.nationality != '' && this.form.account.country_residence != '' && this.form.account.city != '' && this.form.account.phone != '') this.form.account.disabled_account = false;
            else this.form.account.disabled_account = true;
        }
        else if (this.form.account.type == 'Professionnel' || store.getters.getUserData.account == 'profesional') {
            if (this.form.account.registre != '' && this.form.account.activity != '' && this.form.account.raison != '' && this.form.account.repertoire != '' && this.form.account.date_creation != '' && this.form.account.object != '') this.form.account.disabled_next1 = false;
            else this.form.account.disabled_next1 = true;
        }
        else if (this.form.account.type == 'Entreprise' || store.getters.getUserData.account == 'entreprise') {
            if (this.form.account.raison != '' && this.form.account.object != '' && this.form.account.forme_juridique != '' && this.form.account.capital_social != '' && this.form.account.date_creation != '' && this.form.account.pays_creation != '') this.form.account.disabled_next1 = false;
            else this.form.account.disabled_next1 = true;
        }
        else if (this.form.account.type == 'Association et ONG' || store.getters.getUserData.account == 'association') {
            if (this.form.account.raison != '' && this.form.account.immatriculation != '' && this.form.account.date_creation != '' && this.form.account.pays_creation != '' && this.form.account.object != '' && this.form.account.localisation != '') this.form.account.disabled_next1 = false;
            else this.form.account.disabled_next1 = true;
        }


        // Create project
        if (this.form.project.nom_projet != '' && this.form.project.categorie != '' && this.form.project.country != '' && this.form.project.duree_campagne != '' && this.form.project.montant_collecte != '') this.form.project.disabled_project = false;
        else this.form.project.disabled_project = true;

        if (this.form.project.contexte != '' && this.form.project.plan_execution != '' && this.form.project.budget_detaille != '' && this.form.project.presentation_equipe != '' && this.form.project.resume_projet != '') this.form.project.disabled_project1 = false;
        else this.form.project.disabled_project1 = true;


        // Update project
        if (this.form.project_update.nom_projet != '' && this.form.project_update.categorie != '' && this.form.project_update.country != '' && this.form.project_update.duree_campagne != '' && this.form.project_update.montant_collecte != '') this.form.project_update.disabled_project = false;
        else this.form.project_update.disabled_project = true;

        if (this.form.project_update.contexte != '' && this.form.project_update.plan_execution != '' && this.form.project_update.budget_detaille != '' && this.form.project_update.presentation_equipe != '' && this.form.project_update.resume_projet != '') this.form.project_update.disabled_project1 = false;
        else this.form.project_update.disabled_project1 = true;



        // Profil;
        if (this.form.profil.firstname != '' && this.form.profil.lastname != '' && this.form.profil.phone != '' && this.form.profil.email != '' && this.form.profil.old_password != '') this.form.profil.disabled_profil = false;
        else this.form.profil.disabled_profil = true;
    },

    created() {
        this.url = process.env.VUE_APP_COPY;
        this.api_url = process.env.VUE_APP_URL;
        this.url1 = process.env.VUE_APP_COPY1;
        // Account
        this.form.account.firstname = store.getters.getUserData.name.substr(0, store.getters.getUserData.name.indexOf(' '));
        this.form.account.lastname = store.getters.getUserData.name.substring(store.getters.getUserData.name.indexOf(' ') + 1);
        this.form.account.phone = store.getters.getUserData.phone;
        this.form.account.country_residence = store.getters.getUserData.country;
        this.form.account.sex = store.getters.getUserData.sex;
        // Profil
        this.form.profil.firstname = store.getters.getUserData.name.substr(0, store.getters.getUserData.name.indexOf(' '));
        this.form.profil.lastname = store.getters.getUserData.name.substring(store.getters.getUserData.name.indexOf(' ') + 1);
        this.form.profil.email = store.getters.getUserData.email;
        this.form.profil.phone = store.getters.getUserData.phone;
        // User
        this.user_id = store.getters.getUserData.id;
        this.token = store.getters.getUserData.token;
        this.getCountry();
        this.getCategorie();
        this.getProjects();
        this.getBrouillonProjects();
        this.getFinanceProjects();
        this.getDonations();
        this.getCoursProjects();
        this.getUserAccountType();
        this.getTaxe();
        this.getTooltips();
        this.getDecaissement();
    },

    methods: {
        onFileChange(e, i) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.form.project.type.image.splice(i, 0, files[0]);
        },

        updateOnFileChange(e, i) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.form.project.type.image.splice(i, 0, files[0]);
        },

        callback() {
            return this.contributeurs;
        },
        
        getUserAccountType() {
            axios.get(this.api_url + 'account-type', {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        store.dispatch('userInfo', null);
                        store.dispatch('userInfo', collect.data);
                        this.userMedia = collect.data;
                        this.userAccountType = collect.data.account;
                        this.form.account.civility = collect.data.civility;
                        this.form.account.pseudo = collect.data.pseudo;
                        this.form.account.birthday = collect.data.birthday;
                        this.form.account.borthday = collect.data.borthday;
                        this.form.account.nationality = collect.data.nationality;
                        this.form.account.localisation = collect.data.localisation;
                        this.form.account.city = collect.data.city;
                        this.form.account.registre = collect.data.registre_metier;
                        this.form.account.activity = collect.data.activity;
                        this.form.account.raison = collect.data.raison_sociale;
                        this.form.account.repertoire = collect.data.repertoire_entreprise;
                        this.form.account.date_creation = collect.data.date_creation_entreprise;
                        this.form.account.object = collect.data.objet_societe;
                        this.form.account.forme_juridique = collect.data.forme_juridique;
                        this.form.account.capital_social = collect.data.capital_sociale;
                        this.form.account.pays_creation = collect.data.country_creation;
                        this.form.account.immatriculation = collect.data.numero_immatriculation;
                        // Add other account data
                        collect.data.account_actif == 1 ? this.form.account.hide_button = true : this.form.account.hide_button = false;
                    }
                })
                .catch(err => console.log(err));
        },

        getProjects() {
            this.projects = [];
            axios.get(this.api_url + 'user-projects' + '?page=' + this.projet, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        const sort = collect.data.reverse();
                        this.projects_limit = sort.slice(0, 5);
                        this.projects = sort;
                        this.project_total = this.projects_limit[0].project_total;
                    }
                })
                .catch(err => console.log(err));
        },

        getTooltips() {
            axios.get(this.api_url + 'form-tooltips')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.tooltips = collect.data;
                })
                .catch(err => console.log(err));
        },

        getDecaissement() {
            axios.get(this.api_url + 'decaissements', {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.decaissements = collect.data;
                })
                .catch(err => console.log(err));
        },

        getBrouillonProjects() {
            this.projects_brouillon = [];
            axios.get(this.api_url + 'user-projects' + '?page=' + this.projet_brouillon, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        collect.data.forEach(element => {
                            if (element.etat == 'Brouillon') this.projects_brouillon.push(element);
                        });
                        this.projet_brouillon_total = this.projects_brouillon[0].projet_brouillon_total;
                    }
                })
                .catch(err => console.log(err));
        },

        getFinanceProjects() {
            this.projects_finance = [];
            axios.get(this.api_url + 'user-projects' + '?page=' + this.projet_finance, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.projects_finance = [];
                        collect.data.forEach(element => {
                            if (element.etat == 'Clôturé et réussi') this.projects_finance.push(element);
                        });
                        this.projet_finance_total = this.projects_finance[0].projet_finance_total;
                    }
                })
                .catch(err => console.log(err));
        },

        getCoursProjects() {
            this.projects_cours = [];
            axios.get(this.api_url + 'user-projects' + '?page=' + this.projet_finance, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.projects_cours = [];
                        collect.data.forEach(element => {
                            if (element.etat == 'En campagne') this.projects_cours.push(element);
                        });
                        this.projet_cours_total = this.projects_cours[0].projet_cours_total;
                    }
                })
                .catch(err => console.log(err));
        },

        getTaxe() {
            axios.get(this.api_url + 'taxes')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.taxe = collect.data[0];
                })
                .catch(err => console.log(err));
        },

        getDonations() {
            axios.get(this.api_url + 'contributions' + '?page=' + this.page_donation, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        const sort = collect.data.reverse();
                        this.donations = sort;
                        this.donation_total = this.donations[0].donation_total;
                    }
                })
                .catch(err => console.log(err));
        },

        getCountry() {
            axios.get(this.api_url + 'country')
            .then(async res => {
                const collect = await res.data;
                collect.data.forEach(element => {
                    this.country.push(element.nicename);
                });
                this.country = this.country.sort();
            })
            .catch(err => console.log(err));
        },

        getCategorie() {
            axios.get(this.api_url + 'categories')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.categories = collect.data;
                    }
                })
                .catch(err => console.log(err));
        },

        handleClick(number) {
            if (number == 2) {
                this.project = true;
                this.dashboard = false;
                this.rechargement = false;
                this.profil = false;
                this.create_project = false;
                this.open_account = false;
                this.donation = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 3) {
                this.rechargement = true;
                this.project = false;
                this.dashboard = false;
                this.profil = false;
                this.create_project = false;
                this.open_account = false;
                this.donation = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 4) {
                this.profil = true;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.create_project = false;
                this.open_account = false;
                this.donation = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 5) {
                this.create_project = true;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.open_account = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 6) {
                this.open_account = true;
                this.create_project = false;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.donation = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 7) {
                this.donation = true;
                this.open_account = false;
                this.create_project = false;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 8) {
                this.update_project = true;
                this.create_project = false;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.open_account = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
            else if (number == 9) {
                this.contributeur = true;
                this.update_project = false;
                this.create_project = false;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.open_account = false;
                this.decaissement_screen = false;
            }
            else if (number == 10) {
                this.decaissement_screen = true;
                this.contributeur = false;
                this.update_project = false;
                this.create_project = false;
                this.profil = false;
                this.rechargement = false;
                this.project = false;
                this.dashboard = false;
                this.open_account = false;
            }
            else {
                this.dashboard = true;
                this.project = false;
                this.rechargement = false;
                this.profil = false;
                this.create_project = false;
                this.open_account = false;
                this.donation = false;
                this.update_project = false;
                this.contributeur = false;
                this.decaissement_screen = false;
            }
        },

        openOukaleyAccount() {
            this.load = true;
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            if (this.form.account.type == 'Particulier') {
                let formData = new FormData();
                formData.append("firstname", this.form.account.firstname);
                formData.append("lastname", this.form.account.lastname);
                formData.append("pseudo", this.form.account.pseudo);
                formData.append("birthday", this.form.account.birthday);
                formData.append("birthplace", this.form.account.borthday);
                formData.append("phone", this.form.account.phone);
                formData.append("nationality", this.form.account.nationality);
                formData.append("residence", this.form.account.country_residence);
                formData.append("civility", this.form.account.civility);
                formData.append("sex", this.form.account.sex);
                formData.append("town", this.form.account.city);

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles1 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles2 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles3 = this.$refs.releve_bancaire.files.length;

                var photo = this.$refs.photo.files;
                var totalfiles4 = this.$refs.photo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("piece_identite", piece_identite[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("releve_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("photo", photo[i]);

                axios.post(this.api_url + 'personal-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.form.account.type == 'Professionnel') {
                let formData = new FormData();
                formData.append("registre_metier", this.form.account.registre);
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("activity", this.form.account.activity);
                formData.append("repertoire_entreprise", this.form.account.repertoire);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("objet_societe", this.form.account.object);

                var rccm = this.$refs.rccm.files;
                var totalfiles1 = this.$refs.rccm.files.length;

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles2 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles3 = this.$refs.justification_domicile.files.length;

                var bank = this.$refs.bank.files;
                var totalfiles4 = this.$refs.bank.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles5 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("rccm", rccm[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("piece_identite", piece_identite[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("bank", bank[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'professional-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.form.account.type == 'Entreprise') {
                let formData = new FormData();
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("forme_juridique", this.form.account.forme_juridique);
                formData.append("capital_sociale", this.form.account.capital_social);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("country_creation", this.form.account.pays_creation);
                formData.append("localisation", this.form.account.localisation);
                formData.append("objet_societe", this.form.account.object);

                var rccm = this.$refs.rccm.files;
                var totalfiles1 = this.$refs.rccm.files.length;

                var dfe = this.$refs.dfe.files;
                var totalfiles2 = this.$refs.dfe.files.length;

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles3 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles4 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles5 = this.$refs.releve_bancaire.files.length;

                var status_certifie = this.$refs.status_certifie.files;
                var totalfiles6 = this.$refs.status_certifie.files.length;

                var piece_identite_actionnaire = this.$refs.piece_identite_actionnaire.files;
                var totalfiles7 = this.$refs.piece_identite_actionnaire.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles8 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("rccm", rccm[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("dfe", dfe[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("piece_identite_dirigeant", piece_identite[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("releve_identite_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles6; i++)
                    formData.append("statut_entreprise", status_certifie[i]);
                for (var i = 0; i < totalfiles7; i++)
                    formData.append("piece_identite_actionnaire", piece_identite_actionnaire[i]);
                for (var i = 0; i < totalfiles8; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'entreprise-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.form.account.type == 'Association et ONG') {
                let formData = new FormData();
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("capital_sociale", this.form.account.raison);
                formData.append("numero_immatriculation", this.form.account.immatriculation);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("country_creation", this.form.account.pays_creation);
                formData.append("localisation", this.form.account.localisation);
                formData.append("objet_societe", this.form.account.object);

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles1 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles2 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles3 = this.$refs.releve_bancaire.files.length;

                var status_association = this.$refs.status_association.files;
                var totalfiles4 = this.$refs.status_association.files.length;

                var copie_numero = this.$refs.copie_numero.files;
                var totalfiles5 = this.$refs.copie_numero.files.length;

                var liste_membre = this.$refs.liste_membre.files;
                var totalfiles6 = this.$refs.liste_membre.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles7 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("piece_identite_dirigeant", piece_identite[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("releve_identite_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("statut_association", status_association[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("numero_enregistrement", copie_numero[i]);
                for (var i = 0; i < totalfiles6; i++)
                    formData.append("liste_membre_bureau", liste_membre[i]);
                for (var i = 0; i < totalfiles7; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'association-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(res => {
                        const collect = res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
        },

        updateOukaleyAccount() {
            this.load = true;
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            if (this.userAccountType == 'personal') {
                let formData = new FormData();
                formData.append("firstname", this.form.account.firstname);
                formData.append("lastname", this.form.account.lastname);
                formData.append("pseudo", this.form.account.pseudo);
                formData.append("birthday", this.form.account.birthday);
                formData.append("birthplace", this.form.account.borthday);
                formData.append("phone", this.form.account.phone);
                formData.append("nationality", this.form.account.nationality);
                formData.append("residence", this.form.account.country_residence);
                formData.append("civility", this.form.account.civility);
                formData.append("sex", this.form.account.sex);
                formData.append("town", this.form.account.city);

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles1 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles2 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles3 = this.$refs.releve_bancaire.files.length;

                var photo = this.$refs.photo.files;
                var totalfiles4 = this.$refs.photo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("piece_identite", piece_identite[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("releve_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("photo", photo[i]);

                axios.post(this.api_url + 'update-personal-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(async res => {
                        const collect = await res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // store.dispatch('userInfo', null);
                            // store.dispatch('userInfo', collect.data);
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.userAccountType == 'profesional') {
                let formData = new FormData();
                formData.append("registre_metier", this.form.account.registre);
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("activity", this.form.account.activity);
                formData.append("repertoire_entreprise", this.form.account.repertoire);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("objet_societe", this.form.account.object);

                var rccm = this.$refs.rccm.files;
                var totalfiles1 = this.$refs.rccm.files.length;

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles2 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles3 = this.$refs.justification_domicile.files.length;

                var bank = this.$refs.bank.files;
                var totalfiles4 = this.$refs.bank.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles5 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("rccm", rccm[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("piece_identite", piece_identite[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("bank", bank[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'update-professional-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(async res => {
                        const collect = await res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // store.dispatch('userInfo', null);
                            // store.dispatch('userInfo', collect.data);
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.userAccountType == 'entreprise') {
                let formData = new FormData();
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("forme_juridique", this.form.account.forme_juridique);
                formData.append("capital_sociale", this.form.account.capital_social);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("country_creation", this.form.account.pays_creation);
                formData.append("localisation", this.form.account.localisation);
                formData.append("objet_societe", this.form.account.object);

                var rccm = this.$refs.rccm.files;
                var totalfiles1 = this.$refs.rccm.files.length;

                var dfe = this.$refs.dfe.files;
                var totalfiles2 = this.$refs.dfe.files.length;

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles3 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles4 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles5 = this.$refs.releve_bancaire.files.length;

                var status_certifie = this.$refs.status_certifie.files;
                var totalfiles6 = this.$refs.status_certifie.files.length;

                var piece_identite_actionnaire = this.$refs.piece_identite_actionnaire.files;
                var totalfiles7 = this.$refs.piece_identite_actionnaire.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles8 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("rccm", rccm[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("dfe", dfe[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("piece_identite_dirigeant", piece_identite[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("releve_identite_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles6; i++)
                    formData.append("statut_entreprise", status_certifie[i]);
                for (var i = 0; i < totalfiles7; i++)
                    formData.append("piece_identite_actionnaire", piece_identite_actionnaire[i]);
                for (var i = 0; i < totalfiles8; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'update-entreprise-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(async res => {
                        const collect = await res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // store.dispatch('userInfo', null);
                            // store.dispatch('userInfo', collect.data);
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else if (this.userAccountType == 'association') {
                let formData = new FormData();
                formData.append("raison_sociale", this.form.account.raison);
                formData.append("capital_sociale", this.form.account.raison);
                formData.append("numero_immatriculation", this.form.account.immatriculation);
                formData.append("date_creation_entreprise", this.form.account.date_creation);
                formData.append("country_creation", this.form.account.pays_creation);
                formData.append("localisation", this.form.account.localisation);
                formData.append("objet_societe", this.form.account.object);

                var piece_identite = this.$refs.piece_identite.files;
                var totalfiles1 = this.$refs.piece_identite.files.length;

                var justification_domicile = this.$refs.justification_domicile.files;
                var totalfiles2 = this.$refs.justification_domicile.files.length;

                var releve_bancaire = this.$refs.releve_bancaire.files;
                var totalfiles3 = this.$refs.releve_bancaire.files.length;

                var status_association = this.$refs.status_association.files;
                var totalfiles4 = this.$refs.status_association.files.length;

                var copie_numero = this.$refs.copie_numero.files;
                var totalfiles5 = this.$refs.copie_numero.files.length;

                var liste_membre = this.$refs.liste_membre.files;
                var totalfiles6 = this.$refs.liste_membre.files.length;

                var logo = this.$refs.logo.files;
                var totalfiles7 = this.$refs.logo.files.length;

                for (var i = 0; i < totalfiles1; i++)
                    formData.append("piece_identite_dirigeant", piece_identite[i]);
                for (var i = 0; i < totalfiles2; i++)
                    formData.append("justification_domicile", justification_domicile[i]);
                for (var i = 0; i < totalfiles3; i++)
                    formData.append("releve_identite_bancaire", releve_bancaire[i]);
                for (var i = 0; i < totalfiles4; i++)
                    formData.append("statut_association", status_association[i]);
                for (var i = 0; i < totalfiles5; i++)
                    formData.append("numero_enregistrement", copie_numero[i]);
                for (var i = 0; i < totalfiles6; i++)
                    formData.append("liste_membre_bureau", liste_membre[i]);
                for (var i = 0; i < totalfiles7; i++)
                    formData.append("logo", logo[i]);

                axios.post(this.api_url + 'update-association-account', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                })
                    .then(async res => {
                        const collect = await res.data;
                        this.load = false;
                        if (collect.success) {
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            // this.form.account.civility = collect.data.civility;
                            // this.form.account.pseudo = collect.data.pseudo;
                            // this.form.account.birthday = collect.data.birthday;
                            // this.form.account.borthday = collect.data.borthday;
                            // this.form.account.nationality = collect.data.nationality;
                            // this.form.account.localisation = collect.data.localisation;
                            // this.form.account.city = collect.data.city;
                            // this.form.account.registre = collect.data.registre_metier;
                            // this.form.account.activity = collect.data.activity;
                            // this.form.account.raison = collect.data.raison_sociale;
                            // this.form.account.repertoire = collect.data.repertoire_entreprise;
                            // this.form.account.date_creation = collect.data.date_creation_entreprise;
                            // this.form.account.object = collect.data.objet_societe;
                            // this.form.account.forme_juridique = collect.data.forme_juridique;
                            // this.form.account.capital_social = collect.data.capital_sociale;
                            // this.form.account.pays_creation = collect.data.country_creation;
                            // this.form.account.immatriculation = collect.data.numero_immatriculation;
                            // store.dispatch('userInfo', null);
                            // store.dispatch('userInfo', collect.data);
                            // this.step('last');
                            window.location.href = '/#/dashboard';
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
        },

        createProject(state) {
            this.load = true;
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            let formData = new FormData();
            formData.append("name", this.form.project.nom_projet);
            formData.append("categorie", this.form.project.categorie);
            formData.append("country", this.form.project.country);
            formData.append("campagn_duration", this.form.project.duree_campagne);
            formData.append("amount", this.form.project.montant_collecte);
            formData.append("project_video", this.form.project.link);
            formData.append("context", this.form.project.contexte);
            formData.append("execution_plan", this.form.project.plan_execution);
            formData.append("detailed_budget", this.form.project.budget_detaille);
            formData.append("campagne_condition", this.form.project.condition_campagne);
            formData.append("campagne_type", this.form.project.type_campagne);
            formData.append("presentation_equipe", this.form.project.presentation_equipe);
            formData.append("project_summary", this.form.project.resume_projet);
            formData.append("state", state);
            var titre_contrepartie = this.form.project.type.titre_contrepartie;
            var montant_contrepartie = this.form.project.type.montant_contrepartie;
            var mode_acquisition = this.form.project.type.mode_acquisition;
            var nombre_contrepartie = this.form.project.type.nombre_contrepartie;
            var description_contrepartie = this.form.project.type.description_contrepartie;

            for (var i = 0; i < titre_contrepartie.length; i++)
                formData.append("titre_contrepartie[]", titre_contrepartie[i]);
            for (var i = 0; i < montant_contrepartie.length; i++)
                formData.append("montant_contrepartie[]", montant_contrepartie[i]);
            for (var i = 0; i < mode_acquisition.length; i++)
                formData.append("mode_acquisition[]", mode_acquisition[i]);
            for (var i = 0; i < nombre_contrepartie.length; i++)
                formData.append("nombre_contrepartie[]", nombre_contrepartie[i]);
            for (var i = 0; i < description_contrepartie.length; i++)
                formData.append("description_contrepartie[]", description_contrepartie[i]);
            for (var i = 0; i < this.form.project.type.image.length; i++)
                formData.append("image_contrepartie[]", this.form.project.type.image[i]);

            var banniere_projet = this.$refs.banniere_projet.files;
            var totalfiles1 = this.$refs.banniere_projet.files.length;

            var fichier_uploader = this.$refs.fichier_uploader.files;
            var totalfiles2 = this.$refs.fichier_uploader.files.length;

            var logo_profil = this.$refs.logo_profil.files;
            var totalfiles3 = this.$refs.logo_profil.files.length;

            var image_presentation = this.$refs.image_presentation.files;
            var totalfiles8 = this.$refs.image_presentation.files.length;

            for (var i = 0; i < totalfiles1; i++)
                formData.append("banniere_projet", banniere_projet[i]);
            for (var i = 0; i < totalfiles2; i++)
                formData.append("fichier_uploader", fichier_uploader[i]);
            for (var i = 0; i < totalfiles3; i++)
                formData.append("logo_profil", logo_profil[i]);
            for (var i = 0; i < this.totalfiles4; i++)
                formData.append("context_justificatif", this.context_justificatif[i]);
            for (var i = 0; i < this.totalfiles5; i++)
                formData.append("plan_execution", this.plan_execution[i]);
            for (var i = 0; i < this.totalfiles6; i++)
                formData.append("budget_detaille", this.budget_detaille[i]);
            for (var i = 0; i < this.totalfiles7; i++)
                formData.append("teams", this.presentation_equipe[i]);
            for (var i = 0; i < totalfiles8; i++)
                formData.append("image_presentation", image_presentation[i]);

            axios.post(this.api_url + 'create-project', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    this.load = false;
                    if (collect.success) {
                        this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                        this.form.project.nom_projet = '';
                        this.form.project.categorie = '';
                        this.form.project.country = '';
                        this.form.project.duree_campagne = '';
                        this.form.project.montant_collecte = '';
                        this.form.project.link = '';
                        this.form.project.contexte = '';
                        this.form.project.plan_execution = '';
                        this.form.project.budget_detaille = '';
                        this.form.project.condition_campagne = '';
                        this.form.project.type_campagne = '';
                        this.form.project.presentation_equipe = '';
                        this.form.project.resume_projet = '';
                        this.handleClick(2);
                        this.getProjects();
                        this.getBrouillonProjects();
                        this.getFinanceProjects();
                        this.getCoursProjects();
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },

        updateProject(state) {
            // this.load = true;
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            let formData = new FormData();
            formData.append("id", this.form.project_update.id);
            formData.append("name", this.form.project_update.nom_projet);
            formData.append("categorie", this.form.project_update.categorie);
            formData.append("country", this.form.project_update.country);
            formData.append("campagn_duration", this.form.project_update.duree_campagne);
            formData.append("amount", this.form.project_update.montant_collecte);
            formData.append("project_video", this.form.project_update.link);
            formData.append("context", this.form.project_update.contexte);
            formData.append("execution_plan", this.form.project_update.plan_execution);
            formData.append("detailed_budget", this.form.project_update.budget_detaille);
            formData.append("campagne_condition", this.form.project_update.condition_campagne);
            formData.append("campagne_type", this.form.project_update.type_campagne);
            formData.append("presentation_equipe", this.form.project_update.presentation_equipe);
            formData.append("project_summary", this.form.project_update.resume_projet);
            formData.append("state", state);
            var titre_contrepartie = this.form.project_update.type.titre_contrepartie;
            var montant_contrepartie = this.form.project_update.type.montant_contrepartie;
            var mode_acquisition = this.form.project_update.type.mode_acquisition;
            var nombre_contrepartie = this.form.project_update.type.nombre_contrepartie;
            var description_contrepartie = this.form.project_update.type.description_contrepartie;

            for (var i = 0; i < titre_contrepartie.length; i++)
                formData.append("titre_contrepartie[]", titre_contrepartie[i]);
            for (var i = 0; i < montant_contrepartie.length; i++)
                formData.append("montant_contrepartie[]", montant_contrepartie[i]);
            for (var i = 0; i < mode_acquisition.length; i++)
                formData.append("mode_acquisition[]", mode_acquisition[i]);
            for (var i = 0; i < nombre_contrepartie.length; i++)
                formData.append("nombre_contrepartie[]", nombre_contrepartie[i]);
            for (var i = 0; i < description_contrepartie.length; i++)
                formData.append("description_contrepartie[]", description_contrepartie[i]);
            for (var i = 0; i < this.form.project_update.type.image.length; i++)
                formData.append("image_contrepartie[]", this.form.project_update.type.image[i]);

            var banniere_projet = this.$refs.banniere_projet.files;
            var totalfiles1 = this.$refs.banniere_projet.files.length;

            var fichier_uploader = this.$refs.fichier_uploader.files;
            var totalfiles2 = this.$refs.fichier_uploader.files.length;

            var logo_profil = this.$refs.logo_profil.files;
            var totalfiles3 = this.$refs.logo_profil.files.length;

            var image_presentation = this.$refs.image_presentation.files;
            var totalfiles8 = this.$refs.image_presentation.files.length;

            for (var i = 0; i < totalfiles1; i++)
                formData.append("banniere_projet", banniere_projet[i]);
            for (var i = 0; i < totalfiles2; i++)
                formData.append("fichier_uploader", fichier_uploader[i]);
            for (var i = 0; i < totalfiles3; i++)
                formData.append("logo_profil", logo_profil[i]);
            for (var i = 0; i < this.totalfiles4; i++)
                formData.append("context_justificatif", this.context_justificatif[i]);
            for (var i = 0; i < this.totalfiles5; i++)
                formData.append("plan_execution", this.plan_execution[i]);
            for (var i = 0; i < this.totalfiles6; i++)
                formData.append("budget_detaille", this.budget_detaille[i]);
            for (var i = 0; i < this.totalfiles7; i++)
                formData.append("teams", this.presentation_equipe[i]);
            for (var i = 0; i < totalfiles8; i++)
                formData.append("image_presentation", image_presentation[i]);

            axios.post(this.api_url + 'update-project', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    this.load = false;
                    if (collect.success) {
                        this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                        this.handleClick(2);
                        this.getProjects();
                        this.getBrouillonProjects();
                        this.getFinanceProjects();
                        this.getCoursProjects();
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },

        disabledProject(project_id) {
            axios.get(this.api_url + 'disabled-project/' + project_id, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                        this.getProjects();
                        this.getBrouillonProjects();
                        this.getFinanceProjects();
                        this.getCoursProjects();
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },

        updateAccount() {
            this.load = true;
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            let formData = new FormData();
            formData.append("name", this.form.profil.firstname + ' ' + this.form.profil.lastname);
            formData.append("phone", this.form.profil.phone);
            formData.append("password", this.form.profil.old_password);

            var image_user = this.$refs.image_user.files;

            for (var i = 0; i < image_user.length; i++)
                formData.append("image", image_user[i]);

            axios.post(this.api_url + 'update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    this.load = false;
                    if (collect.success) {
                        this.form.profil.old_password = '';
                        store.dispatch('userInfo', null);
                        store.dispatch('userInfo', collect.data);
                        this.emitter.emit('event', { 'message': 'Mise à jour effectué', 'color': 'success' });
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },

        updateProjet(data, state = null) {
            if (state == 'Avec reserve') {
                this.load = true;
                axios.post(this.api_url + 'projects/projet-brouillon')
                    .then(res => {
                        const collect = res.data;
                        this.load = false;
                        if (collect.success) {
                            this.form.project_update.id = data.project.id,
                                this.form.project_update.nom_projet = data.project.name,
                                this.form.project_update.categorie = data.categorie,
                                this.form.project_update.country = data.project.country,
                                this.form.project_update.duree_campagne = data.project.campagn_duration,
                                this.form.project_update.montant_collecte = data.project.amount,
                                this.form.project_update.link = data.video,
                                this.form.project_update.contexte = data.project.context,
                                this.form.project_update.plan_execution = data.project.execution_plan,
                                this.form.project_update.budget_detaille = data.project.detailed_budget,
                                this.form.project_update.presentation_equipe = data.project.description,
                                this.form.project_update.resume_projet = data.project.project_summary,
                                this.form.project_update.condition_campagne = data.project.campagne_condition,
                                this.form.project_update.type_campagne = data.project.campagne_type,
                                this.handleClick(8);
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
            else {
                this.form.project_update.id = data.project.id,
                this.form.project_update.nom_projet = data.project.name,
                this.form.project_update.categorie = data.categorie,
                this.form.project_update.country = data.project.country,
                this.form.project_update.duree_campagne = data.project.campagn_duration,
                this.form.project_update.montant_collecte = data.project.amount,
                this.form.project_update.link = data.video.src,
                this.form.project_update.contexte = data.project.context,
                this.form.project_update.plan_execution = data.project.execution_plan,
                this.form.project_update.budget_detaille = data.project.detailed_budget,
                this.form.project_update.presentation_equipe = data.project.description,
                this.form.project_update.resume_projet = data.project.project_summary,
                this.form.project_update.condition_campagne = data.project.campagne_condition,
                this.form.project_update.type_campagne = data.project.campagne_type,
                this.form.project_update.project_context_justificatif = data.project_context_justificatif,
                this.form.project_update.project_plan_execution = data.project_plan_execution,
                this.form.project_update.project_budget_detaille = data.project_budget_detaille,
                this.form.project_update.project_presentation_equipe = data.project_presentation_equipe,
                this.form.project_update.project_image = data.image,
                this.form.project_update.project_logo = data.logo,
                this.form.project_update.project_file = data.file,
                this.form.project_update.frais = data.project.frais.status,
                this.line = data.contreparties.length;
                data.contreparties.forEach(element => {
                    this.form.project_update.type.titre_contrepartie.push(element.titre);
                    this.form.project_update.type.montant_contrepartie.push(element.montant);
                    this.form.project_update.type.mode_acquisition.push(element.mode_acquisition);
                    this.form.project_update.type.nombre_contrepartie.push(element.nombre);
                    this.form.project_update.type.description_contrepartie.push(element.description);
                    this.form.project_update.type.image.push(element.image_contrepartie);
                })
                this.handleClick(8);
            }
        },

        sendNote() {
            this.load = true;
            let formData = new FormData();
            formData.append("message", this.note);
            formData.append("project_id", this.project_id);

            var note_file = this.$refs.note_file.files;

            for (var i = 0; i < note_file.length; i++)
                formData.append("image", note_file[i]);

            axios.post(this.api_url + 'note', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.token
                }
            })
            .then(res => {
                const collect = res.data;
                this.load = false;
                if (collect.success)
                    this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
            })
            .catch(err => {
                const collect = err.response.data;
                if (collect.errors)
                    this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                else if (collect.success == false)
                    this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                else
                    this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
            });
        },

        sendDecaissement() {
            this.load = true;
            axios.post(this.api_url + 'decaissement', {
                amount: this.decaissement.amount,
                step: this.decaissement.step,
                payment_method: this.decaissement.payment_method,
                project_id: this.project_id
            },
                {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
            .then(res => {
                const collect = res.data;
                this.load = false;
                if (collect.success)
                    this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
            })
            .catch(err => {
                const collect = err.response.data;
                if (collect.errors)
                    this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                else if (collect.success == false)
                    this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                else
                    this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
            });
        },

        pay(type, state) {
            if(type == 'rechargement') {
                var new_amount = this.form.amount + ((this.form.amount * this.taxe.user) / 100);
                if (this.form.amount == 0 || new_amount < 1000) this.emitter.emit('event', { 'message': 'Montant mimimum à recharger 1000 F', 'color': 'info' });
                else payment.cinetpay(null, new_amount, null, this.taxe.user);
            }
            else {
                if ((this.cgu1 && this.cgu2) || (this.cgu3 && this.cgu4)) {
                    if (type == 'oukaley') {
                        if (state == 'frais_dossier') {
                            this.load = true;
                            const data = {
                                name: this.form.project.nom_projet,
                                categorie: this.form.project.categorie,
                                country: this.form.project.country,
                                campagn_duration: this.form.project.duree_campagne,
                                amount: this.form.project.montant_collecte,
                                frais: this.form.project.type_campagne != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000,
                                project_video: this.form.project.link,
                                context: this.form.project.contexte,
                                execution_plan: this.form.project.plan_execution,
                                detailed_budget: this.form.project.budget_detaille,
                                campagne_condition: this.form.project.condition_campagne,
                                campagne_type: this.form.project.type_campagne,
                                presentation_equipe: this.form.project.presentation_equipe,
                                project_summary: this.form.project.resume_projet,
                                banniere_projet: this.$refs.banniere_projet.files,
                                totalfiles1: this.$refs.banniere_projet.files.length,
                                fichier_uploader: this.$refs.fichier_uploader.files,
                                totalfiles2: this.$refs.fichier_uploader.files.length,
                                logo_profil: this.$refs.logo_profil.files,
                                totalfiles3: this.$refs.logo_profil.files.length,
                                image_presentation: this.$refs.image_presentation.files,
                                totalfiles8: this.$refs.image_presentation.files.length,
                                state: state,
                                titre_contrepartie: this.form.project.type.titre_contrepartie,
                                montant_contrepartie: this.form.project.type.montant_contrepartie,
                                mode_acquisition: this.form.project.type.mode_acquisition,
                                nombre_contrepartie: this.form.project.type.nombre_contrepartie,
                                description_contrepartie: this.form.project.type.description_contrepartie,
                                image_contrepartie: this.form.project.type.image,
                            };
    
                            let formData = new FormData();
                            formData.append("name", data.name);
                            formData.append("categorie", data.categorie);
                            formData.append("country", data.country);
                            formData.append("campagn_duration", data.campagn_duration);
                            formData.append("amount", data.amount);
                            formData.append("project_video", data.project_video);
                            formData.append("context", data.context);
                            formData.append("execution_plan", data.execution_plan);
                            formData.append("detailed_budget", data.detailed_budget);
                            formData.append("campagne_condition", data.campagne_condition);
                            formData.append("campagne_type", data.campagne_type);
                            formData.append("presentation_equipe", data.presentation_equipe);
                            formData.append("project_summary", data.project_summary);
                            formData.append("state", 'frais_dossier');
                            formData.append("frais_dossier", data.frais);
                            formData.append("payment_method", 'Oukaley');
                            formData.append("mean_payment", data.type);
                            formData.append("description", 'Paiement des frais de dossier pour le projet ' + data.name);
                            formData.append("transaction_id", '');
                            formData.append("currency", '');
                            formData.append("status", 'ACCEPTED');
    
                            var titre_contrepartie = data.titre_contrepartie;
                            var montant_contrepartie = data.montant_contrepartie;
                            var mode_acquisition = data.mode_acquisition;
                            var nombre_contrepartie = data.nombre_contrepartie;
                            var description_contrepartie = data.description_contrepartie;
                            var image_contrepartie = data.image_contrepartie;
    
                            for (var i = 0; i < titre_contrepartie.length; i++)
                                formData.append("titre_contrepartie[]", titre_contrepartie[i]);
                            for (var i = 0; i < montant_contrepartie.length; i++)
                                formData.append("montant_contrepartie[]", montant_contrepartie[i]);
                            for (var i = 0; i < mode_acquisition.length; i++)
                                formData.append("mode_acquisition[]", mode_acquisition[i]);
                            for (var i = 0; i < nombre_contrepartie.length; i++)
                                formData.append("nombre_contrepartie[]", nombre_contrepartie[i]);
                            for (var i = 0; i < description_contrepartie.length; i++)
                                formData.append("description_contrepartie[]", description_contrepartie[i]);
                            for (var i = 0; i < image_contrepartie.length; i++)
                                formData.append("image_contrepartie[]", image_contrepartie[i]);
    
                            var banniere_projet = data.banniere_projet;
                            var totalfiles1 = data.totalfiles1;
    
                            var fichier_uploader = data.fichier_uploader;
                            var totalfiles2 = data.totalfiles2;
    
                            var logo_profil = data.logo_profil;
                            var totalfiles3 = data.totalfiles3;
    
                            var image_presentation = data.image_presentation;
                            var totalfiles8 = data.totalfiles8;
    
                            for (var i = 0; i < totalfiles1; i++)
                                formData.append("banniere_projet", banniere_projet[i]);
                            for (var i = 0; i < totalfiles2; i++)
                                formData.append("fichier_uploader", fichier_uploader[i]);
                            for (var i = 0; i < totalfiles3; i++)
                                formData.append("logo_profil", logo_profil[i]);
                            for (var i = 0; i < totalfiles8; i++)
                                formData.append("image_presentation", image_presentation[i]);
                            // A revoir
                            for (var i = 0; i < this.totalfiles4; i++)
                                formData.append("context_justificatif", this.context_justificatif[i]);
                            for (var i = 0; i < this.totalfiles5; i++)
                                formData.append("plan_execution", this.plan_execution[i]);
                            for (var i = 0; i < this.totalfiles6; i++)
                                formData.append("budget_detaille", this.budget_detaille[i]);
                            for (var i = 0; i < this.totalfiles7; i++)
                                formData.append("teams", this.presentation_equipe[i]);
    
                            axios.post(process.env.VUE_APP_URL + 'create-project', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': 'Bearer ' + this.token
                                }
                            })
                                .then(res => {
                                    const collect = res.data;
                                    this.load = false;
                                    if (collect.success) this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                                })
                                .catch(err => {
                                    const collect = err.response.data;
                                    this.load = false;
                                    if (collect.errors) this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                                    else if (collect.success == false) this.emitter.emit('event', { 'message': collect.message, 'color': 'error' });
                                    else this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': 'error' });
                                });
                        }
                        else if (state == 'frais_dossier_update') {
                            this.load = true;
                            const data1 = {
                                id: this.form.project_update.id,
                                name: this.form.project_update.nom_projet,
                                categorie: this.form.project_update.categorie,
                                country: this.form.project_update.country,
                                campagn_duration: this.form.project_update.duree_campagne,
                                amount: this.form.project_update.montant_collecte,
                                frais: this.form.project_update.type_campagne != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000,
                                project_video: this.form.project_update.link,
                                context: this.form.project_update.contexte,
                                execution_plan: this.form.project_update.plan_execution,
                                detailed_budget: this.form.project_update.budget_detaille,
                                campagne_condition: this.form.project_update.condition_campagne,
                                campagne_type: this.form.project_update.type_campagne,
                                presentation_equipe: this.form.project_update.presentation_equipe,
                                project_summary: this.form.project_update.resume_projet,
                                banniere_projet: this.$refs.banniere_projet.files,
                                totalfiles1: this.$refs.banniere_projet.files.length,
                                fichier_uploader: this.$refs.fichier_uploader.files,
                                totalfiles2: this.$refs.fichier_uploader.files.length,
                                logo_profil: this.$refs.logo_profil.files,
                                totalfiles3: this.$refs.logo_profil.files.length,
                                image_presentation: this.$refs.image_presentation.files,
                                totalfiles8: this.$refs.image_presentation.files.length,
                                state: state,
                                titre_contrepartie: this.form.project_update.type.titre_contrepartie,
                                montant_contrepartie: this.form.project_update.type.montant_contrepartie,
                                mode_acquisition: this.form.project_update.type.mode_acquisition,
                                nombre_contrepartie: this.form.project_update.type.nombre_contrepartie,
                                description_contrepartie: this.form.project_update.type.description_contrepartie,
                                image_contrepartie: this.form.project_update.type.image,
                                type: type,
                            };
                            let formData = new FormData();
                            formData.append("id", data1.id);
                            formData.append("name", data1.name);
                            formData.append("categorie", data1.categorie);
                            formData.append("country", data1.country);
                            formData.append("campagn_duration", data1.campagn_duration);
                            formData.append("amount", data1.amount);
                            formData.append("project_video", data1.project_video);
                            formData.append("context", data1.context);
                            formData.append("execution_plan", data1.execution_plan);
                            formData.append("detailed_budget", data1.detailed_budget);
                            formData.append("campagne_condition", data1.campagne_condition);
                            formData.append("campagne_type", data1.campagne_type);
                            formData.append("presentation_equipe", data1.presentation_equipe);
                            formData.append("project_summary", data1.project_summary);
                            formData.append("state", 'frais_dossier_update');
                            formData.append("frais_dossier", data1.frais);
                            formData.append("payment_method", 'Oukaley');
                            formData.append("mean_payment", data1.type);
                            formData.append("description", 'Paiement des frais de dossier pour le projet ' + data1.name);
                            formData.append("transaction_id", '');
                            formData.append("currency", '');
                            formData.append("status", 'ACCEPTED');
    
                            var titre_contrepartie = data1.titre_contrepartie;
                            var montant_contrepartie = data1.montant_contrepartie;
                            var mode_acquisition = data1.mode_acquisition;
                            var nombre_contrepartie = data1.nombre_contrepartie;
                            var description_contrepartie = data1.description_contrepartie;
                            var image_contrepartie = data1.image_contrepartie;
    
                            for (var i = 0; i < titre_contrepartie.length; i++)
                                formData.append("titre_contrepartie[]", titre_contrepartie[i]);
                            for (var i = 0; i < montant_contrepartie.length; i++)
                                formData.append("montant_contrepartie[]", montant_contrepartie[i]);
                            for (var i = 0; i < mode_acquisition.length; i++)
                                formData.append("mode_acquisition[]", mode_acquisition[i]);
                            for (var i = 0; i < nombre_contrepartie.length; i++)
                                formData.append("nombre_contrepartie[]", nombre_contrepartie[i]);
                            for (var i = 0; i < description_contrepartie.length; i++)
                                formData.append("description_contrepartie[]", description_contrepartie[i]);
                            for (var i = 0; i < image_contrepartie.length; i++)
                                formData.append("image_contrepartie[]", image_contrepartie[i]);
    
                            var banniere_projet = data1.banniere_projet;
                            var totalfiles1 = data1.totalfiles1;
    
                            var fichier_uploader = data1.fichier_uploader;
                            var totalfiles2 = data1.totalfiles2;
    
                            var logo_profil = data1.logo_profil;
                            var totalfiles3 = data1.totalfiles3;
    
                            var image_presentation = data1.image_presentation;
                            var totalfiles8 = data1.totalfiles8;
    
                            for (var i = 0; i < totalfiles1; i++)
                                formData.append("banniere_projet", banniere_projet[i]);
                            for (var i = 0; i < totalfiles2; i++)
                                formData.append("fichier_uploader", fichier_uploader[i]);
                            for (var i = 0; i < totalfiles3; i++)
                                formData.append("logo_profil", logo_profil[i]);
                            for (var i = 0; i < totalfiles8; i++)
                                formData.append("image_presentation", image_presentation[i]);
                            // A revoir
                            for (var i = 0; i < this.totalfiles4; i++)
                                formData.append("context_justificatif", this.context_justificatif[i]);
                            for (var i = 0; i < this.totalfiles5; i++)
                                formData.append("plan_execution", this.plan_execution[i]);
                            for (var i = 0; i < this.totalfiles6; i++)
                                formData.append("budget_detaille", this.budget_detaille[i]);
                            for (var i = 0; i < this.totalfiles7; i++)
                                formData.append("teams", this.presentation_equipe[i]);
    
                            axios.post(process.env.VUE_APP_URL + 'update-project', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': 'Bearer ' + this.token
                                }
                            })
                                .then(res => {
                                    const collect = res.data;
                                    this.load = false;
                                    if (collect.success) this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                                })
                                .catch(err => {
                                    const collect = err.response.data;
                                    this.load = false;
                                    if (collect.errors) this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                                    else if (collect.success == false) this.emitter.emit('event', { 'message': collect.message, 'color': 'error' });
                                    else this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': 'error' });
                                });
                        }
                    }
                    else {
                        if (state == 'frais_dossier') {
                            this.load = true;
                            const data = {
                                name: this.form.project.nom_projet,
                                categorie: this.form.project.categorie,
                                country: this.form.project.country,
                                campagn_duration: this.form.project.duree_campagne,
                                amount: this.form.project.montant_collecte,
                                frais: this.form.project.type_campagne != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000,
                                project_video: this.form.project.link,
                                context: this.form.project.contexte,
                                execution_plan: this.form.project.plan_execution,
                                detailed_budget: this.form.project.budget_detaille,
                                campagne_condition: this.form.project.condition_campagne,
                                campagne_type: this.form.project.type_campagne,
                                presentation_equipe: this.form.project.presentation_equipe,
                                project_summary: this.form.project.resume_projet,
                                banniere_projet: this.$refs.banniere_projet.files,
                                totalfiles1: this.$refs.banniere_projet.files.length,
                                fichier_uploader: this.$refs.fichier_uploader.files,
                                totalfiles2: this.$refs.fichier_uploader.files.length,
                                logo_profil: this.$refs.logo_profil.files,
                                totalfiles3: this.$refs.logo_profil.files.length,
                                image_presentation: this.$refs.image_presentation.files,
                                totalfiles8: this.$refs.image_presentation.files.length,
                                state: state,
                                titre_contrepartie: this.form.project.type.titre_contrepartie,
                                montant_contrepartie: this.form.project.type.montant_contrepartie,
                                mode_acquisition: this.form.project.type.mode_acquisition,
                                nombre_contrepartie: this.form.project.type.nombre_contrepartie,
                                description_contrepartie: this.form.project.type.description_contrepartie,
                                image_contrepartie: this.form.project.type.image,
                                type: type,
                            };
                            payment.cinetpay(null, null, data);
                            setTimeout(() => {
                                this.load = false; 
                            }, 10000);
                        }
                        else if (state == 'frais_dossier_update') {
                            this.load = true;
                            const data1 = {
                                id: this.form.project_update.id,
                                name: this.form.project_update.nom_projet,
                                categorie: this.form.project_update.categorie,
                                country: this.form.project_update.country,
                                campagn_duration: this.form.project_update.duree_campagne,
                                amount: this.form.project_update.montant_collecte,
                                frais: this.form.project_update.type_campagne != 'Mécénat de compétence (ou crowdsourcing)' ? 15000 : 50000,
                                project_video: this.form.project_update.link,
                                context: this.form.project_update.contexte,
                                execution_plan: this.form.project_update.plan_execution,
                                detailed_budget: this.form.project_update.budget_detaille,
                                campagne_condition: this.form.project_update.condition_campagne,
                                campagne_type: this.form.project_update.type_campagne,
                                presentation_equipe: this.form.project_update.presentation_equipe,
                                project_summary: this.form.project_update.resume_projet,
                                banniere_projet: this.$refs.banniere_projet.files,
                                totalfiles1: this.$refs.banniere_projet.files.length,
                                fichier_uploader: this.$refs.fichier_uploader.files,
                                totalfiles2: this.$refs.fichier_uploader.files.length,
                                logo_profil: this.$refs.logo_profil.files,
                                totalfiles3: this.$refs.logo_profil.files.length,
                                image_presentation: this.$refs.image_presentation.files,
                                totalfiles8: this.$refs.image_presentation.files.length,
                                state: state,
                                titre_contrepartie: this.form.project_update.type.titre_contrepartie,
                                montant_contrepartie: this.form.project_update.type.montant_contrepartie,
                                mode_acquisition: this.form.project_update.type.mode_acquisition,
                                nombre_contrepartie: this.form.project_update.type.nombre_contrepartie,
                                description_contrepartie: this.form.project_update.type.description_contrepartie,
                                image_contrepartie: this.form.project_update.type.image,
                                type: type,
                            };
                            payment.cinetpay(null, null, data1);
                            setTimeout(() => {
                                this.load = false; 
                            }, 10000);
                        }
                    }
                } else this.emitter.emit('event', { 'message': 'Veuillez cocher le CGU et les données à caractères personnelle', 'color': 'info' });
            }
        },

        step(state) {
            if (state == 'next') {
                this.next_step = true;
                this.last_step = false;
            }
            else if (state == 'last') {
                this.last_step = true;
                this.next_step = false;
            }

            if (state == 'project_next') {
                this.project_next_step = true;
                this.project_last_step = false;
                this.project_last1_step = false;
                this.project_next1_step = false;
            }
            else if (state == 'project_last') {
                this.project_last_step = true;
                this.project_next_step = false;
                this.project_last1_step = false;
                this.project_next1_step = false;
            }
            else if (state == 'project_next1') {
                this.project_next1_step = true;
                this.project_last_step = false;
                this.project_next_step = false;
                this.project_last1_step = false;

                this.context_justificatif = this.$refs.context_justificatif.files;
                this.totalfiles4 = this.$refs.context_justificatif.files.length;

                this.plan_execution = this.$refs.plan_execution.files;
                this.totalfiles5 = this.$refs.plan_execution.files.length;

                this.budget_detaille = this.$refs.budget_detaille.files;
                this.totalfiles6 = this.$refs.budget_detaille.files.length;

                this.presentation_equipe = this.$refs.presentation_equipe.files;
                this.totalfiles7 = this.$refs.presentation_equipe.files.length;
            }
            else if (state == 'project_last1') {
                this.project_next_step = true;
                this.project_next1_step = false;
                this.project_last1_step = false;
                this.project_last_step = false;
            }

            if (state == 'project_next_update') {
                this.project_next_step_update = true;
                this.project_last_step_update = false;
                this.project_last1_step_update = false;
                this.project_next1_step_update = false;
            }
            else if (state == 'project_last_update') {
                this.project_last_step_update = true;
                this.project_next_step_update = false;
                this.project_last1_step_update = false;
                this.project_next1_step_update = false;
            }
            else if (state == 'project_next1_update') {
                this.project_next1_step_update = true;
                this.project_last_step_update = false;
                this.project_next_step_update = false;
                this.project_last1_step_update = false;

                if (this.$refs.context_justificatif.files.length != 0) {
                    this.context_justificatif = this.$refs.context_justificatif.files;
                    this.totalfiles4 = this.$refs.context_justificatif.files.length;
                }

                if (this.$refs.plan_execution.files.length != 0) {
                    this.plan_execution = this.$refs.plan_execution.files;
                    this.totalfiles5 = this.$refs.plan_execution.files.length;
                }

                if (this.$refs.budget_detaille.files.length != 0) {
                    this.budget_detaille = this.$refs.budget_detaille.files;
                    this.totalfiles6 = this.$refs.budget_detaille.files.length;
                }

                if (this.$refs.presentation_equipe.files.length != 0) {
                    this.presentation_equipe = this.$refs.presentation_equipe.files;
                    this.totalfiles7 = this.$refs.presentation_equipe.files.length;
                }
            }
            else if (state == 'project_last1_update') {
                this.project_next_step_update = true;
                this.project_next1_step_update = false;
                this.project_last1_step_update = false;
                this.project_last_step_update = false;
            }
        },

        seeContributeur(id) {
            this.handleClick(9);
            this.projects.forEach(element => {
                if (element.project.id == id) this.contributeurs = element;
            });
        },

        onCopy() {
            this.emitter.emit('event', { 'message': 'Copié !', 'color': 'success' });
        },

        addToExist(id) {
            if (!this.exist.includes(id)) this.exist.push(id);
            else this.exist.splice(0, id);
        },

        downloadFile() {
            const data = this.contributeurs.contributeur;
            let data1 = [];
            data.forEach((element) => {
                data1.push({
                    "Nom & prénoms": element.user.name,
                    "Email": element.user.email,
                    "Contact": element.user.phone,
                    "Montant versé": element.contributeur.amount + ' FCFA',
                    "Type de contrepartie": element.contribution.type,
                    "Nombre de contrepartie": element.contribution.nombre,
                    "Mode de contrepartie": element.contribution.mode,
                });
            });
            const fileName = "contributeurs";
            const exportType = exportFromJSON.types.csv;
            if (data1) exportFromJSON({ data: data1, fileName: fileName, exportType: exportType });
        },

        removeLast() {
            this.line--;
            this.form.project_update.type.titre_contrepartie.splice(-1);
            this.form.project_update.type.montant_contrepartie.splice(-1);
            this.form.project_update.type.mode_acquisition.splice(-1);
            this.form.project_update.type.nombre_contrepartie.splice(-1);
            this.form.project_update.type.description_contrepartie.splice(-1);
        },

        logout() {
            store.dispatch('userInfo', null);
            window.location.href = '/';
            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
            // axios.delete(this.api_url + 'logout', {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.token
            //     }
            // })
            // .then(res => {
            //     const collect = res.data;
            //     if(collect.success) {
            //         store.dispatch('userInfo', null);
            //         window.location.href = '/';
            //         this.emitter.emit('event', {'message': collect.message, 'color': 'success'});
            //     }
            // })
            // .catch(err => {
            //     const collect = err.response.data;
            //     if(collect.errors)
            //         this.emitter.emit('event', {'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR});
            //     else if(collect.success == false)
            //         this.emitter.emit('event', {'message': collect.message, 'color': process.env.VUE_APP_ERROR});
            //     else
            //         this.emitter.emit('event', {'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR});
            // });
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.name == 'dashboard-projet') this.handleClick(5);
                if (to.name == 'account-recharge') this.handleClick(3);
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
/* .np-list {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #107fda;
  background: #ffffff;
  border-radius: 6px;
  color: #107fda;
} */
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #107fda;
  width: 110px;
  background: #107fda;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}

section {
    position: relative;
    /* top: 90px; */
}

.dashboard__address[data-v-6192fe06],
.dashboard__profile[data-v-6192fe06] {
    width: 100%;
}

.nav-tabs {
    background-color: #dee2e6 !important;
}

.file {
    width: 165px !important;
    border: none !important;
}

a.nav-link {
    color: #000;
}

a.nav-link.active {
    color: var(--yellow);
    background-color: transparent;
    font-weight: bold;
    border-bottom: 1px solid var(--yellow);
}

.card-grey {
    background-color: #f7f7f9 !important;
    border: 1px solid #fff !important;
}

.cgu {
    color: red !important;
}

.border_actif {
    border: 1px solid var(--yellow) !important;
}

.btn-link {
    text-decoration: none;
    color: rgb(92, 91, 91) !important;
}

.jumbotron {
    padding: 10px;
    margin: 5px 0;
}

@media screen and (max-width: 960px) {
    section {
        position: relative;
        top: 50px;
    }
}

.explore-projects-3-area,
.card-grey {
    background-color: #f7f7f9;
}

.dashboard__profile[data-v-dfbd1980],
.dashboard__address[data-v-dfbd1980] {
    width: 100%;
}

td img {
    width: 40px;
    height: 40px;
}

/*
    // .card
    */
.card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    border: none;
    border-radius: 0;
}

.card-body--padding--2 {
    padding: 2rem;
}

@media (max-width: 575.98px) {
    .card-body--padding--2 {
        padding: 1.5rem;
    }
}

.card-divider {
    height: 1px;
    background: #ebebeb;
}

.card-header {
    border-radius: 0;
    background: transparent;
    padding: 1.25rem 2rem;
    border: none;
}

.card-header>h5 {
    margin-bottom: 0;
}

.card-footer {
    border-radius: 0;
    background: transparent;
    padding: 1.25rem 2rem;
    border: none;
}

.card-title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 2rem;
    margin-top: -4px;
}

.card-title--lg {
    font-size: 28px;
}

/*
    // .card-table
    */
.card-table {
    font-size: 15px;
}

.card-table a:hover {
    text-decoration: underline;
}

.card-table table {
    width: 100%;
    min-width: 500px;
}

[dir=ltr] .card-table table {
    text-align: left;
}

[dir=rtl] .card-table table {
    text-align: right;
}

.highlight_ {
    background-color: var(--yellow);
    color: #fff;
}

.card-table thead th {
    border-bottom: 1px solid #ebebeb;
    text-transform: uppercase;
    font-size: 13px;
    padding: 10px 12px;
}

.card-table tbody th,
.card-table tbody td,
.card-table tfoot th,
.card-table tfoot td {
    padding: 12px 12px;
}

.card-table tfoot th,
.card-table tfoot td {
    padding-top: 16px;
    padding-bottom: 16px;
}

.card-table tbody+tbody tr th,
.card-table tbody+tbody tr td,
.card-table tbody+tfoot tr th,
.card-table tbody+tfoot tr td,
.card-table tbody tr+tr th,
.card-table tbody tr+tr td {
    border-top: 1px solid #ebebeb;
}

.card-table tbody tr:hover>*,
.card-table tfoot tr:hover>* {
    background-color: #f7f7f7;
}

[dir=ltr] .card-table th:first-child,
[dir=ltr] .card-table td:first-child {
    padding-left: 2rem;
}

[dir=ltr] .card-table th:last-child,
[dir=ltr] .card-table td:last-child {
    padding-right: 2rem;
}

[dir=rtl] .card-table th:first-child,
[dir=rtl] .card-table td:first-child {
    padding-right: 2rem;
}

[dir=rtl] .card-table th:last-child,
[dir=rtl] .card-table td:last-child {
    padding-left: 2rem;
}

.card-table .card-table__body--merge-rows th,
.card-table .card-table__body--merge-rows td {
    padding-top: 3px;
    padding-bottom: 3px;
}

.card-table .card-table__body--merge-rows tr:first-child th,
.card-table .card-table__body--merge-rows tr:first-child td {
    padding-top: 12px;
}

.card-table .card-table__body--merge-rows tr:last-child th,
.card-table .card-table__body--merge-rows tr:last-child td {
    padding-bottom: 12px;
}

.card-table .card-table__body--merge-rows tr+tr th,
.card-table .card-table__body--merge-rows tr+tr td {
    border-top: none;
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .card-table {
        font-size: 14px;
    }

    [dir=ltr] .card-table th:first-child,
    [dir=ltr] .card-table td:first-child {
        padding-left: 1.5rem;
    }

    [dir=ltr] .card-table th:last-child,
    [dir=ltr] .card-table td:last-child {
        padding-right: 1.5rem;
    }

    [dir=rtl] .card-table th:first-child,
    [dir=rtl] .card-table td:first-child {
        padding-right: 1.5rem;
    }

    [dir=rtl] .card-table th:last-child,
    [dir=rtl] .card-table td:last-child {
        padding-left: 1.5rem;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .card-table {
        font-size: 14px;
    }

    [dir=ltr] .card-table th:first-child,
    [dir=ltr] .card-table td:first-child {
        padding-left: 1.375rem;
    }

    [dir=ltr] .card-table th:last-child,
    [dir=ltr] .card-table td:last-child {
        padding-right: 1.375rem;
    }

    [dir=rtl] .card-table th:first-child,
    [dir=rtl] .card-table td:first-child {
        padding-right: 1.375rem;
    }

    [dir=rtl] .card-table th:last-child,
    [dir=rtl] .card-table td:last-child {
        padding-left: 1.375rem;
    }
}

/*
    // .container
    */
@media (min-width: 576px) {
    .container--max--sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container--max--md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container--max--lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container--max--xl {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container--max--xxl {
        max-width: 1380px;
    }
}

.container .container {
    padding: 0;
}

/* ----------------------------------------
    // Account
    // ---------------------------------------- */
/*
    // .account-nav
    */
.account-nav {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.account-nav__title {
    padding: 1.375rem 1.5rem;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.account-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
}

.account-nav__item a {
    cursor: pointer;
    display: block;
    color: #6c757d;
    padding: 7px 1.5rem;
}

.account-nav__item:hover a {
    background: #f2f2f2;
}

.account-nav__item--active a {
    color: inherit;
    font-weight: 500;
}

.account-nav__divider {
    height: 1px;
    background: #ebebeb;
    margin: 10px 0;
}

@media (min-width: 992px) {
    .account-nav__list {
        padding-bottom: 1.375rem;
    }

    [dir=ltr] .account-nav__item--active a {
        -webkit-box-shadow: 3px 0 #e52727 inset;
        box-shadow: 3px 0 #e52727 inset;
    }

    [dir=rtl] .account-nav__item--active a {
        -webkit-box-shadow: -3px 0 #e52727 inset;
        box-shadow: -3px 0 #e52727 inset;
    }
}

@media (max-width: 991.98px) {
    .account-nav {
        overflow-x: auto;
        max-width: 100%;
    }

    .account-nav__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        white-space: nowrap;
        padding: 0 8px;
    }

    .account-nav__title {
        display: none;
    }

    .account-nav__item a {
        padding: 14px 20px;
    }

    .account-nav__item--active a {
        -webkit-box-shadow: 0 -3px #e52727 inset;
        box-shadow: 0 -3px #e52727 inset;
    }
}

/*
    // .address-card
    */
.address-card {
    position: relative;
}

.address-card__badge {
    position: absolute;
    top: 12px;
}

[dir=ltr] .address-card__badge {
    right: 12px;
}

[dir=rtl] .address-card__badge {
    left: 12px;
}

.address-card__body {
    padding: 1.5rem;
    font-size: 15px;
    line-height: 18px;
}

.address-card__name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1.125rem;
}

.address-card__row+.address-card__row {
    margin-top: .75rem;
}

.address-card__row-title {
    font-size: 13px;
    color: #6c757d;
}

.address-card__footer {
    margin-top: 1.625rem;
}

.address-card__footer a:hover {
    text-decoration: underline;
}

.address-card--featured .address-card__body {
    padding: 2rem;
}

.address-card--featured .address-card__name {
    font-weight: 700;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .address-card--featured .address-card__body {
        padding: 1.5rem;
    }
}

@media (max-width: 767.98px) {
    .address-card--featured .address-card__body {
        padding: 1.375rem;
    }
}

/*
    // .addresses-list
    */
.addresses-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addresses-list__item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.addresses-list__item--new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.addresses-list__divider:last-child {
    display: none;
}

.addresses-list__plus {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 30px;
    margin-bottom: 24px;
    color: #e0e0e0;
    border: 2px solid currentColor;
    -webkit-transition: color .2s;
    transition: color .2s;
}

.addresses-list__plus:before,
.addresses-list__plus:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 4px;
    background: currentColor;
    border-radius: 1.5px;
    top: calc(50% - 2px);
}

[dir=ltr] .addresses-list__plus:before,
[dir=ltr] .addresses-list__plus:after {
    left: calc(50% - 16px);
}

[dir=rtl] .addresses-list__plus:before,
[dir=rtl] .addresses-list__plus:after {
    right: calc(50% - 16px);
}

.addresses-list__plus:after {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
}

.addresses-list__item--new:hover .addresses-list__plus {
    color: #ccc;
}

@media (min-width: 768px) {
    .addresses-list__item {
        width: calc((100% - 32px - 1px) / 3);
    }

    .addresses-list__divider:nth-child(2n) {
        width: 16px;
    }

    .addresses-list__divider:nth-child(6n) {
        width: 100%;
        height: 16px;
    }
}

@media (min-width: 480px) and (max-width: 767.98px) {
    .addresses-list__item {
        width: calc((100% - 16px - 1px) / 2);
    }

    .addresses-list__divider:nth-child(2n) {
        width: 16px;
    }

    .addresses-list__divider:nth-child(4n) {
        width: 100%;
        height: 16px;
    }
}

@media (max-width: 479px) {
    .addresses-list__item {
        width: calc((100% - 0px - 1px) / 1);
    }

    .addresses-list__divider:nth-child(2n) {
        width: 16px;
    }

    .addresses-list__divider:nth-child(2n) {
        width: 100%;
        height: 16px;
    }
}

/*
    // .dashboard
    */
.dashboard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.dashboard__orders {
    margin-top: 24px;
    width: 100%;
}

@media (min-width: 768px) {

    .dashboard__profile,
    .dashboard__address {
        width: calc(50% - 12px);
    }
}

@media (max-width: 767.98px) {

    .dashboard__profile,
    .dashboard__address {
        width: 100%;
    }

    .dashboard__address {
        margin-top: 24px;
    }
}

/*
    // .profile-card
    */
.profile-card__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.profile-card__avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 16px;
}

.profile-card__avatar img {
    border-radius: 50%;
    max-width: 100%;
}

.profile-card__name {
    font-weight: 500;
    line-height: 20px;
}

.profile-card__email {
    font-size: 15px;
    margin-bottom: 24px;
}
</style>