import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../components/Home';
import Projects from '../components/Projects';
import DetailProject from '../components/DetailProject';
import Contribution from '../components/user/Contribute';
import Dashboard from '../components/user/Dashboard';
import Team from '../components/Team';
import About from '../components/About';
import CGU from '../components/CGU';
import Charte from '../components/Charte';
import ContactUs from '../components/ContactUs';
import FAQ from '../components/FAQ';
import Campagne from '../components/Campagne';
import Guide from '../components/Guide';
import Financement from '../components/Financement';
import Important from '../components/Important';
import Politique from '../components/Politique';
import Representant from '../components/Representant';
import Media from '../components/Media';

const routes = [
    {
        path: '',
        component: Home,
        name: 'home'
    },
    {
        path: '/projects',
        component: Projects,
        name: 'projects'
    },
    {
        path: '/project/:id',
        component: DetailProject,
        name: 'detail-project'
    },
    {
        path: '/projects/:search',
        component: Projects,
        name: 'search-projects'
    },
    {
        path: '/project-view/:id',
        component: DetailProject,
        name: 'viewer'
    },
    {
        path: '/contribution/:slug/contrepartie/:id',
        component: Contribution,
        name: 'contribution'
    },
    {
        path: '/teams',
        component: Team,
        name: 'team'
    },
    {
        path: '/medias',
        component: Media,
        name: 'media'
    },
    {
        path: '/about',
        component: About,
        name: 'about'
    },
    {
        path: '/cgu',
        component: CGU,
        name: 'cgu'
    },
    {
        path: '/chart',
        component: Charte,
        name: 'charte'
    },
    {
        path: '/support',
        component: ContactUs,
        name: 'contact-us'
    },
    {
        path: '/guide',
        component: Guide,
        name: 'guide'
    },
    {
        path: '/faqs',
        component: FAQ,
        name: 'faqs'
    },
    {
        path: '/campagne',
        component: Campagne,
        name: 'campagne'
    },
    {
        path: '/funding',
        component: Financement,
        name: 'financement'
    },
    {
        path: '/know',
        component: Important,
        name: 'important'
    },
    {
        path: '/politik',
        component: Politique,
        name: 'politique'
    },
    {
        path: '/begin-member',
        component: Representant,
        name: 'representant'
    },
    {
        path: '/dashboard',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'dashboard'
    },
    {
        path: '/dashboard/projet',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'dashboard-projet'
    },
    {
        path: '/dashboard/rechargement',
        meta: { requiresAuth: true },
        component: Dashboard,
        name: 'account-recharge'
    },
];

const router = createRouter({
    history: /*createWebHistory()*/ createWebHashHistory(),
    routes,

    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ left: 0, top: 0 })
            }, 500)
        })
    }
});



export default router;