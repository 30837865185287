<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Connexion</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input v-model="form.email" type="email" id="email" class="form-control"
                                    placeholder="Entrez votre email">
                                <div class="invalid-feedback">{{ errors.email }}</div>
                            </div>
                            <div class="form-group">
                                <label for="password">Mot de passe</label>
                                <input v-model.trim="form.password" type="password" name="" id="password"
                                    class="form-control" placeholder="Mot de passe">
                                <div class="invalid-feedback">{{ errors.password }}</div>
                            </div>
                        </form>
                        <a href="#" class="text-dark" data-dismiss="modal" data-toggle="modal"
                            data-target="#modelId1">Mot de passe oublié ?</a>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="login" type="button" class="btn btn-warning text-light" :disabled="disabled" data-dismiss="modal">Connexion</button>
                    </div>
                    <p class="text-right mb-1 mr-1">
                        Première fois sur Oukaley ? 
                        <a href="#" class="text-dark" data-dismiss="modal" data-toggle="modal"
                            data-target="#modelId2">Inscription</a>
                    </p>
                </div>
            </div>
        </div>
        <Register />
        <Forget />
    </div>
</template>

<script>
import axios from 'axios';
import Register from './Register';
import Forget from './Forget';
import store from '../../../store';

export default {
    components: {
        Register,
        Forget,
    },
    data() {
        return {
            api_url: String,
            disabled: true,
            route: String,
            form: {
                email: '',
                password: ''
            },
            errors: {
                email: '',
                password: ''
            },
        };
    },

    updated() {
        if (new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}').test(this.form.email) == true && this.form.password.length >= 8) this.disabled = false;
        else this.disabled = true;
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
    },

    methods: {
        login() {
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            axios.post(this.api_url + 'sign-in', {
                email: this.form.email,
                password: this.form.password
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        store.dispatch('userInfo', collect.data);
                        if (this.route.name == 'detail-project')
                            this.emitter.emit('event', { 'message': 'Vous pouvez désormais contribuer au projet ...', 'color': 'info' });
                        else {
                            this.$router.push('/dashboard');
                            // this.emitter.emit('event', { 'message': 'Connexion acceptée', 'color': 'success' });
                        }
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },

        togglePassword() {

        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.route = to;
            }
        },
    }
}
</script>

<style scoped>
    .text-right {
        color: red;
    }
</style>