<template>
    <div>
        Representant
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    
</style>