<template>
    <div>
        <!--====== BRAND PART START ======-->
        <div class="brand-area-2 pt-80 pb-120">
            <div class="container">
                <div class="section-title text-center">
                    <h3 class="title">Nos partenaires</h3>
                </div>
                <div class="row justify-content-center">
                    <div v-for="item in partner" :key="item.id" class="col-md-3 my-5">
                        <a :href="item.link" target="_blank"><img :src="item.image.src" height="100"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="brand-area-2 pt-80 pb-120">
            <div class="container">
                <div class="section-title text-center">
                    <h3 class="title">Réseaux Sociaux</h3>
                </div>
                <div class="row brand-active">
                    <div class="col-3">
                        <div class="brand-item text-center">
                            <a href="https://web.facebook.com/oukaley" target="_blank"><i class="fa fa-facebook-official fa-2x"></i></a>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="brand-item text-center">
                            <a href="https://twitter.com/oukaley_twit" target="_blank"><i class="fa fa-twitter fa-2x"></i></a>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="brand-item text-center">
                            <a href="https://www.linkedin.com/in/oukaley-kab-497910103/" target="_blank"><i class="fa fa-linkedin fa-2x"></i></a>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="brand-item text-center">
                            <a href="https://youtube.com/@oukaleyreussirsolidairemen8492" target="_blank"><i class="fa fa-youtube fa-2x"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--====== BRAND PART ENDS ======-->
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
    props: ['partner'],
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        },
        breakpoints: {
        700: {
            itemsToShow: 3.5,
            snapAlign: 'center',
        },
        1024: {
            itemsToShow: 5,
            snapAlign: 'start',
        },
        },
    }),
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .section-title {
        padding-bottom: 60px;
    }
</style>