<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" id="modelId1" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Mot de passe oublié</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input v-model="form.email" type="email" id="email" class="form-control"
                                    :class="[form.email != '' ? '' : '']" placeholder="Entrez votre email">
                                <div class="invalid-feedback">{{ form.email_error }}</div>
                            </div>
                        </form>
                        <p>
                            Vous avez pas de compte ?
                            <a class="text-dark" href="#" data-dismiss="modal" data-toggle="modal"
                                data-target="#modelId">Connexion</a>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="forget" type="button" class="btn btn-warning text-light"
                            :disabled="disabled">Modifier</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            api_url: String,
            disabled: false,
            form: {
                email: '',
                email_error: '',
            },
        };
    },

    updated() {
        if (this.form.email == '') this.form.email_error = 'Ce champs est requis';
        else if (new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}').test(this.form.email) == false) this.form.email_error = 'Ceci n\'est pas un email';
        else this.form.email_error = '';

        if (new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}').test(this.form.email) == true) this.disabled = false;
        else this.disabled = true;
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
    },

    methods: {
        forget() {
            axios.post(this.api_url + 'forget-password', {
                email: this.form.email,
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.$router.push('/');
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_SUCCESS });
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        },
    }
}
</script>

<style>

</style>