<template>
    <div>
        <footer class="footer-area bg_cover"
            style="background-image: url(assets/images/footer-bg.jpg); padding: 30px 0;">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="footer-list mt-30">
                            <div class="footer-title">
                                <h4 class="title">Informations</h4>
                                <ul>
                                    <li><a :href="api_url + 'cgu'">CGU Oukaley</a></li>
                                    <li><a :href="api_url + 'know'">Bon à savoir</a></li>
                                    <li><a v-if="footers.length != 0" :href="footers[9].description" target="_blank">Oukablog</a></li>
                                    <li><a :href="api_url + 'politik'">Politique de confidentialité</a></li>
                                    <li><a href="#">Payer son droit de dossier</a></li>
                                    <li><a href="/CHECK-LIST_DE_PITCH_DE_PROJET_Oukaley.pdf" target="_blank">Télécharger la check list
                                            de pitch de projet</a></li>
                                    <li><a :href="api_url + 'guide'">Consulter le guide de création de projet</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="footer-list mt-30">
                            <div class="footer-title">
                                <h4 class="title">Le site web</h4>
                                <ul>
                                    <li><a :href="api_url + 'funding'">Le financement participatif?</a></li>
                                    <li><routerLink :to="{ name: 'team' }">Notre équipe</routerLink></li>
                                    <li><a :href="api_url + 'about'">A propos de OUKALEY</a></li>
                                    <li><a :href="api_url + 'chart'">La charte communautaire</a></li>
                                    <li><a :href="api_url + 'faqs'">FAQ</a></li>
                                    <li><a :href="api_url + 'campagne'">Réussir sa campagne de Crowdfunding </a></li>
                                    <li><a :href="api_url + 'contact-us'">Nous contacter</a></li>
                                    <li><a :href="api_url + 'medias'">Médiathèque</a></li>
                                    <li><a href="#">Devenir représentant-pays</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="footer-list mt-30">
                            <div class="footer-title">
                                <h4 class="title">A savoir</h4>
                                <span v-if="footers.length != 0" v-html="footers[7].description"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="footer-list mt-30">
                            <div class="footer-title">
                                <h4 class="title">A propos de nous</h4>
                                <span v-if="footers.length != 0" v-html="footers[8].description"></span>
                                <p>Tel: <a :href="'tel:' + phone1"> {{ phone1 }}</a> / <a :href="'tel:' + phone2"> {{ phone2 }}</a></p>
                                <p>Email: <a :href="'mailto:' + email"> {{ email }}</a></p>
                                <a :href="facebook" class="mx-1"><i class="fa fa-facebook-official"></i></a>
                                <a :href="twitter" class="mx-1"><i class="fa fa-twitter"></i></a>
                                <a :href="linkedin" class="mx-1"><i class="fa fa-linkedin"></i></a>
                                <a :href="youtube" class="mx-1"><i class="fa fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-copyright text-center">
                            <p>© Copyright 2020 by oukaley.com</p>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="footer-shape">
                <img src="assets/images/footer-shape.png" alt="">
            </div>
        </footer>

        <div class="go-top-area">
            <div class="go-top-wrap">
                <div class="go-top-btn-wrap">
                    <div class="go-top go-top-btn">
                        <i class="fa fa-angle-double-up"></i>
                        <i class="fa fa-angle-double-up"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            api_url: String,
            api_url1: String,
            localisation: String,
            phone1: String,
            phone2: String,
            email: String,
            facebook: String,
            twitter: String,
            linkedin: String,
            youtube: String,
            phone: String,
            footers: [],
        }
    },

    created() {
        this.api_url = process.env.VUE_APP_COPY;
        this.api_url1 = process.env.VUE_APP_URL;
        this.localisation = process.env.VUE_APP_LOCALISATION;
        this.phone1 = process.env.VUE_APP_FIRST_PHONE;
        this.phone2 = process.env.VUE_APP_SECOND_PHONE;
        this.email = process.env.VUE_APP_EMAIL;
        this.facebook = process.env.VUE_APP_FACEBOOK_URL;
        this.twitter = process.env.VUE_APP_TWITTER_URL;
        this.linkedin = process.env.VUE_APP_LINKEDIN_URL;
        this.youtube = process.env.VUE_APP_YOUTUBE_URL;
        this.phone = process.env.VUE_APP_FIRST_PHONE;
        this.getParam();
    },
    

    methods: {
        getParam() {
            axios.get(this.api_url1 + 'footer')
            .then(async res => {
                const collect = await res.data;
                if (collect.success) this.footers = collect.data;
            })
            .catch(err => console.log(err));
        },
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.header-logo {
    max-width: 214px;
}

.go-top-wrap .go-top-btn,
.go-top-area .go-top,
.go-top-wrap .go-top-btn::after {
    background-color: var(--yellow);
}
</style>