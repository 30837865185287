<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/detail.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">Contact</h3>
                            <!-- <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Contact</li>
                                </ol>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== CONTACT FORM PART START ======-->
        <section class="contact-form-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-title text-center">
                            <span>Contactez - nous</span>
                            <h3 class="title">Envoyer un message</h3>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <form>
                            <div class="conact-form-item">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Nom">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Prénoms">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="input-box mt-20">
                                            <input type="email" placeholder="Email">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Contact">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Adresse">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="input-box mt-20">
                                            <textarea id="#" cols="30" rows="10" placeholder="Message"></textarea>
                                        </div>
                                        <div class="input-box mt-20 text-center">
                                            <button @click="send" class="main-btn">Envoyer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p class="form-message"></p>
                    </div>
                </div>
            </div>
        </section>
        <!--====== CONTACT FORM PART ENDS ======-->

        <!--====== CONTACT INFO PART START ======-->
        <section class="contact-info-area">
            <div class="container-fluid p-0">
                <div class="row no-gutters justify-content-center">
                    <div class="col-lg-4 col-md-9">
                        <div class="contact-info-item">
                            <div class="icon">
                                <i class="flaticon-entrepreneur-1"></i>
                            </div>
                            <h4 class="title">About Krowd</h4>
                            <p>Morbi ut tellus ac leo molestie luctus nec <br> vehicula sed justo onec tempor rhoncus
                                <br> volutpat ras lorem.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-9">
                        <div class="contact-info-item item-2">
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <h4 class="title">About Krowd</h4>
                            <ul>
                                <li>66 Road Broklyn Street, 600 New York.</li>
                                <li>United States of America</li>
                                <li>P.O Box 0000</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-9">
                        <div class="contact-info-item item-3">
                            <div class="icon">
                                <i class="flaticon-contact"></i>
                            </div>
                            <h4 class="title">Our Address</h4>
                            <ul>
                                <li>needhelp@krowd.com</li>
                                <li>info@krowd.com</li>
                                <li>666 888 0000</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== CONTACT INFO PART ENDS ======-->

        <!--====== CONTACT INFO PART ENDS ======-->
        <div class="contact-map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63557.35865482664!2d-3.9987076!3d5.3657791!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1eb44a58c87a7%3A0xf1e2ed88e236c3c5!2sOUKALEY!5e0!3m2!1sfr!2sci!4v1648050991614!5m2!1sfr!2sci"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            api_url: String,
            form: {
                fname: '',
                lname: '',
                email: '',
                contact: '',
                adress: '',
                message: '',
                errors: {
                    fname: '',
                    lname: '',
                    email: '',
                    contact: '',
                    adress: '',
                    message: '',
                }
            }
        }
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
    },

    methods: {
        send() {
            axios.post(this.api_url + 'message', {
                firstname: this.fname,
                lastname: this.lname,
                email: this.email,
                contact: this.contact,
                adress: this.adress,
                message: this.message,
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success)
                        this.emitter.emit('event', { 'message': 'Message envoyé', 'color': process.env.VUE_APP_SUCCESS });
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_SUCCESS });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur d\'envoi', 'color': process.env.VUE_APP_ERROR });
                });
        }
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">

</style>