<template>
    <div>
        <div class="header-progress">
            <div class="progress-container">
                <div class="progress-bar" id="myBar"></div>
            </div>
        </div>
        <Header />
        <router-view></router-view>
        <Footer />

        <notifications position="top right" width="auto" max="1" duration="10" />

        {{ isIdle }}
    </div>
</template>

<script>
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import store from './store';
import { useNotification } from "@kyvg/vue3-notification";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            message: '',
            color: '',
        }
    },

    computed: {
        isIdle() {
            if (store.state.idleVue.isIdle == true && store.getters.getUserData != null) {
                store.dispatch('userInfo', null);
                this.emitter.emit('event', { 'message': 'Déconnexion ...', 'color': 'success' });
                window.location.href = '/';
            }
        },
    },

    mounted() {
        const notification = useNotification()
        this.emitter.on('event', (e) => {
            this.message = e.message;
            this.color = e.color;
            notification.notify({
                type: this.color,
                title: this.color == 'success' ? "FELICITATION 🎉🎉🎉" : this.color == 'error' ? "OUPS 😞😞😞" : "INFORMATION ⚠️⚠️⚠️",
                text: this.message
            });
        });
    },
}

// When the user scrolls the page, execute myFunction
window.onscroll = function () { myFunction() };
function myFunction() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
} 
</script>

<style>
:root {
    --yellow: #ef9b05;
    --green: #21ba45;
    --blue: #674df0;
}

@font-face {
    font-family: "Arial Rounded";
    src: local("Arial Rounded"),
        url(../public/assets/fonts/arial-rounded/unicode.arialr.ttf) format("truetype");
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Arial Rounded" !important;
}

.vue-notification {
  font-size: 16px;
}

/* .explore-projects-3-area .explore-projects-item .explore-projects-content .item > span, .main-btn {
        background-color: #ef9b05 !important;
    } */

/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    padding: 16px;
    position: fixed;
    z-index: 10;
    left: 50%;
    bottom: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

/* Loading */
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ef9b05;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 15% 0;
    margin-left: 50%;
}

.load {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ef9b05;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(50deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*PROGRESS SCROLL*/
.header-progress {
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
}

.progress-container {
    width: 100%;
    height: 4px;
}

.progress-bar {
    height: 4px;
    background: #ef9b05 !important;
    width: 0%;
}

p.VuePagination__count {
    display: none !important;
}
</style>
