<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" id="updatepassword" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Modifier le mot de passe</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="">
                            <div class="form-group">
                                <input v-model="form.old_password" type="password" id="password" class="form-control"
                                    :class="[form.old_password != '' ? 'is-valid' : '']"
                                    placeholder="Ancien mot de passe">
                                <div class="invalid-feedback">{{ form.errors.old_password }}</div>
                            </div>
                            <div class="form-group">
                                <input v-model.trim="form.password" type="password" name="" id="password"
                                    class="form-control" :class="[form.password != '' ? 'is-valid' : '']"
                                    placeholder="Nouveau mot de passe">
                                <div class="invalid-feedback">{{ form.errors.password }}</div>
                            </div>
                            <div class="form-group">
                                <input v-model.trim="form.confirm_password" type="password" name="" id="confirm"
                                    class="form-control" :class="[form.confirm_password != '' ? 'is-valid' : '']"
                                    placeholder="Confirmer le mot de passe">
                                <div class="invalid-feedback">{{ form.errors.confirm_password }}</div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="update" data-dismiss="modal" type="button" class="btn btn-warning text-light"
                            :disabled="disabled">Modifier</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import store from '../../../store';

export default {
    data() {
        return {
            api_url: String,
            disabled: true,
            form: {
                old_password: '',
                password: '',
                confirm_password: '',
                errors: {
                    old_password: '',
                    password: '',
                    confirm_password: '',
                },
            },
        };
    },

    updated() {
        if (this.form.password.length >= 8 && this.form.confirm_password == this.form.password) this.disabled = false;
        else this.disabled = true;
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.token = store.getters.getUserData.token;
    },

    methods: {
        update() {
            this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
            axios.post(this.api_url + 'update-password', {
                old_password: this.form.old_password,
                password: this.form.password
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            })
                .then(res => {
                    const collect = res.data;
                    if (collect.success) {
                        this.$router.push('/');
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_SUCCESS });
                    }
                })
                .catch(err => {
                    const collect = err.response.data;
                    if (collect.errors)
                        this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                    else if (collect.success == false)
                        this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                    else
                        this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                });
        }
    }
}
</script>

<style>

</style>