<template>
    <div>
        <!--====== BANNER PART START ======-->
        <!-- <section class="banner-slider banner-3-slider"> -->
            <Carousel>
                <Slide v-for="slide in banners" :key="slide">
                    <div class="banner-area banner-3-area bg_cover d-flex align-items-end carousel__item"
                        :style="{'background-image': 'url(' + slide.banner.src + ')'}">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="banner-content">
                                        <h3 data-animation="fadeInRightBig" data-delay="0.7s" class="title">{{slide.text.name}}</h3>
                                        <routerLink data-animation="zoomIn" data-delay="0.8s" class="main-btn"
                                            :to="{ name: 'dashboard-projet' }">Ajouter un projet</routerLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slide>

                <template #addons>
                    <Navigation />
                    <!-- <Pagination /> -->
                </template>
            </Carousel>
        <!-- </section> -->
        <!--====== BANNER PART ENDS ======-->

        <!--====== EXPLORE PROJECTS PART START ======-->
        <section class="explore-projects-3-area">
            <div class="container-fluid p-0">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-title text-center">
                            <h3 class="title">Projets en vedette </h3>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center my-2">
                    <div class="col-lg-7">
                        <div class="easy-steps-content text-center">
                            <routerLink class="main-btn" :to="{ name: 'projects' }">Voir plus
                            </routerLink>
                        </div>
                    </div>
                </div>
                <div class="carousel">
                    <div class="inner" ref="inner" :style="innerStyles">
                        <div v-for="(item, index) in projects" :key="item.project.id" class="card col-lg-3">
                            <div class="explore-projects-item mt-30">
                                <img :src="item.image.src" alt="img" width="450px" height="350">
                                <!-- <div class="icon">
                                    <a @click="like(item.project.id)" style="cursor: pointer;">
                                        <i class="fa fa-heart"></i>
                                    </a>
                                </div> -->

                                <routerLink :to="{ name: 'detail-project', params: { id: item.project.slug } }">
                                    <div class="explore-projects-content">
                                        <div class="item d-flex align-items-center">
                                            <span
                                                :style="[item.etat == 'Clôturé et échoué' ? 'background-color: red' : item.etat == 'Clôturé et réussi' ? 'background-color: var(--green)' : 'background-color: black']">{{
                                                    item.etat
                                                        == 'Clôturé et réussi' ? 'Réussi' : item.etat == 'Clôturé et échoué' ?
                                                        'Echoué' : item.etat
                                                }}</span>
                                            <p><i class="fa fa-clock-o"></i> {{ item.deadline }} Jours restant</p>
                                        </div>
                                        <h3 class="title text">{{ item.project.name }}</h3>
                                        <p class="text-description my-3 text-light">{{ item.project.project_summary }}
                                        </p>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="item align-items-center">
                                                    <p style="font-size: 15px;">
                                                        <b>Collectés:</b> {{ item.contribution.toLocaleString("en-US") }} FCFA
                                                    </p>
                                                    <p style="font-size: 15px;">
                                                        <b>Objectif:</b> {{ parseInt(item.project.amount).toLocaleString("en-US") }} FCFA
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <circle-progress :percent="parseInt((item.contribution / item.project.amount) * 100)" fill-color="#ef9b05" :show-percent="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </routerLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="projects.length > 2" class="row justify-content-center my-2">
                    <div class="col-lg-7">
                        <div class="easy-steps-content text-center">
                            <a @click="prev" class="m-1"
                                style="background-color: var(--yellow); padding: 25px; cursor: pointer; color: #fff;">
                                <i class="flaticon-back"></i>
                            </a>
                            <a @click="next"
                                style="background-color: var(--yellow); padding: 25px; cursor: pointer; color: #fff;">
                                <i class="flaticon-next"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== EXPLORE PROJECTS PART ENDS ======-->

        <!--====== 4 EASY STEPS PART START ======-->
        <section class="easy-steps-area pb-120">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-title text-center">
                            <h3 class="title">5 étapes du financement participatif</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="easy-steps-item mt-50 text-center" data-toggle="tooltip" data-placement="top" title="Si vous n’êtes pas encore inscrit, faites-le gratuitement en renseignant les quelques informations requises dans le formulaire d’inscription accessible à partir du menu « Connectez-vous ».">
                            <div class="thumb">
                                <!-- <img src="assets/images/4-easy-steps-1.png" alt="">  -->
                                <i class="flaticon-account"></i>
                            </div><br>
                            <span style="background-color: var(--yellow)">01</span>
                            <h4 class="title" v-if="steps.length != 0"><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#modelId2">{{steps[0].name}}</a></h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="easy-steps-item mt-50 text-center" data-toggle="tooltip" data-placement="top" title="Nous vous invitons à lire la page au préalable la rubrique « Consulter le guide de création de projet ».  Vous pouvez à présent nous soumettre votre projet en cliquant sur « Lancer mon projet » dans le menu. Veuillez également à ouvrir un compte Oukaley en fournissant vos pièces justificatives (KYC) afin de pouvoir recevoir votre collecte.">
                            <div class="thumb">
                                <!-- <img src="assets/images/4-easy-steps-2.png" alt=""> -->
                                <i class="flaticon-increment"></i>
                            </div><br>
                            <span style="background-color: var(--yellow)">02</span>
                            <h4 class="title" v-if="steps.length != 0">
                                <a v-if="userData == null"
                                    class="nav-link"
                                    data-toggle="modal" 
                                    data-target="#modelId"
                                >{{steps[1].name}}</a>
                                <routerLink v-else :to="{ name: 'dashboard-projet' }"
                                    class="nav-link">{{steps[1].name}}</routerLink>
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="easy-steps-item mt-50 text-center" data-toggle="tooltip" data-placement="top" title="Vous devez séduire et motiver les internautes à vous soutenir à travers une bonne stratégie de communication soutenue. Vous aurez donc à promouvoir votre campagne de Crowdfunding sur tous les canaux de communication à votre disposition aussi bien en ligne que hors ligne et animer votre page projet.">
                            <div class="thumb">
                                <!-- <img src="assets/images/4-easy-steps-3.png" alt=""> -->
                                <i class="flaticon-pay"></i>
                            </div><br>
                            <span style="background-color: var(--yellow)">03</span>
                            <h4 class="title" v-if="steps.length != 0">
                                <routerLink :to="{ name: 'campagne' }">{{steps[2].name}}
                                </routerLink>
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="easy-steps-item item-4 mt-50 text-center" data-toggle="tooltip" data-placement="top" title="Vos contributeurs vous ont fait confiance en vous soutenant car il croit en votre projet. Après avoir reçu les fonds, passez directement à la réalisation de votre projet sans plus attendre.">
                            <div class="thumb">
                                <!-- <img src="assets/images/4-easy-steps-4.png" alt=""> -->
                                <i class="flaticon-handshake"></i>
                            </div><br>
                            <span style="background-color: var(--yellow)">04</span>
                            <h4 class="title" v-if="steps.length != 0"><a href="#">{{steps[3].name}}</a></h4>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="easy-steps-item item-4 mt-50 text-center" data-toggle="tooltip" data-placement="top" title="La première contrepartie attendue est d’informer régulièrement vos contributeurs sur le déroulement de la réalisation du projet. Si vous avez prévu des contreparties, veillez à les remettre aux bénéficiaires dans le délai imparti.">
                            <div class="thumb">
                                <!-- <img src="assets/images/4-easy-steps-4.png" alt=""> -->
                                <i class="flaticon-handshake"></i>
                            </div><br>
                            <span style="background-color: var(--yellow)">05</span>
                            <h4 class="title" v-if="steps.length != 0"><a href="#">{{steps[4].name}}</a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== 4 EASY STEPS PART ENDS ======-->

        <!--====== FUN FACTS PART START ======-->
        <!-- <section class="fun-facts-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="fun-facts-item text-center">
                            <div class="icon">
                                <i class="flaticon-scrum"></i>
                            </div><br>
                            <h4 class="title odometer" data-count="690">00</h4>
                            <span>Projets</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="fun-facts-item text-center">
                            <div class="icon">
                                <i class="flaticon-mission"></i>
                            </div><br>
                            <h4 class="title odometer" data-count="8080">00</h4>
                            <span>Projets en validation</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="fun-facts-item text-center">
                            <div class="icon">
                                <i class="flaticon-team"></i>
                            </div><br>
                            <h4 class="title odometer" data-count="440">00</h4>
                            <span>Projets en cours</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="fun-facts-item text-center last-item">
                            <div class="icon">
                                <i class="flaticon-increment"></i>
                            </div><br>
                            <h4 class="title odometer" data-count="2870">00</h4>
                            <span>Projets clôturé</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fun-facts-shape-1">
                <img src="assets/images/fun-facts-shape-1.png" alt="">
            </div>
            <div class="fun-facts-shape-2">
                <img src="assets/images/fun-facts-shape-2.png" alt="">
            </div>
        </section> -->
        <!--====== FUN FACTS PART ENDS ======-->

        <!--====== TOGETHER PART START ======-->
        <section class="together-area bg_cover" style="background-image: url(assets/images/network.jpg);">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="together-content text-center">
                            <span>Avec Oukaley,</span>
                            <h3 class="title">mobilisez une communauté solidaire via Internet pour le financement des bonnes idées de projet en Afrique</h3>
                            <routerLink class="main-btn" :to="{ name: 'dashboard-projet' }">Ajouter un projet
                            </routerLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== TOGETHER PART ENDS ======-->

        <!--====== BRAND PART START ======-->
        <Patner :partner="partners" />
        <!--====== BRAND PART ENDS ======-->
        <section class="together-area guide-area bg_cover" style="background-image: url(assets/images/network.jpg);">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-title text-center">
                            <h3 class="title text-light">Newsletter</h3>
                            <div class="input-box">
                                <input v-model="email" type="email" placeholder="Adresse email">
                                <button @click="newsletter" class="main-btn main-btn-2">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import store from '../store';
import Patner from './layouts/Partner';
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    components: {
        Patner, CircleProgress, 
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            name: String,
            api_url: String,
            projects: [],
            innerStyles: {},
            step: '',
            transitioning: false,
            cards: [],
            banners: [],
            steps: [],
            partners: [],
            email: ''
        };
    },

    created() {
        this.name = process.env.VUE_APP_NAME;
        this.api_url = process.env.VUE_APP_URL;
        this.getProjects();
        this.getParam();
        setTimeout(() => {
            if (this.projects.length > 2) {
                this.next();
            }
        }, 1000);
    },

    computed: {
        userData: () => store.getters.getUserData,
    },

    // updated() {
    //     setTimeout(() => {
    //         if (this.projects.length > 2) {
    //             this.next();
    //         }
    //     }, 10000);
    // },

    methods: {
        getProjects() {
            axios.get(this.api_url + 'featured')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        const sort = collect.data.reverse();
                        // this.projects = sort;
                        sort.forEach(element => {
                            if (element.project.status == 1) this.projects.push(element);
                        })
                        this.setStep();
                        this.resetTranslate();
                    }
                })
                .catch(err => console.log(err));
        },

        getParam() {
            axios.get(this.api_url + 'banner')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.banners = collect.data;
                })
                .catch(err => console.log(err));

            axios.get(this.api_url + 'step')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.steps = collect.data;
                })
                .catch(err => console.log(err));

            axios.get(this.api_url + 'partner')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.partners = collect.data;
                })
                .catch(err => console.log(err));

        },

        like(project_id) {
            axios.get(this.api_url + 'like/' + project_id)
                .then(res => {
                    const collect = res.data;
                    console.log(collect);
                    if (collect.success)
                        this.emitter.emit('event', { 'message': 'Vous avez liké le projet', 'color': 'info' });
                })
                .catch(err => console.log(err));
        },

        newsletter() {
            axios.post(this.api_url + 'newsletter', {
                email: this.email
            })
            .then(res => {
                const collect = res.data;
                if (collect.success) {
                    this.email = '';
                    this.emitter.emit('event', { 'message': 'Demande acceptée', 'color': process.env.VUE_APP_SUCCESS });
                }
            })
            .catch(err => console.log(err));
        },

        setStep() {
            const innerWidth = '0';
            const totalCards = this.projects.length
            this.step = `${innerWidth / totalCards}px`
        },
        next() {
            if (this.transitioning) return
            this.transitioning = true
            this.moveLeft()
            this.afterTransition(() => {
                const card = this.projects.shift()
                this.projects.push(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },
        prev() {
            if (this.transitioning) return
            this.transitioning = true
            this.moveRight()
            this.afterTransition(() => {
                const card = this.projects.pop()
                this.projects.unshift(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },
        moveLeft() {
            this.innerStyles = {
                transform: `translateX(-${this.step})
                        translateX(-${this.step})`
            }
        },
        moveRight() {
            this.innerStyles = {
                transform: `translateX(${this.step})
                        translateX(-${this.step})`
            }
        },
        afterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.inner.removeEventListener('transitionend', listener)
            }
            this.$refs.inner.addEventListener('transitionend', listener)
        },
        resetTranslate() {
            this.innerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        },
    },
}

</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.carousel__item {
  height: 500px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.explore-projects-3-area {
    padding-top: 50px;
}

.fun-facts-area {
    padding-top: 117px;
}

.slick-slider {
    display: flex;
}

.banner-area .banner-content .title {
    font-size: 60px;
}

h3.text,
.text-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Circular Progress */
.vue3-circular-progressbar {
    width: 70px !important;
    height: 70px !important;
}

.current-counter {
    color: #ef9b05 !important;
}

.main-btn {
    background-color: var(--yellow);
    color: #fff;
}

.explore-projects-3-area .explore-projects-item img {
    width: 420px;
}

.carousel {
    width: 100%;
    overflow: hidden;
}

.inner {
    transition: transform 0.2s;
    white-space: nowrap;
}

.card {
    width: 100%;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
}

/* optional */
button {
    margin-right: 5px;
    margin-top: 10px;
}

@media screen and (max-width: 960px) {
    .banner-area .banner-content .title {
        font-size: 25px;
    }
}
</style>