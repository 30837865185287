<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/detail.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">Equipe</h3>
                            <!-- <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">pages</li>
                                    <li class="breadcrumb-item active" aria-current="page">Team Members</li>
                                </ol>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== TEAM PART START ======-->
        <div class="team-main-area about-team-main-area team-page-area">
            <div class="container">
                <!-- <h4>Equipe manageriale</h4> -->
                <div class="row justify-content-center">
                    <div v-for="item in teams" :key="item" class="col-lg-4 col-md-6 col-sm-9">
                        <div class="team-item mt-30">
                            <div class="team-thumb">
                                <img :src="item.media.src" alt="team" width="400" height="450">
                                <div class="overlay">
                                    <div class="text">{{item.user.description}}</div>
                                </div>
                                <div class="share">
                                    <i class="fa fa-share-alt"></i>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-facebook-official"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-content text-center">
                                <h5 class="title">{{item.user.name}}</h5>
                                <span>{{item.user.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--====== TEAM PART ENDS ======-->

        <Patner :partner="partners" />
    </div>
</template>

<script>
import Patner from './layouts/Partner';
import axios from 'axios';
export default {
    components: {
        Patner
    },
    data() {
        return {
            api_url: String,
            teams: [],
            partners: [],
        }
    },
    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getParam();
    },
    methods: {
        getParam() {
            axios.get(this.api_url + 'team')
            .then(async res => {
                const collect = await res.data;
                if (collect.success) this.teams = collect.data;
            })
            .catch(err => console.log(err));

            axios.get(this.api_url + 'partner')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.partners = collect.data;
                })
                .catch(err => console.log(err));
        },
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: #f1bc2b;
    }

    .team-item:hover .overlay {
        opacity: 1;
    }

    .text {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* text-align: center; */
    }
</style>