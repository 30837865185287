<template>
    <div>
        <div class="off_canvars_overlay"></div>
        <div class="offcanvas_menu">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="offcanvas_menu_wrapper">
                            <div class="canvas_close">
                                <a href="javascript:void(0)"><i class="fa fa-times"></i></a>
                            </div>
                            <div class="offcanvas-social">
                                <ul class="text-center">
                                    <li><a :href="facebook" target="_blank"><i class="fa fa-facebook-square text-warning"></i></a></li>
                                    <li><a :href="twitter" target="_blank"><i class="fa fa-twitter text-warning"></i></a></li>
                                    <li><a :href="linkedin" target="_blank"><i class="fa fa-linkedin text-warning"></i></a></li>
                                    <li><a :href="youtube" target="_blank"><i class="fa fa-youtube text-warning"></i></a></li>
                                </ul>
                            </div>

                            <div id="menu" class="text-left ">
                                <ul class="offcanvas_main_menu">
                                    <!-- <li class="menu-item-has-children active">
                                        <a href="">Accueil</a>
                                    </li> -->
                                    <li class="menu-item-has-children active">
                                        <a @click="redirect('about')">A propos de OUKALEY</a>
                                    </li>
                                    <li class="menu-item-has-children active">
                                        <a @click="redirect('dashboard/projet')">Lancer mon projet</a>
                                    </li>
                                    <li class="menu-item-has-children active">
                                        <a @click="redirect('projects')">Découvrir les projets</a>
                                    </li>
                                    <li v-if="userData == null" class="menu-item-has-children active">
                                        <a @click="redirect('signIn')" href="#" data-toggle="modal" data-target="#modelId">Connectez-vous</a>
                                    </li>
                                    <li v-else class="menu-item-has-children active">
                                        <a @click="redirect('dashboard')">Tableau de bord</a>
                                        <a @click="logout">Déconnexion</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="offcanvas_footer">
                                <span><a :href="'mailto:' + email"><i class="fa fa-envelope-o"></i> {{
                                    email
                                }}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <header class="header-area" :class="expand ? 'header-3-area' : ''">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="/">
                            <img src="assets/images/oukaley_small.png" alt="logo">
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto mx-5">
                                <li class="nav-item">
                                    <routerLink :to="{ name: 'about' }" class="nav-link" :class="[route == 'about' ? 'text-bold' : '']">A propos de OUKALEY</routerLink>
                                </li>
                                <li class="nav-item">
                                    <routerLink :to="{ name: 'dashboard-projet' }"
                                    class="nav-link"
                                    :class="[route == 'dashboard-projet' ? 'text-bold' : '']"
                                    :data-toggle="userData == null ? 'modal' : ''" 
                                    :data-target="userData == null ? '#modelId' : ''">Lancer mon
                                    projet</routerLink>
                                </li>
                                <li class="nav-item">
                                    <routerLink :to="{ name: 'projects' }"
                                    class="nav-link"
                                    :class="[route == 'projects' || route == 'detail-project' || route == 'viewer' || route == 'contribution' ? 'text-bold' : '']">
                                    Découvrir les projets</routerLink>
                                </li>
                                <li class="nav-item d-none d-md-block">
                                    <i @click="isSearch = !isSearch" class="fa fa-search nav-link mt-1"></i>
                                </li>
                                <li v-if="userData != null" class="nav-item dropdown">
                                    <div class="d-none d-md-block">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img :src="userMedia != '' ? userMedia : '/assets/images/user.png'" alt="img user" class="img-circle" width="32" height="32" draggable="false">
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <routerLink class="dropdown-item" :to="{ name: 'dashboard' }">Tableau de bord</routerLink>
                                            <routerLink class="dropdown-item" :to="{ name: 'dashboard-projet' }">Créer un projet</routerLink>
                                            <routerLink class="dropdown-item" :to="{ name: 'account-recharge' }">Rechargement de compte</routerLink>
                                            <!-- <routerLink class="dropdown-item" :to="{ name: 'dashboard' }">Compte oukaley: {{ userData.bank }} FCFA</routerLink> -->
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" @click="logout" href="#">Déconnexion</a>
                                        </div>
                                    </div>
                                    <div class="d-block d-md-none">
                                        <li class="nav-item">
                                            <routerLink class="nav-link" :to="{ name: 'dashboard' }">Tableau de bord</routerLink>
                                        </li>
                                        <li class="nav-item">
                                            <routerLink class="nav-link" :to="{ name: 'dashboard-projet' }">Créer un projet</routerLink>
                                        </li>
                                        <li class="nav-item">
                                            <routerLink class="nav-link" :to="{ name: 'account-recharge' }">Rechargement de compte</routerLink>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" @click="logout" href="#">Déconnexion</a>
                                        </li>
                                        <li class="nav-item">
                                            <div class="form-inline my-2 my-lg-0">
                                                <input v-model="search" class="form-control mr-sm-2" type="search" placeholder="Rechercher" aria-label="Search">
                                                <button @click="$router.push('/projects/' + search)" class="btn btn-outline-success my-2 my-sm-0" type="submit">Rechercher</button>
                                            </div>
                                        </li>
                                    </div>
                                </li>
                                <li v-else>
                                    <a href="#" class="nav-link" data-toggle="modal" data-target="#modelId" style="color: #838694">Connectez-vous</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div v-if="isSearch" class="col-md-6 input-group mb-3 mt-5">
                    <input v-model="search" type="text" class="form-control border border-warning" placeholder="Nom du projet" aria-label="Nom du projet" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button @click="$router.push('/projects/' + search)" class="btn btn-outline-secondary border border-warning" type="button">Rechercher</button>
                    </div>
                </div>
            </div>
        </div>
        <Login />
    </div>
</template>

<script>
import Login from '../auth/modal/Login';
import store from '../../store';
import axios from 'axios';

export default {
    components: {
        Login
    },
    data() {
        return {
            api_url: String,
            email: String,
            localisation: String,
            facebook: String,
            twitter: String,
            linkedin: String,
            youtube: String,
            phone: String,
            expand: false,
            route: String,
            userMedia: '',
            search: '',
            isSearch: false,
        };
    },

    computed: {
        userData: () => store.getters.getUserData,
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.email = process.env.VUE_APP_EMAIL;
        this.localisation = process.env.VUE_APP_LOCALISATION;
        this.facebook = process.env.VUE_APP_FACEBOOK_URL;
        this.twitter = process.env.VUE_APP_TWITTER_URL;
        this.linkedin = process.env.VUE_APP_LINKEDIN_URL;
        this.youtube = process.env.VUE_APP_YOUTUBE_URL;
        this.phone = process.env.VUE_APP_FIRST_PHONE;
        if (store.getters.getUserData != null) this.getUserAccountType();
    },

    methods: {
        getUserAccountType() {
            axios.get(this.api_url + 'account-type', {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.getUserData.token
                }
            })
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        store.dispatch('userInfo', null);
                        store.dispatch('userInfo', collect.data);
                        this.userMedia = collect.data.image;
                    }
                })
                .catch(err => console.log(err));
        },

        redirect(path) {
            if (path == 'signIn') {
                var class1 = document.getElementsByClassName('off_canvars_overlay');
                class1[0].classList.remove('active');
                var class2 = document.getElementsByClassName('offcanvas_menu_wrapper');
                class2[0].classList.remove('active');
            }
            else {
                this.$router.push('/' + path);
                var class1 = document.getElementsByClassName('off_canvars_overlay');
                class1[0].classList.remove('active');
                var class2 = document.getElementsByClassName('offcanvas_menu_wrapper');
                class2[0].classList.remove('active');
            }
        },

        logout() {
            store.dispatch('userInfo', null);
            window.location.href = '/';
            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
            // axios.delete(this.api_url + 'logout', {
            //     headers: {
            //         'Authorization': 'Bearer ' + store.getters.getUserData.token
            //     }
            // })
            // .then(res => {
            //     const collect = res.data;
            //     if(collect.success) {
            //         store.dispatch('userInfo', null);
            //         window.location.href = '/';
            //         this.emitter.emit('event', {'message': collect.message, 'color': 'success'});
            //     }
            // })
            // .catch(err => {
            //     const collect = err.response.data;
            //     if(collect.errors)
            //         this.emitter.emit('event', {'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR});
            //     else if(collect.success == false)
            //         this.emitter.emit('event', {'message': collect.message, 'color': process.env.VUE_APP_ERROR});
            //     else
            //         this.emitter.emit('event', {'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR});
            // });
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.name == 'team' || to.name == 'about' || to.name == 'cgu' || to.name == 'chart' || to.name == 'contact-us' || to.name == 'faqs' || to.name == 'financement' || to.name == 'important' || to.name == 'politique' || to.name == 'projects') this.expand = false;
                else this.expand = false;
                this.route = to.name;
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.navbar-brand {
    max-width: 214px;
}

.fa-search {
    cursor: pointer;
}

.header-area {
    position: fixed !important;
    z-index: 1000;
    justify-content: center !important;
    background-color: #fff;
    width: 100%;
}

.input-group {
    margin-top: 100px !important;
}

.header-area.header-3-area .header-top {
    background: var(--yellow);
    padding: 0 40px;
    border-bottom: 0;
}

.header-area.header-3-area .header-top .header-info ul li a i {
    color: #fff;
}

.text-bold {
    font-weight: bold !important;
}

.main-header {
    position: unset !important;
}

/* .main-header .main-header-item .header-menu ul li > a {
        margin: 0 20px !important;
    }

    .header-area.header-3-area .main-header .main-header-item .header-menu ul li > a {
        margin: 0 10px !important;
    } */

@media screen and (max-width: 960px) {
    .navbar-brand {
        max-width: 80%;
    }
}
</style>