import { createApp } from 'vue'
import App from './App.vue'
import customer from './router/customer'
import store from './store'
import VueClipboard from 'vue3-clipboard';
import Notifications from '@kyvg/vue3-notification'
import emitt from 'emitt'
const emitter = emitt()
import VueEasyLightbox from 'vue-easy-lightbox'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import IdleVue from 'idle-vue'
const options = {
    store,
    idleTime: 500000,
    startAtIdle: false
};

const app = createApp(App);
app.use(customer)
app.use(IdleVue, options)
app.use(Notifications)
app.use(VueEasyLightbox)
app.use(VueQrcode)
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})
app.config.globalProperties.emitter = emitter
app.mount('#app');

// Guard
customer.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + ' | Financement participatif';
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.getUserData != null) next()
        else {
            emitter.emit('event', { 'message': 'Veuillez vous connectez pour continuer', 'color': 'info' });
            next({ name: 'home' });
        }
    }
    else next();
});