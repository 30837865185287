<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/business.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">Tous les projets</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== EXPLORE PART START ======-->
        <section class="explore-area pt-90 pb-120">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-3 d-block d-sm-none">
                        <div class="sidebar__single sidebar__category">
                            <h3 class="sidebar__title">Status</h3>
                            <ul class="sidebar__category-list">
                                <li class="sidebar__category-list-item">
                                    <a @click="status('En campagne')" href="javascript:;">En campagne </a>
                                </li>
                                <li class="sidebar__category-list-item">
                                    <a @click="status('Clôturé et réussi')" href="javascript:;">Clôturé et réussi </a>
                                </li>
                                <li class="sidebar__category-list-item">
                                    <a @click="status('Clôturé et échoué')" href="javascript:;">Clôturé et échoué</a>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar__single sidebar__category">
                            <h3 @click="_categorie = !_categorie" class="sidebar__title dropdown-toggle" style="cursor: pointer;">Categories</h3>
                            <ul v-if="_categorie" class="sidebar__category-list">
                                <li v-for="item in categories" :key="item.id" class="sidebar__category-list-item">
                                    <a @click="categorie(item.name)" href="javascript:;">{{ item.name }} </a>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar__single sidebar__category">
                            <h3 @click="_country = !_country" class="sidebar__title dropdown-toggle" style="cursor: pointer;">Pays</h3>
                            <ul v-if="_country" class="sidebar__category-list">
                                <li v-for="item in countries" :key="item" class="sidebar__category-list-item">
                                    <a @click="country(item)" href="javascript:;">{{ item }} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row justify-content-center">
                            <div v-for="item in projects" :key="item.project.id" class="col-md-3">
                                <div v-if="item.etat == 'En campagne' || item.etat == 'Clôturé et réussi' || item.etat == 'Clôturé et échoué'"
                                    class="explore-projects-item mt-30">
                                    <div class="explore-projects-thumb">
                                        <img :src="item.image.src" alt="img" width="350px" height="250">
                                        <!-- <a href="#"><i class="fa fa-heart"></i></a> -->
                                    </div>
                                    <routerLink :to="{ name: 'detail-project', params: { id: item.project.slug } }"
                                        style="display: block;">
                                        <div class="explore-projects-content">
                                            <div class="item d-flex align-items-center">
                                                <span
                                                    :style="[item.etat == 'Clôturé et échoué' ? 'background-color: red' : item.etat == 'Clôturé et réussi' ? 'background-color: var(--green)' : 'background-color: black']">{{
                                                        item.etat
                                                            == 'Clôturé et réussi' ? 'Réussi' : item.etat == 'Clôturé et échoué'
                                                            ? 'Echoué' : item.etat
                                                    }}</span>
                                                <p><i class="fa fa-clock-o"></i> {{ item.deadline }} Jours restant</p>
                                            </div>
                                            <h3 class="title text">{{ item.project.name }}</h3>
                                            <p class="text-description my-3">{{ item.project.project_summary }}</p>
                                            <div class="projects-goal">
                                                <span>Objectif: <span class="color">{{ parseInt(item.project.amount).toLocaleString("en-US") }}
                                                        FCFA</span></span> <br>
                                                <span>Collectés: <span class="color">{{ item.contribution.toLocaleString("en-US") }}
                                                        FCFA</span></span>
                                            </div>
                                            <circle-progress :percent="parseInt((item.contribution / item.project.amount) * 100)" fill-color="#ef9b05" :show-percent="true"/>
                                        </div>
                                    </routerLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-none d-md-block">
                        <div class="sidebar__single sidebar__category">
                            <h3 class="sidebar__title">Statuts</h3>
                            <ul class="sidebar__category-list">
                                <li class="sidebar__category-list-item">
                                    <a @click="status('En campagne')" href="javascript:;">En campagne </a>
                                </li>
                                <li class="sidebar__category-list-item">
                                    <a @click="status('Clôturé et réussi')" href="javascript:;">Clôturé et réussi </a>
                                </li>
                                <li class="sidebar__category-list-item">
                                    <a @click="status('Clôturé et échoué')" href="javascript:;">Clôturé et échoué</a>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar__single sidebar__category">
                            <h3 @click="_categorie = !_categorie" class="sidebar__title dropdown-toggle" style="cursor: pointer;">Categories</h3>
                            <ul v-if="_categorie" class="sidebar__category-list">
                                <li v-for="item in categories" :key="item.id" class="sidebar__category-list-item">
                                    <a @click="categorie(item.name)" href="javascript:;">{{ item.name }} </a>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar__single sidebar__category">
                            <h3 @click="_country = !_country" class="sidebar__title dropdown-toggle" style="cursor: pointer;">Pays</h3>
                            <ul v-if="_country" class="sidebar__category-list">
                                <li v-for="item in countries" :key="item" class="sidebar__category-list-item">
                                    <a @click="country(item)" href="javascript:;">{{ item }} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <pagination v-if="projects.length > 6" v-model="page" :records="projects.length" :per-page="6"
                            @paginate="getProject" />
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <!--====== EXPLORE PART ENDS ======-->
    </div>
</template>

<script>
import axios from 'axios';
import Pagination from 'v-pagination-3';
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
    components: {
        Pagination, CircleProgress
    },
    data() {
        return {
            api_url: '',
            projects: [],
            newProjects: [],
            categories: [],
            countries: [],
            etats: [],
            search: '',
            router: '',
            page: 1,

            _categorie: false,
            _country: false,
        }
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getProject();
        this.getCategories();
        this.getCountry();
    },

    methods: {
        getProject() {
            axios.get(this.api_url + 'projects?page=' + this.page)
            .then(async res => {
                const collect = await res.data;
                if (collect.success) {
                    const sort = collect.data.reverse();
                    // this.projects = sort;
                    sort.forEach(element => {
                        if (element.etat == 'En campagne' || element.etat == 'Clôturé et réussi' || element.etat == 'Clôturé et échoué')
                            if (element.project.status == 1) {
                                this.projects.push(element);
                                this.newProjects.push(element);
                            }
                    })
                }
            })
            .catch(err => console.log(err));
        },

        searchProject() {
            this.projects = [];
            axios.get(this.api_url + 'search-projects/' + this.search + '?page=' + this.page)
            .then(async res => {
                const collect = await res.data;
                if (collect.success) {
                    const sort = collect.data.reverse();
                    // this.projects = sort;
                    sort.forEach(element => {
                        if (element.etat == 'En campagne' || element.etat == 'Clôturé et réussi' || element.etat == 'Clôturé et échoué')
                            if (element.project.status == 1) {
                                this.projects.push(element);
                                this.newProjects.push(element);
                            }
                    })
                }
            })
            .catch(err => console.log(err));
        },


        getCategories() {
            axios.get(this.api_url + 'categories')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success)
                        this.categories = collect.data;
                })
                .catch(err => console.log(err))
        },

        getCountry() {
            axios.get(this.api_url + 'country')
                .then(async res => {
                    const collect = await res.data;
                    collect.data.forEach(element => {
                        this.countries.push(element.nicename);
                    });
                    this.countries = this.countries.sort();
                })
                .catch(err => console.log(err));
        },

        status(state) {
            this.projects = [];
            this.newProjects.forEach(element => {
                if (element.etat == state) this.projects.push(element);
            })
        },

        categorie(name) {
            this.projects = [];
            axios.get(this.api_url + 'search-projects-by-categorie/' + name + '?page=' + this.page)
            .then(async res => {
                const collect = await res.data;
                if (collect.success) {
                    const sort = collect.data.reverse();
                    // this.projects = sort;
                    sort.forEach(element => {
                        if (element.etat == 'En campagne' || element.etat == 'Clôturé et réussi' || element.etat == 'Clôturé et échoué')
                            if (element.project.status == 1) {
                                this.projects.push(element);
                                this.newProjects.push(element);
                            }
                    })
                }
            })
            .catch(err => console.log(err));
        },

        country(name) {
            this.projects = [];
            axios.get(this.api_url + 'search-projects-by-country/' + name + '?page=' + this.page)
            .then(async res => {
                const collect = await res.data;
                if (collect.success) {
                    const sort = collect.data.reverse();
                    // this.projects = sort;
                    sort.forEach(element => {
                        if (element.etat == 'En campagne' || element.etat == 'Clôturé et réussi' || element.etat == 'Clôturé et échoué')
                            if (element.project.status == 1) {
                                this.projects.push(element);
                                this.newProjects.push(element);
                            }
                    })
                }
            })
            .catch(err => console.log(err));
        },

        like(project_id) {
            axios.get(this.api_url + 'like/' + project_id)
                .then(res => {
                    const collect = res.data;
                    console.log(collect);
                    if (collect.success)
                        this.emitter.emit('event', { 'message': 'Vous avez liké le projet', 'color': 'info' });
                })
                .catch(err => console.log(err));
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.search = to.params.search;
                this.router = to.name;
                if(to.name == 'search-projects') this.searchProject();
            }
        },
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.explore-area {
    margin: 0 50px;
}

h3.text,
.text-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Circular Progress */
.vue3-circular-progressbar {
    width: 70px !important;
    height: 70px !important;
}

.current-counter {
    color: #ef9b05 !important;
}

.explore-projects-item .explore-projects-thumb img {
    width: 100%;
    height: 220px;
}

.progress-bar {
    background-color: var(--yellow) !important;
}

.projects-goal span.color {
    color: var(--yellow) !important;
}

.page-title-area .page-title-content::before {
    background-color: var(--yellow) !important;
}

.explore-projects-item .explore-projects-content {
    padding: 20px !important;
}

.explore-projects-item .explore-projects-content .item>span {
    text-transform: lowercase;
}
</style>