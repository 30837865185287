<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/business.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">Contribution</h3>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">pages</li>
                                    <li class="breadcrumb-item active" aria-current="page">FAQs</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== FAQ TITLE PART START ======-->
        <section class="faq-title-area">
            <div class="container">
                <div class="row">
                    <div v-if="project.contreparties.length > 0" class="col-lg-4 section-title text-center">
                        <h4 class="mb-2">Sélectionnez une contrepartie</h4>
                        <div v-for="item in project.contreparties" :key="item.id">
                            <div @click="contrepartie = item" class="jumbotron" :class="[contrepartie == item ? 'border border-info' : '']" style="cursor: pointer;">
                                <img v-if="item.image != null" :src="item.image.src" width="250" height="200" class="my-1 text-center" />
                                <p class="my-1 text-left"><strong>Titre</strong>: {{ item.titre }}</p>
                                <p class="my-1 text-left"><strong>Description</strong>: {{ item.description }}</p>
                                <p class="my-1 text-left"><strong>Montant</strong>: {{ item.montant }} FCFA</p>
                                <p class="my-1 text-left"><strong>Mode</strong>: {{ item.mode_acquisition }}</p>
                                <p class="my-1 text-left"><strong>Nombre</strong>: {{ item.nombre }}</p>
                            </div>
                        </div>
                    </div>
                    <div :class="[project.contreparties.length > 0 ? 'col-lg-8' : 'col-lg-12']">
                        <div class="section-title text-center">
                            <h3 class="title">{{ project.name }}</h3>
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <div class="input-box">
                                        <div class="row">
                                            <div v-if="contrepartie" class="col-md-4">
                                                <h6>Nombre de la contribution: </h6> <br>
                                                <select v-model="nbre" class="form-control">
                                                    <option v-for="item in contrepartie.nombre" :key="item">{{ item }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div :class="contrepartie != null ? 'col-md-8' : 'col-md-12'">
                                                <h4>Don supplémentaire: </h4> <br>
                                                <input v-model="amount" type="number" min="1000" step="1000"
                                                    placeholder="Saisissez le montant de la contribution">
                                            </div>
                                        </div>
                                        <h5 class="mt-4 text-danger">Taux: {{ taxe.user }} %</h5>
                                        <h5 v-if="contrepartie != null" class="mt-4 text-danger">Total à payer:
                                            {{ (parseInt(contrepartie.montant * nbre) + parseInt(amount)) + ((parseInt(contrepartie.montant * nbre) + parseInt(amount)) * (taxe.user / 100))}} FCFA</h5>
                                        <h5 v-if="amount != 0 && contrepartie == null" class="mt-4 text-danger">Total à
                                            payer: {{ (parseInt(amount)) + (parseInt(amount) * (taxe.user / 100)) }}
                                            FCFA
                                        </h5>
                                        <img src="/assets/images/cinetpay.png" alt="img" class="my-5" draggable="false">
                                        <div class="row justify-content-center my-2">
                                            <div class="d-flex">
                                                <input v-model="anonyme" type="checkbox" id="cgu" style="width: 14px;"><div style="margin-right: 10px;"></div>
                                                Je contribue de façon anonyme
                                            </div>
                                            <div v-if="!load" class="row">
                                                <div class="col-md-5">
                                                    <a @click="pay(null, project)" type="button"
                                                        class="main-btn text-decoration-none btn-sm" disabled>Payer par
                                                        cinetpay</a>
                                                </div>
                                                <div class="col-md-5 ml-2">
                                                    <a @click="pay('oukaley', project)" type="button"
                                                        class="main-btn text-decoration-none btn-sm" disabled>Payer par
                                                        compte oukaley</a>
                                                </div>
                                            </div>
                                            <div v-else class="load"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== FAQ TITLE PART ENDS ======-->
    </div>
</template>

<script>
import store from '../../store';
import axios from 'axios';
import payment from '../../helpers/cinetpay';

export default {
    data() {
        return {
            api_url: String,
            project: null,
            id: Number,
            slug: String,
            amount: 0,
            nbre: 1,
            disabled: true,
            contrepartie: null,
            taxe: null,
            load: false,
            anonyme: false
        }
    },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getProjet();
        this.getTaxe();
    },

    methods: {
        getProjet() {
            axios.get(this.api_url + 'project/' + this.slug)
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) {
                        this.project = collect.data;
                        this.contrepartie = this.project.contreparties[this.project.contreparties.findIndex(element => element.id == this.id)];
                    }
                })
                .catch(err => console.log(err));
        },

        getTaxe() {
            axios.get(this.api_url + 'taxes')
                .then(async res => {
                    const collect = await res.data;
                    if (collect.success) this.taxe = collect.data[0];
                })
                .catch(err => console.log(err));
        },

        convert(amount) {
            this.amount = parseInt(amount);
        },

        pay(type, project) {
            if (this.contrepartie == null && (this.amount == 0 || this.amount < 1000))
                this.emitter.emit('event', { 'message': 'Montant mimimum de contribution 1000 F', 'color': 'info' });
            else {
                const amount_cinetpay = this.contrepartie != null ? (parseInt(this.contrepartie.montant * this.nbre) + parseInt(this.amount)) + ((parseInt(this.contrepartie.montant * this.nbre) + parseInt(this.amount)) * (this.taxe.user / 100)) : (this.amount + (this.amount * (this.taxe.user / 100)));
                const amount_oukaley = this.contrepartie != null ? (parseInt(this.contrepartie.montant * this.nbre) + parseInt(this.amount)) : this.amount;
                if (type == 'oukaley') {
                    this.load = true;
                    axios.post(this.api_url + 'payment', {
                        amount: Math.ceil(amount_oukaley),
                        project_id: project.id,
                        transaction_id: Math.floor(Math.random() * 100000000).toString(),
                        currency: 'XOF',
                        payment_method: 'OUKALEY ACCOUNT',
                        commission: 0,
                        is_anonyme: this.anonyme,
                        contrepartie_id: this.contrepartie != null ? this.contrepartie.id : null,
                        description: 'Contribution pour le projet ' + project.name,
                    }, {
                        headers: {
                            'Authorization': 'Bearer ' + store.getters.getUserData.token
                        }
                    })
                        .then(res => {
                            const collect = res.data;
                            this.load = false;
                            if (collect.success) {
                                if (collect.message == 'Veuillez fournir les pièces justificatives de votre identité') this.$router('/dashboard');
                                else {
                                    store.dispatch('bankReduce', parseInt(amount_oukaley));
                                    this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                                    setTimeout(() => {
                                        window.location.href = '/';
                                    }, 5000);
                                }
                            }
                        })
                        .catch(err => {
                            const collect = err.response.data;
                            this.load = false;
                            if (collect.errors) this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': 'error' });
                            else if (collect.success == false) this.emitter.emit('event', { 'message': collect.message, 'color': 'error' });
                            else this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': 'error' });
                        });
                }
                else {
                    payment.cinetpay(project, amount_cinetpay, null, this.taxe.user, this.anonyme, this.contrepartie);
                    setTimeout(() => {
                        this.load = false;
                    }, 5000);
                }
            }
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.slug = to.params.slug;
                this.id = to.params.id;
            }
        }
    },
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">
.main-btn {
    color: #fff;
    background-color: var(--yellow);
}
</style>