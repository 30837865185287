<template>
    <div>
        <!-- Modal -->
        <div class="modal fade" id="modelId2" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Inscription</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <input v-model="form.firstname" type="text" class="form-control" placeholder="Nom">
                                <small v-if="errors.firstname != ''" class="text-danger">{{
                                                            errors.firstname }}</small>
                            </div>
                            <div class="form-group">
                                <input v-model="form.lastname" type="text" class="form-control" placeholder="Prénoms">
                                <small v-if="errors.lastname != ''" class="text-danger">{{
                                                            errors.lastname }}</small>
                            </div>
                            <div class="form-group">
                                <select v-model="form.country" class="form-control">
                                    <option value="" disabled selected>Sélectionnez le pays</option>
                                    <option v-for="item in countries" :key="item.id" :value="item">{{ item.nicename }}</option>
                                </select>
                                <small v-if="errors.country != ''" class="text-danger">{{
                                                            errors.country }}</small>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">{{ form.country.phonecode }}</span>
                                </div>
                                <input v-model.trim="form.phone" type="text" class="form-control" placeholder="Contact" aria-label="Contact" aria-describedby="basic-addon1">
                            </div>
                            <small v-if="errors.phone != ''" class="text-danger">{{
                                                        errors.phone }}</small>
                            <div class="form-group">
                                <input v-model="form.email" type="email" class="form-control" placeholder="Email">
                                <small v-if="errors.email != ''" class="text-danger">{{
                                                            errors.email }}</small>
                            </div>
                            <div class="form-group">
                                <input v-model.trim="form.password" type="password" class="form-control"
                                    placeholder="Mot de passe (8 caractères minimum)">
                                    <small v-if="errors.password != ''" class="text-danger">{{
                                                            errors.password }}</small>
                            </div>
                            <div class="form-group">
                                <input v-model="form.confirm_password" type="password" class="form-control"
                                    placeholder="Confirmer le mot de passe (8 caractères minimum)">
                                    <small v-if="errors.confirm_password != ''" class="text-danger">{{
                                                            errors.confirm_password }}</small>
                            </div>
                        </form>
                        <!-- <div class="g-recaptcha" data-sitekey="6LfiLzkoAAAAAFGoI1EH-zhRmN9648CW11Kuemzr"></div> -->
                        <VueRecaptcha
                            sitekey="6LfiLzkoAAAAAFGoI1EH-zhRmN9648CW11Kuemzr"
                            :load-recaptcha-script="true"
                            ref="recaptcha"
                            type="invisible"
                            @verify="handleSuccess"
                            @error="handleError"
                        ></VueRecaptcha>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button @click="register" type="button" :disabled="disabled" class="btn btn-warning text-light">Inscription</button>
                    </div>
                    <p class="text-right mb-1 mr-1">
                        Vous avez un compte ?
                        <a href="#" class="text-dark" data-dismiss="modal" data-toggle="modal"
                            data-target="#modelId">Connexion</a>
                    </p>
                </div>
            </div>
        </div>
        <Forget />
    </div>
</template>

<script>
import axios from 'axios';
import Forget from './Forget';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {Forget, VueRecaptcha},
    data() {
        return {
            api_url: String,
            disabled: false,
            countries: [],
            form: {
                firstname: '',
                lastname: '',
                phone: '',
                country: '',
                email: '',
                password: '',
                confirm_password: '',
                captcha: '',
            },
            errors: {
                firstname: '',
                lastname: '',
                phone: '',
                country: '',
                email: '',
                password: '',
                confirm_password: ''
            }
        };
    },

    // updated() {
    //     if (this.form.firstname != '' && this.form.lastname != '' && this.form.phone.length >= 6 && (new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}').test(this.form.email) == true) && this.form.password.length >= 8 && this.form.country != '' && this.form.confirm_password == this.form.password) this.disabled = false;
    //     else this.disabled = true;
    // },

    created() {
        this.api_url = process.env.VUE_APP_URL;
        this.getCountry();
    },

    methods: {
        getCountry() {
            axios.get(this.api_url + 'country')
                .then(async res => {
                    const collect = await res.data;
                    collect.data.forEach(element => {
                        this.countries.push(element);
                    });
                    this.countries = this.countries.sort();
                })
                .catch(err => console.log(err));
        },

        register() {
            if (this.form.firstname == '') this.errors.firstname = 'Ce champs est requis';
            else this.errors.firstname = '';
            if (this.form.lastname == '') this.errors.lastname = 'Ce champs est requis';
            else this.errors.lastname = '';
            if (this.form.country == '') this.errors.country = 'Ce champs est requis';
            else this.errors.country = '';
            if (this.form.email == '') this.errors.email = 'Ce champs est requis';
            else this.errors.email = '';
            if (new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}').test(this.form.email) == false) this.errors.email = 'Ceci n\'est pas un email';
            else this.errors.email = '';
            if (new RegExp('^[0-9]*$').test(this.form.phone) == false) this.errors.phone = 'Uniquement des chiffres';
            else if (this.form.phone == '') this.errors.phone = 'Ce champs est requis';
            else this.errors.phone = '';
            if (this.form.password == '') this.errors.password = 'Ce champs est requis';
            else this.errors.password = '';
            if (this.form.confirm_password == '') this.errors.confirm_password = 'Ce champs est requis';
            else this.errors.confirm_password = '';
            if (this.form.confirm_password != this.form.password) this.errors.confirm_password = 'Les mots de passe ne sont pas identiques';
            else this.errors.confirm_password = '';
            if (this.errors.firstname == '' && this.errors.lastname == '' && this.errors.country == '' && this.errors.email == '' && this.errors.phone == '' && this.errors.password == '' && this.errors.confirm_password == '' && this.form.password == this.form.confirm_password) {
                this.disabled = false;
                this.emitter.emit('event', { 'message': 'Traitement en cours ...', 'color': 'info' });
                axios.post(this.api_url + 'sign-up', {
                    name: this.form.firstname + ' ' + this.form.lastname,
                    phone: this.form.phone,
                    email: this.form.email,
                    country: this.form.country.name,
                    password: this.form.password,
                    captcha: this.form.captcha,
                })
                    .then(res => {
                        const collect = res.data;
                        if (collect.success) {
                            this.form.firstname = '',
                            this.form.lastname = '',
                            this.form.phone = '',
                            this.form.email = '',
                            this.form.country.name = '',
                            this.form.password = '',
                            this.form.captcha = '',
                            this.emitter.emit('event', { 'message': collect.message, 'color': 'success' });
                            this.handleError();
                            setTimeout(() => {
                                window.location.href = '/';
                            }, 5000);
                        }
                    })
                    .catch(err => {
                        const collect = err.response.data;
                        this.handleError();
                        if (collect.message.errorBag == "default") {
                            this.emitter.emit('event', { 'message': 'Veuillez cocher Google recaptcha', 'color': process.env.VUE_APP_ERROR });
                            return;
                        }
                        if (collect.errors)
                            this.emitter.emit('event', { 'message': collect.errors[Object.keys(collect.errors)[0]], 'color': process.env.VUE_APP_ERROR });
                        else if (collect.success == false)
                            this.emitter.emit('event', { 'message': collect.message, 'color': process.env.VUE_APP_ERROR });
                        else
                            this.emitter.emit('event', { 'message': 'Erreur de connexion', 'color': process.env.VUE_APP_ERROR });
                    });
            }
        },

        handleSuccess(res) {
            this.form.captcha = res;
        },

        handleError() {
            this.$refs.recaptcha.reset();
        }
    }
}
</script>

<style scoped>
    .modal-body {
        overflow-y: auto;
        height: 430px;
    }
    .text-right {
        color: red;
    }
</style>
