<template>
    <div>
        <!--====== PAGE TITLE PART START ======-->
        <section class="page-title-area bg_cover" style="background-image: url(assets/images/detail.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-title-content">
                            <h3 class="title">Guide de création de projet</h3>
                            <!-- <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">pages</li>
                                    <li class="breadcrumb-item active" aria-current="page">CGU</li>
                                </ol>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== PAGE TITLE PART ENDS ======-->

        <!--====== FAQ DESIGN PART START ======-->
        <section class="faq-design-area pb-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tab-content mt-55" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-1" role="tabpanel"
                                aria-labelledby="pills-1-tab">
                                <h4 class="pb-5"><strong>Recevez déjà toutes nos félicitations pour votre contribution
                                        au
                                        développement
                                        du continent africain à travers votre projet.
                                        Vous avez tout notre soutien et nos encouragements.</strong></h4>
                                <div class="row">
                                    <h4 class="pb-2"> <strong>VOUS Y ETES PRESQUE !</strong></h4>
                                    <p class="text-lowercase pb-5">
                                        Comme tout événement, une campagne de Crowdfunding demande l’élaboration d’une
                                        stratégie et d’un plan marketing. Vous allez devoir communiquer sur votre idée,
                                        sur votre projet, pour convaincre votre cible de passer à l’action en vous
                                        soutenant financièrement. Une véritable opération de séduction commence alors.
                                        <br /><br />
                                        Mais cette opération de séduction passe par une captivante présentation de votre
                                        projet. Lisons ces quelques lignes avant de nous lancer.
                                        Tout d’abord, sachez que toute votre présentation textuelle peut se faire sous
                                        forme de vidéo attractive qui sera affichée en haut de page de votre projet. Il
                                        faut humaniser le discours pour attirer l’empathie de la foule et son adhésion à
                                        la campagne. Comme toute histoire ou film qui se respecte, et pour captiver
                                        votre public, vous devez les embarquez dans votre récit. Ils doivent pouvoir s'y
                                        identifier. Et donc il faut leur dire des choses qui leur parlent. <br /><br />
                                        Pour se faire, vous aurez à remplir les rubriques suivantes : <br /><br />
                                        <strong>Donnez un nom à votre projet</strong> :
                                        Il s’agit de donner un nom significatif à votre projet ; un nom qui a du sens
                                        pour votre projet. <br /><br />
                                        Dans quel pays sera réalisé le projet? :
                                        Vous allez indiquer le pays où va s’exécuter le projet. <br /><br />
                                        Durée de la campagne (en Jours) :
                                        La durée de la campagne est le nombre de jours durant lequel la collecte va
                                        s’effectuer. Veuillez à bien le déterminer et vous rendre disponible tout au
                                        long de cette période pour œuvrer (mobiliser suffisamment à travers des actions
                                        de marketing et communication les internautes) afin de vous donner le maximum de
                                        chance d’atteindre et pourquoi pas d’excéder votre objectif financier.
                                        <br /><br />
                                        <strong>Montant de la collecte attendu (CFA)</strong> :
                                        Il s’agit de votre budget incluant toutes les dépenses occasionnées (frais liées
                                        directement au projet, frais liés aux actions marketing , frais liés aux
                                        contreparties, commission OUKALEY, etc.) par la campagne. <br /><br />
                                        <strong>Lien Pitch vidéo du projet</strong> :
                                        Il vous faut juste copier et coller ici le lien (YouTube) de la présentation de
                                        votre projet.
                                        Pour vous aider à mieux présenter votre campagne, nous mettons à votre
                                        disposition le fichier de pitch vidéo que vous pourrez télécharger en cliquant
                                        <a href="/CHECK-LIST_DE_PITCH_DE_PROJET_Oukaley.pdf" target="_blank"
                                            class="text-danger" style="color: red;">ici</a>. <br /><br />
                                        <strong>Contexte et justificatif</strong> :
                                        Il vous faut présenter clairement votre idée pour que les gens identifient tout
                                        de suite en quoi elle apporte une solution concrète à un besoin réel. Montrer
                                        que l’idée est parfaitement réalisable et que vous avez une vision claire de la
                                        manière dont vous allez la mettre en œuvre.
                                        Vous pourrez répondre à titre indicatif aux questions suivantes :
                                        Comment est née l’idée de projet ? Quel problème résout-il ? C’est quoi le
                                        projet (le livrable) ? Quelle est la particularité du projet ? <br /><br />

                                        <strong>Plan d’exécution</strong> :
                                        Il vous faudra indiquer les étapes de réalisation de votre projet marquées par
                                        des livrables. <br /><br />
                                        Budget détaillé du projet :
                                        Vous indiquerez les dépenses à effectuer pour la réalisation de votre projet
                                        <br /><br />
                                        <strong>Présentation de l’équipe</strong> :
                                        Ici, il s’agira de donner toutes les informations sur chaque membre de votre
                                        équipe de sorte à vous faire connaître et à créer un bon climat de confiance
                                        <strong>Résumé du projet</strong>
                                        Les internautes doivent pouvoir en une phrase ou deux connaître votre projet et
                                        y adhérer. Il vous faudra faire un très gros effort de synthèse tout en
                                        humanisant votre discours. <br /><br />
                                        <strong>Ajouter un logo de profil pour le projet</strong>
                                        Télécharger votre logo ou si vous n’en avez pas, mettez-y une image qui vous
                                        identifie. <br /><br />
                                        <strong>Ajouter une bannière pour le projet</strong> (indiquer la taille)
                                        Télécharger ici une image qui résume votre projet et qui identifiera votre
                                        projet. <br /><br />
                                        <strong>Sélectionnez votre type de donation pour ce projet</strong> :
                                        -Don sans contrepartie : La contrepartie est émotionnelle et vous n’avez donc
                                        rien à offrir si ce n’est tout de même des remerciements. Vous donnerez
                                        essentiellement des nouvelles régulières du projet pendant et après sa
                                        réalisation.
                                        -Don avec contrepartie : Vous offrirez aux donateurs une récompense, en guise de
                                        remerciement, de nature physique ou immatérielle (mention de leur nom,
                                        invitation à la présentation publique du projet, modèle miniature d’une œuvre,
                                        dédicace du prototype, tee-shirt, Ticket pour concert, une partie de votre
                                        production, etc.). Les meilleures contreparties que vous pouvez proposer aux
                                        internautes peuvent être vos produits ou futurs produits. <br /><br />
                                        <strong>Sélectionnez la catégorie</strong> :
                                        Vous sélectionnerez le type d’activité dans laquelle s’inscrit le projet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--====== FAQ DESIGN PART ENDS ======-->

        <Patner />
    </div>
</template>

<script>
import Patner from './layouts/Partner';
export default {
    components: {
        Patner
    }
}
</script>

<style scoped="this api replaced by slot-scope in 2.5.0+">

</style>